import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { createTheme } from '@mui/material/styles';
import styles from '../../assets/styles/common.module.scss';
import classNames from 'classnames/bind';
import { Box } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
let cx = classNames.bind(styles);

const CustomTooltip = ({ info, children, placement = 'top', enableInfoStyle = true }) => {
    const materialTheme = createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        minWidth: (info?.length ?? 0) * 5.3,
                        fontSize: '10px',
                        lineHeight: '14px',
                        color: '#000000',
                        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
                        backgroundColor: '#fff',
                    },
                    arrow: {
                        color: '#fff',
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={materialTheme}>
            <Tooltip
                placement={placement}
                title={
                    <div className={cx(styles.tooltipComponent)}>
                        <p>{info}</p>
                    </div>
                }
                arrow>
                <Box className={cx(enableInfoStyle && styles.info)}>{children}</Box>
            </Tooltip>
        </ThemeProvider>
    );
};

export default CustomTooltip;
