import React, { useState } from 'react';
import { Dialog } from 'components/common';
import TransactionView from './TransactionView';
import { request } from 'utils/api';
import cx from 'classnames';
import styles from 'assets/styles/common.module.scss';

import Endpoints from 'utils/endpoints';

const {
    transactions: { fetchTransaction },
} = Endpoints;


const TransactionLink = ({ accountId, transactionId }) => {
    const [showTransactionModal, setShowTransactionModal] = useState(false);
    const [transaction, setTransaction] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const retrieveTransaction = () => {
        setTransaction(null);
        setLoading(true);
        setError(null);
        request()
        .get(fetchTransaction.url(accountId, transactionId))
        .then((response) => {
            setLoading(false);
            if (response.status === 200) {
                const { data } = response;
                setTransaction(data);
            } else {
                setError('Something went wrong');
            }
        });
    }

    const showTransaction = () => {
        setShowTransactionModal(true);
        retrieveTransaction();
    }
    return (
        <>
            <span className={cx(styles.txnId)} onClick={showTransaction}>{transactionId}</span>
            <Dialog
                title="Transaction details"
                fullWidth
                open={showTransactionModal}
                setOpen={(val) => {
                    setShowTransactionModal(val);
                }}
                isSingleButton={true}
                submitButton={'Close'}
                submitAction={() => {
                    setShowTransactionModal(false);
                }}
                paperProps={{
                    maxWidth: '100%',
                    width: '80%',
                }}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <TransactionView
                    onRefresh={() => retrieveTransaction()} 
                    transaction={transaction}
                    loading={loading}
                    error={error} />
            </Dialog>

        </>
    );
};

export default TransactionLink;
