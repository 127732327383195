import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import cx from 'classnames';
import styles from 'assets/styles/common.module.scss';
import { Icon } from '../index';
import { toDateString } from 'utils/helpers';

const RangePickerV2 = ({ label, disable, values, minDate, maxDate, onChange, required }) => {
    const [value, setValue] = useState([null, null]);
    const [rangePickerOpen, setRangePickerOpen] = useState(false);
    const ref = useRef()
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        setValue(values);
    }, [values]);

    const handleChange = useCallback(
        (dateRange) => {
            setValue(dateRange);
            onChange(dateRange);
        },
        [onChange]
    );

    const handleClick = (event) => {
        setRangePickerOpen((isOpen) => !isOpen);
        setAnchorEl(ref.current);
      };
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {label && (
                <div className={cx(disable ? styles.disable : styles.label)}>
                    {label}
                    {required && <span className={cx(styles.asterisk)}> *</span>}
                </div>
            )}
            <DateRangePicker
                open={rangePickerOpen}
                onClose={() => setRangePickerOpen(false)}
                minDate={minDate}
                maxDate={maxDate}
                calendars={1}
                value={value}
                disableFuture={true}
                disableAutoMonthSwitching={true}
                disableCloseOnSelect={false}
                onChange={handleChange}
                PopperProps={{
                    placement: "bottom-end",
                    anchorEl: anchorEl,
                }}
                renderInput={(startProps, endProps) => (
                    <div className={cx(styles.rangePickerV2)}>
                        <div className={cx(styles.datePickerContainer)} ref={ref} onClick = {handleClick}>
                            <div className={cx(styles.datePicker)} {...startProps}>
                                <Icon className={cx(styles.calendarIcon)} name={'calendar'}/>
                                <div className={cx(styles.dateValue)}>From: {value[0] ? toDateString(value[0]) : ''}</div>
                            </div>
                            <div>-</div>
                            <div className={cx(styles.datePicker)}{...endProps}>
                                <Icon className={cx(styles.calendarIcon)} name={'calendar'}/>
                                <div className={cx(styles.dateValue)}>To: {value[1] ? toDateString(value[1]) : ''}</div>
                            </div>
                            <Icon className={cx(styles.dropDown)} name={'caret-down'}/>

                        </div>
                    </div>
                )}
            />
        </LocalizationProvider>
    );
};

export default RangePickerV2;