import { createSlice } from '@reduxjs/toolkit';

/*
 *  @desc The global slice manages the state to be use across components
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    loading: false,
    error: null,
    enabledCurrencies: [],
    collapseMenu: true,
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        clearState(state) {
            state.loading = false;
            state.error = {
                message: '',
                status: false,
            };
            state.changePassword = {
                status: null,
            };
        },
        loadEnabledCurrencies(state) {
            state.loading = true;
            state.enabledCurrencies = [];
            state.error = null;
        },
        loadEnabledCurrenciesSuccess(state, action) {
            state.loading = false;
            state.enabledCurrencies = action.payload;
            state.error = null;
        },
        loadEnabledCurrenciesError(state, action) {
            state.loading = false;
            state.enabledCurrencies = [];
            state.error = action.payload;
        },
        setCollapseMenu: (state, { payload }) => {
            state.collapseMenu = payload;
        },
    },
});

export const { actions, reducer, name } = globalSlice;
