import React, { useCallback } from 'react';
import styles from 'assets/styles/recipients.module.scss';
import { CustomList, CustomTooltip, Icon } from 'components/common';
import cx from 'classnames';
import RecipientDetails from './RecipientDetails';
import CurrencyIcon from 'components/common/CurrencyIcon';
import { getCurrencyDescription } from 'utils/helpers';
import { Box } from '@mui/material';

const getSubtitle = (recipient) => {
    const recipientDetails = [];
    if (recipient.account_number) {
        recipientDetails.push(`Acc number: ${recipient.account_number}`);
    }
    if (recipient.sort_code) {
        recipientDetails.push(`Sort code: ${recipient.sort_code}`);
    }
    if (recipient.iban) {
        recipientDetails.push(`IBAN: ${recipient.iban}`);
    }
    if (recipient.bic) {
        recipientDetails.push(`BIC: ${recipient.bic}`);
    }
    return recipientDetails.join(', ');
};

const getTitle = (recipient) => {
    return (
        `${recipient.account_holder_name}` +
        (recipient.nickname ? ` (Nickname: ${recipient.nickname})` : '')
    );
};

const RecipientItem = ({
    recipient,
    onSelectHandler,
    onDeleteHandler,
    onEditHandler,
    isSelected,
}) => {
    const handleRecipientClick = useCallback(
        (recipient) => {
            onSelectHandler(recipient);
        },
        [onSelectHandler]
    );

    return (
        <React.Fragment key={recipient.id}>
            <CustomList
                name={recipient?.nickname ?? recipient?.account_holder_name}
                id={recipient.id}
                title={getTitle(recipient)}
                subtitle={getSubtitle(recipient)}
                avatar={'true'}
                customClass={cx(styles.item)}
                avatarClass={cx(styles.avatar)}
                subAvatar={
                    <Box className={cx(styles.avatarSubIcon)}>
                        <CustomTooltip info={getCurrencyDescription(recipient?.currency)}>
                            <CurrencyIcon currency={recipient?.currency} style={{width: '20px'}} rounded/>
                        </CustomTooltip>
                    </Box>}
                actions={[
                    <span key={'id'} className={cx(styles.action)}>
                        <Icon
                            name={isSelected ? 'caret-up' : 'caret-down'}
                            color="#C4C4C4"
                            onClick={() => handleRecipientClick(recipient)}
                        />
                    </span>,
                ]}
                collapsibleContent={
                    <RecipientDetails
                        recipient={recipient}
                        onDelete={onDeleteHandler}
                        onEdit={onEditHandler}
                        isOpen={isSelected}
                    />
                }
            />
        </React.Fragment>
    );
};

export default RecipientItem;
