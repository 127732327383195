import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    language: 'en-US',
    isDrawerOpen: false,
    currentSection: '',
};

const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        drawerOpen: (state) => {
            state.isDrawerOpen = true;
        },
        drawerClose: (state) => {
            state.isDrawerOpen = false;
        },
        sectionSet: (state, action) => {
            state.currentSection = action.payload;
        },
    },
});

export const { actions, reducer, name } = contentSlice;
