import React, { useEffect, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import cx from 'classnames';
import styles from 'assets/styles/send-funds.module.scss';
import Confirm from './Confirm';
import Review from './Review';
import { Grid } from '@mui/material';
import selectState from 'store/selectors/funds';
import { actions } from 'store/slice/funds';
import { useDispatch } from 'react-redux';
import CustomStepperIcon from 'components/common/StepperContent';
import styled from 'styled-components/macro';
import { Icon } from 'components/common';
import { useSearchParams } from 'react-router-dom';
import { PaymentInput } from 'components/common/Payment';

const steps = ['Enter details', 'Review details', 'Confirm payment'];

const CustomStepLabel = styled(StepLabel)(({ ownerState }) => ({
    ...(ownerState.completed && {
        color: '#00A09B !important',
    }),
    ...(ownerState.active && {
        color: '#00A09B',
    }),
    ...(ownerState.error && {
        color: '#FF8888 !important',
    }),
}));

const StyledLabel = styled('span')(({ ownerState }) => ({
    ...(ownerState.completed && {
        color: '#00A09B !important',
    }),
    ...(ownerState.active && {
        color: '#00A09B !important',
    }),
}));

const StyledOffsetGrid = styled(Grid)`
    @media (max-width: 768px) {
        display: none;
    }
`;

const SendFunds = () => {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const [fromAccountId, setFromAccountId] = useState(null);

    const { currentFundStep, transactionStatus, transaction_details, fromAccount } = selectState();

    const isLastStep = currentFundStep === steps.length - 1;

    useEffect(() => {
        dispatch(actions.resetFunds());
    }, [dispatch]);

    useEffect(() => {
        if (searchParams.get('from')) {
            setFromAccountId(searchParams.get('from'));
            setSearchParams({});
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        if (!fromAccountId) {
            return;
        }
        dispatch(actions.fetchFromAccount(fromAccountId));

        // Resetting it so that the changes could be detected next time
        setFromAccountId(null);
    }, [dispatch, fromAccountId]);

    const handleSendFundsInputSubmit = (values) => {
        dispatch(
            actions.generateTransactionDetails({
                ...values,
            })
        );
        dispatch(actions.setCurrentFundStep(1));
    };

    const handleCancelTransaction = () => {
        dispatch(actions.resetFunds());
        dispatch(actions.setCurrentFundStep(0));
    };    

    const isStepError = (step) => {
        return step === 3 && transactionStatus === 'error';
    };

    const StepIconError = () => {
        return <CustomStepperIcon error={true} />;
    };

    const StepIcon = (props) => {
        const { active, completed, className, error } = props;
        return (
            <CustomStepperIcon
                active={active}
                completed={completed}
                error={error}
                className={className}
                finalStepCompleted={isLastStep && transactionStatus === 'success'}
            />
        );
    };

    const StepIconSuccess = () => {
        return <Icon name="passed" className="completedIcon" />;
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <PaymentInput
                        handleSubmit={handleSendFundsInputSubmit}
                        defaultFrom={fromAccount}
                        values={transaction_details}
                    />
                );
            case 1:
                return <Review handleCancel={handleCancelTransaction}/>;
            case 2:
                return <Confirm />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Grid className={cx(styles['send-funds'])}>
            <Grid item xs={8}>
                <h1 className={cx(styles['page-title'])}>Send funds</h1>
            </Grid>
            {isLastStep && <StyledOffsetGrid item md={2} />}
            <Grid item md={12} xs={12} sm={12} lg={12}>
                <Stepper
                    activeStep={currentFundStep}
                    className={cx(styles['stepper-wrapper'])}
                    alternativeLabel
                    connector={
                        <div className={cx(styles['stepper-connector'])}>
                            <span />
                        </div>
                    }>
                    {steps.map((label, index) => {
                        if (isStepError(index)) {
                            return (
                                <Step key={label}>
                                    <CustomStepLabel
                                        StepIconComponent={StepIconError}
                                        ownerState={{
                                            error: isStepError(index),
                                        }}>
                                        <StyledLabel
                                            ownerState={{
                                                error: isStepError(index),
                                            }}
                                            style={{
                                                color: '#FF8888',
                                            }}>
                                            {label}
                                        </StyledLabel>
                                    </CustomStepLabel>
                                </Step>
                            );
                        }
                        if (isLastStep && transactionStatus === 'success') {
                            return (
                                <Step key={label}>
                                    <CustomStepLabel
                                        StepIconComponent={StepIconSuccess}
                                        ownerState={{
                                            active: currentFundStep === index,
                                            error: isStepError(index),
                                            completed: currentFundStep > index,
                                        }}>
                                        <StyledLabel
                                            ownerState={{
                                                active: currentFundStep === index,
                                                completed: currentFundStep > index,
                                            }}>
                                            {' '}
                                            {label}
                                        </StyledLabel>
                                    </CustomStepLabel>
                                </Step>
                            );
                        }
                        return (
                            <Step key={label}>
                                <CustomStepLabel
                                    StepIconComponent={StepIcon}
                                    ownerState={{
                                        active: currentFundStep === index,
                                        error: isStepError(index),
                                        completed: currentFundStep > index,
                                    }}>
                                    <StyledLabel
                                        ownerState={{
                                            active: currentFundStep === index,
                                            completed: currentFundStep > index,
                                        }}>
                                        {' '}
                                        {label}
                                    </StyledLabel>
                                </CustomStepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            {renderStepContent(currentFundStep)}
        </Grid>
    );
};

export default SendFunds;
