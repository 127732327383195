import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Autocomplete, Grid, MenuItem, TextField } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';

import CustomTooltip from '../CustomTooltip';
import Icon from '../Icon';

import styles from 'assets/styles/common.module.scss';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/common';
import selectState from 'store/selectors/common';
import CurrencyIcon from 'components/common/CurrencyIcon';
import { RecipientCard } from 'components/common/Card';

const SelectRecipient = (props) => {
    const ref = useRef(null);

    const {
        selectRecipient: { recipients },
    } = selectState();

    const {
        className,
        name,
        required,
        label,
        value,
        onChange,
        helperText,
        currency,
        error,
        disable,
        info,
    } = props;

    const [focus, setFocus] = useState(false);
    const [selected, setSelected] = useState(null);
    const [textInput, setTextInput] = useState(null);
    const dispatch = useDispatch();

    const handleChange = useCallback(
        (name, value) => {
            const selectedRecipient = recipients?.find((rec) => rec.id === value);
            if (selectedRecipient) {
                setTextInput(null);
            }
            setSelected(selectedRecipient);
            onChange(name, selectedRecipient);
        },
        [onChange, recipients]
    );

    useEffect(() => {
        if (!value) {
            // If value cleared from parent component
            setSelected(null);
        } else {
            const selectedRecipient = recipients?.find((rec) => rec.id === value);
            if (selectedRecipient) {
                setTextInput(null);
            }
            setSelected(selectedRecipient);
        }
    }, [recipients, value]);

    useEffect(() => {
        if (!textInput || textInput.length > 3) {
            dispatch(actions.getRecipientsForSelect({ search: textInput, currency }));
        }
    }, [dispatch, disable, currency, textInput]);

    const materialTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        width: '100%',
                    },
                    input: {
                        '&.MuiSelect-select': {
                            height: '48px',
                        },
                        padding: '12px 14px',
                    },
                },
            },
        },
    });

    const getSubtitle = (recipient) => {
        var subTitle = '';
        if (recipient.iban) {
            subTitle += `IBAN: ${recipient.iban}, `;
            if (recipient.bic) {
                subTitle += `BIC: ${recipient.bic}, `;
            }
        } else if (recipient.account_number) {
            subTitle += `Acc-number: ${recipient.account_number}, `;
            if (recipient.sort_code) {
                subTitle += `Sort code: ${recipient.sort_code}, `;
            }
        }
        return subTitle?.substring(0, subTitle.length - 2);
    };

    return (
        <div>
            <div className={cx(disable ? styles.disable : styles.label)}>
                {label}
                {required && <span className={cx(styles.asterisk)}> *</span>}
                {info && (
                    <CustomTooltip info={info}>
                        <Icon name={'info'} />
                    </CustomTooltip>
                )}
            </div>
            <ThemeProvider theme={materialTheme}>
                <div
                    className={cx([
                        error ? styles.errorBorder : styles.default,
                        focus && styles.focus,
                        styles[className],
                    ])}>
                    {selected && (
                        <RecipientCard
                            recipient={selected}
                            onClear={() => handleChange(name, null)}
                        />
                    )}
                    {!selected && (
                        <Autocomplete
                            ref={ref}
                            name={name}
                            style={{ padding: '8px 14px' }}
                            disableClearable={true}
                            isOptionEqualToValue={(option, value) => {
                                if (!value) {
                                    return true;
                                }
                                return option.id === value;
                            }}
                            disabled={disable}
                            filterOptions={(x) => x}
                            value={value || ''}
                            options={recipients ?? []}
                            defaultValue={value || ''}
                            error={error}
                            noOptionsText="No recipients found"
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason === 'input') {
                                    setTextInput(newInputValue);
                                }
                            }}
                            renderOption={(props, recipient) => (
                                <MenuItem
                                    style={{
                                        maxWidth: ref?.current?.offsetWidth,
                                        margin: '8px 0px',
                                    }}
                                    onClick={() => handleChange(name, recipient.id)}
                                    value={recipient.id || null}
                                    defaultValue={recipient.id || null}
                                    key={recipient.id}
                                    disabled={false}
                                    className={cx([
                                        styles.selectMenuItem,
                                        recipient.id === value && styles.selectMenuItemActive,
                                    ])}>
                                    <Grid container style={{ alignItems: 'center' }}>
                                        <Grid item>
                                            <CurrencyIcon
                                                rounded
                                                style={{ paddingRight: '10px' }}
                                                currency={recipient.currency}
                                                height="35"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid item>
                                                <p
                                                    style={{
                                                        whiteSpace: 'break-spaces',
                                                    }}>
                                                    {recipient.nickname ??
                                                        recipient.account_holder_name}
                                                </p>
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}>
                                                <p
                                                    style={{
                                                        whiteSpace: 'break-spaces',
                                                        fontSize: '12px',
                                                    }}>
                                                    {getSubtitle(recipient)}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            )}
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.id
                            }
                            onFocus={() => setFocus((prevState) => !prevState)}
                            onChange={(event, newValue) => {
                                handleChange(event.target.name, event.target.value);
                            }}
                        />
                    )}
                    {helperText && <p className={cx(styles.asterisk)}>{helperText}</p>}
                </div>
            </ThemeProvider>
        </div>
    );
};

export default SelectRecipient;
