import { styled } from '@mui/material/styles';
import style from 'styled-components';
import { Drawer, ListItemIcon, Popper } from '@mui/material';
import { SIDE_MENU_WIDTH } from 'components/layouts/constants';

export const StyledDrawer = styled(Drawer)(({ $toggle, variant, collapse }) => ({
    boxSizing: 'border-box',
    display: 'inline-block',
    verticalAlign: 'top',
    border: '1px solid #E5E5E5',
    width: (collapse === 'true' ? SIDE_MENU_WIDTH.collapse : SIDE_MENU_WIDTH.expanded) + 'px',
    borderRadius: '0px 15px 15px 0px',
    height: '100%',
    transition: 'width 250ms ease-in-out',
    position: `${variant === 'temporary' ? 'fixed' : 'relative'}`,

    '.MuiBackdrop-root': {
        backgroundColor: `${$toggle ? 'rgba(255,255,255, 0.8)' : 'unset'}`,
    },
    '.MuiDrawer-paper': {
        boxSizing: 'border-box',
        border: 'none',
        width: collapse === 'true' ? SIDE_MENU_WIDTH.collapse + 'px' : '100%',

        padding: '30px 16px 10px 16px',
        position: 'relative',
        filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.4))',
        transition: 'width 250ms ease-in-out',
        borderRadius: '0px 15px 15px 0px',
    },
    '.MuiListItemButton-root': {
        borderRadius: '8px',
        padding: '8px 16px',
        marginBottom: '8px',
        width: collapse === 'true' ? '58px' : '100%',
        svg: {
            color: '#111326',
            fill: '#111326',
            path: {
                fill: '#111326',
            },
        },
        '&:hover': {
            background: 'rgba(0, 160, 155, 0.19)',
            color: '#00A09B',
            svg: {
                color: '#00A09B',
                fill: '#00A09B',
                path: {
                    fill: '#00A09B',
                },
            },
        },
        ' &.Mui-selected': {
            background: 'rgba(0, 160, 155, 0.19)',
            color: '#00A09B',

            svg: {
                color: '#00A09B',
                fill: '#00A09B',
                path: {
                    fill: '#00A09B',
                },
            },

            '&:hover': {
                background: 'rgba(0, 160, 155, 0.19)',
            },
            '.MuiListItemText-primary': {
                fontWeight: 'bold',
            },
        },
    },
    '.MuiCollapse-wrapper': {
        '.MuiListItemButton-root': {
            marginBottom: 0,
            padding: '2px',
            '&:hover': {
                background: 'transparent',
            },
        },
        '.Mui-selected': {
            backgroundColor: 'transparent',
            color: '#00A09B',
            '.MuiTypography-root': {
                fontWeight: 500,
            },
            '&:hover': {
                background: 'transparent',
            },
        },
    },
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
    zIndex: 1201,

    '.MuiPaper-root': {
        position: 'fixed',
        bottom: '15px',
        left: '76px',
        margin: '0 0 8px 16px',
    },
    '.Mui-selected': {
        backgroundColor: 'transparent',
        color: '#00A09B',
        '.MuiTypography-root': {
            fontWeight: 500,
        },
        svg: {
            color: '#00A09B',
            fill: '#00A09B',
            path: {
                fill: '#00A09B',
            },
        },
    },
    '.MuiListItemButton-root': {
        gap: '10px',

        '&:hover': {
            background: 'rgba(0, 160, 155, 0.19)',
            color: '#00A09B',
            borderRadius: '15px',
            svg: {
                color: '#00A09B',
                fill: '#00A09B',
                path: {
                    fill: '#00A09B',
                },
            },
        },
    },
}));

export const StyledLogo = style.div`
margin: ${({ collapse }) => (collapse ? '0 0 18px' : '10px 16px 30px')};
`;
export const StyledMenuList = style.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
`;

export const StyledChevron = style.div`
    position: absolute;
    top: 42px;
    left: ${({ collapse }) => (collapse ? '90px' : '240px')};
    transform: rotate(${({ collapse }) => (collapse ? 180 : 0)}deg);
    transform-origin: center;
    transition: left 250ms ease-in-out, transform 300ms ease-out;
    z-index: 1201;

    > svg {
        fill: white;
        stroke: #bbbbbb;
        transition: fill 0.3s ease, stroke 0.3s ease;
    }

    > svg g path {
        fill: #666666;
        stroke: white;
    }

    &:hover {
        > svg circle {
            fill: #00a09b;
            stroke: #00a09b;
        }

        > svg g path {
            fill: white;
            stroke: #00a09b;
        }
    }
`;

export const ListItemWrapper = style.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const FaviconWrapper = style.div`
    > svg {
        rect {
            transition: fill 200ms ease-out;
            fill: white;
        }
        path {
            transition: fill 200ms ease;
            fill: #111326;
        }
    }

    &:hover {
        > svg {
            rect {
                fill: #00a09b;
            }
            path {
                fill: white;
            }
        }
    }
`;

export const StyledIconListItem = styled(ListItemIcon)`
    padding-left: 15px;
`;

export const StyledFooterMenu = style.div`
    margin-top: 8px;
`;
