import {
    POST_LOGIN_PENDING,
    POST_LOGIN_SUCCESS,
    POST_LOGIN_FAILURE,
    POST_LOGIN_OTP_PENDING,
    POST_LOGIN_OTP_SUCCESS,
    POST_LOGIN_OTP_FAILURE,
    LOGOUT,
    CLEAR_STATE,
    CLEAR_ERRORS,
    SET_RE_CAPTCHA_TOKEN,
} from '../actionTypes';

//login
export const setReCaptchaToken = (data) => ({
    type: SET_RE_CAPTCHA_TOKEN,
    data,
});

export const postLoginPending = (option) => ({
    type: POST_LOGIN_PENDING,
    option,
});

export const postLoginSuccess = (data) => ({
    type: POST_LOGIN_SUCCESS,
    data,
});

export const postLoginFailure = (message, status) => ({
    type: POST_LOGIN_FAILURE,
    message,
    status,
});

//login-otp
export const postLoginOtp = (option) => ({
    type: POST_LOGIN_OTP_PENDING,
    option,
});

export const postLoginOtpSuccess = (data) => ({
    type: POST_LOGIN_OTP_SUCCESS,
    data,
});

export const postLoginOtpFailure = (message, status) => ({
    type: POST_LOGIN_OTP_FAILURE,
    message,
    status,
});

//logout
export const logout = (data) => ({
    type: LOGOUT,
    data,
});

export const clearState = () => ({
    type: CLEAR_STATE,
});

export const clearErrors = (option) => ({
    type: CLEAR_ERRORS,
    option,
});
