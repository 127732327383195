import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Dialog, Loading } from 'components/common';
import { selectSimulateWebhook } from 'store/selectors/transactions';
import styles from 'assets/styles/account.module.scss';
import { SIMULATE_WEBHOOK } from './constants';
import DialogV2 from 'components/common/DialogV2';

export default function SimulateTransactionFeeModal({ simulateModalState, toggleModalDisplay }) {
    const displayModal = simulateModalState === SIMULATE_WEBHOOK.transactionFee.name;
    const { loading, error, message } = useSelector(selectSimulateWebhook);
    const isLoaded = !loading && !!message;

    const renderBody = () => {
        if (loading) {
            return (
                <Box className={cx(styles.box)}>
                    <p>Fee charge processing..</p>
                </Box>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            {isLoaded ?
                <DialogV2
                    title={SIMULATE_WEBHOOK.transactionFee.title}
                    open={displayModal}
                    isSingleButton={true}
                    setOpen={() => toggleModalDisplay('')}
                    submitButton={'Close'}
                    variant={error ? 'error' : 'info'}
                    submitAction={() => toggleModalDisplay('')}
                >
                    <div className={cx(styles.messageText)}>
                        <p>{message}</p>
                    </div>
                </DialogV2> :
                <Dialog
                    title={SIMULATE_WEBHOOK.transactionFee.title}
                    fullWidth
                    open={displayModal}
                    setOpen={() => toggleModalDisplay('')}
                    backdropProps={{
                        style: {
                            backgroundColor: 'rgba(255,255,255, 0.8)',
                        },
                    }}
                    submitButton={'Close'}
                    submitAction={() => {}}
                    isActions={true}
                    isDisable={loading}>
                    <form className={cx(styles.form)}>
                        {loading && <Loading className={cx(styles.formLoading)} />}
                        {renderBody()}
                    </form>
                </Dialog>
            }
        </>
    );
}
