import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Icon from './Icon';
import styles from 'assets/styles/common.module.scss';

const DialogV2 = (props) => {
    const {
        open,
        setOpen,
        variant,
        title,
        children,
        submitAction,
        cancelAction,
        disableActions = false,
        cancelButton = 'Close',
        submitButton = 'Submit',
        isSingleButton = false,
        fullWidth,
        backdropProps,
        paperProps = {},
        hideActions = false,
        disableClose = false,
    } = props;

    const handleClose = useCallback(
        (event, reason) => {
            // Disabling close on backdrop click
            if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                return;
            }
            if (!disableClose) {
                setOpen(false, reason);
            }
        },
        [setOpen, disableClose]
    );

    return (
        <Dialog
            open={open}
            className={cx(styles.dialogV2)}
            onClose={handleClose}
            fullWidth={fullWidth}
            PaperProps={{
                style: {
                    boxSizing: 'border-box',
                    margin: '0px',
                    width: '565px',
                    minHeight: '220px',
                    borderRadius: '30px',
                    border: '1px solid #c4c4c4',
                    padding: '28px 36px 28px 28px',
                    ...paperProps,
                },
            }}
            BackdropProps={{
                ...backdropProps,
            }}>
            <DialogTitle className={cx(styles.dialogHeader)}>
                {!disableClose && (
                    <Icon
                        className={cx(styles.closeDialog)}
                        name={'close-modal'}
                        onClick={() => {
                            handleClose(null, 'modal-close');
                        }}
                    />
                )}
                <div className={cx(styles.title)}>{title}</div>
            </DialogTitle>
            <DialogContent className={cx(styles.dialogChildren)}>
                <div className={cx(styles.variantIcon)}>
                    <Icon name={'modal-' + variant} width="81px" height="81px" />
                </div>
                <div className={cx(styles.dialogContent)}>{children}</div>
            </DialogContent>
            {!hideActions && (
                <DialogActions className={cx(styles.dialogActions)}>
                    {cancelAction && !isSingleButton && (
                        <Button
                            className={cx(
                                styles.dialogSubmitBtn,
                                styles['modal-' + variant + '-outlined']
                            )}
                            variant="outlined"
                            onClick={cancelAction}
                            disabled={disableActions}>
                            <span>{cancelButton}</span>
                        </Button>
                    )}
                    {submitAction && (
                        <Button
                            className={cx(styles.dialogCancelBtn, styles['modal-' + variant])}
                            onClick={submitAction}
                            disabled={disableActions}>
                            <span>{submitButton}</span>
                        </Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default DialogV2;

DialogV2.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    title: PropTypes.string,
    content: PropTypes.string,
    submitAction: PropTypes.func,
    cancelAction: PropTypes.func,
    isDisable: PropTypes.bool,
    disableClose: PropTypes.bool,
    className: PropTypes.string,
    cancelButton: PropTypes.string,
    submitButton: PropTypes.string,
    isSingleButton: PropTypes.bool,
    fullWidth: PropTypes.bool,
    backdropProps: PropTypes.object,
};
