import React, { useEffect, useState } from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/users.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from 'utils/auth';
import { CustomTooltip, Icon, Loading } from 'components/common';
import selectUsersState from 'store/selectors/userManagement';
import { actions } from 'store/slice/userManagement';
import styled from 'styled-components';
import UserCreate from './UserCreate';
import UserUpdate from './UserUpdate';
import UserDelete from './UserDelete';
import { userRoleNamesFromValues } from './utils';
import { ButtonV2 } from 'components/common/Button';

const TableHeaderRow = styled(TableRow)(() => ({
    backgroundColor: 'rgba(0,160,155,0.19)',
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
        backgroundColor: 'rgba(0,160,155,0.05)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(() => ({
    fontFamily: 'Inter, "Open Sans", sans-serif !important',
}));

const Users = () => {
    const { user_id } = useSelector(getCurrentUser);
    const dispatch = useDispatch();

    const [showCreateUser, setShowCreateUser] = useState(false);
    const [showEditUser, setShowEditUser] = useState(false);
    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const { loading, usersList, rolesList, rolesListOutcome } = selectUsersState();

    useEffect(() => {
        dispatch(actions.getRoles());
    }, [dispatch]);

    useEffect(() => {
        if (!rolesListOutcome.status) {
            return;
        }

        if (rolesListOutcome.status === 'success') {
            dispatch(actions.getUsers());
        }
    }, [dispatch, rolesListOutcome.status]);

    const editUser = (user) => {
        setSelectedUser(user);
        setShowEditUser(true);
    };

    const deleteUer = (user) => {
        setSelectedUser(user);
        setShowDeleteUser(true);
    };

    return (
        <Grid container className={cx(styles.container)}>
            <Grid md={12} item className={cx(styles.headerRow)}>
                <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <h1 className={cx(styles.title)}>{'Users'}</h1>
                    <Icon name={'refresh'} onClick={() => dispatch(actions.getUsers())} />
                </div>
                <ButtonV2
                    text="+ Create user"
                    variant="secondary"
                    disabled={showCreateUser}
                    onClick={() => {
                        setShowCreateUser(true);
                    }}
                />
            </Grid>

            {loading && <Loading className={cx(styles.loading)} />}
            {!loading && usersList.length === 0 && (
                <h3 className={cx(styles.noData)}>Failed to load user data</h3>
            )}
            {!loading && usersList.length > 0 && (
                <Grid item md={12} xs={12} className={cx(styles.list)}>
                    <TableContainer className={cx(styles.table)}>
                        <Table>
                            <TableHead>
                                <TableHeaderRow className={cx(styles.tableHead)}>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                    <StyledTableCell>Mobile number</StyledTableCell>
                                    {/* <StyledTableCell>Send payment<br />OTP via email?</StyledTableCell> */}
                                    <StyledTableCell>Roles</StyledTableCell>
                                    <StyledTableCell>Actions</StyledTableCell>
                                </TableHeaderRow>
                            </TableHead>
                            <TableBody>
                                {usersList?.length > 0 &&
                                    usersList.map((user) => (
                                        <StyledTableRow key={user.id}>
                                            <StyledTableCell>
                                                {user.first_name} {user.last_name}
                                            </StyledTableCell>
                                            <StyledTableCell>{user.email}</StyledTableCell>
                                            <StyledTableCell>{user.phone_number}</StyledTableCell>
                                            {/* <StyledTableCell>
                                            {user.txn_email_otp_enabled ? 'Yes' : 'No'}
                                        </StyledTableCell> */}
                                            <StyledTableCell className={cx(styles.rolesCell)}>
                                                {userRoleNamesFromValues(
                                                    rolesList,
                                                    user.roles
                                                ).join(', ')}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <span
                                                    className={cx(styles.view)}
                                                    style={{
                                                        display: 'inline-block',
                                                    }}
                                                    onClick={() => editUser(user)}>
                                                    <CustomTooltip
                                                        info={'Click here to update the User'}>
                                                        <Icon
                                                            className={cx(styles.editUserButton)}
                                                            name={'edit'}
                                                        />
                                                    </CustomTooltip>
                                                </span>
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                    }}
                                                    disabled={user.id === user_id}
                                                    className={cx(styles.view)}
                                                    onClick={() => {
                                                        if (user.id !== user_id) {
                                                            deleteUer(user);
                                                        }
                                                    }}>
                                                    <CustomTooltip
                                                        info={
                                                            user.id !== user_id
                                                                ? 'Click here to delete the User'
                                                                : 'To delete your user profile reach out to cs@merge.money'
                                                        }>
                                                        <Icon
                                                            className={
                                                                user.id === user_id
                                                                    ? cx(
                                                                          styles.deleteUserButtonInactive
                                                                      )
                                                                    : cx(styles.deleteUserButton)
                                                            }
                                                            name={'delete'}
                                                        />
                                                    </CustomTooltip>
                                                </span>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
            {showCreateUser && <UserCreate handleClose={() => setShowCreateUser(false)} />}
            {showEditUser && (
                <UserUpdate
                    user={selectedUser}
                    isCurrentUser={selectedUser.id === user_id}
                    handleClose={() => setShowEditUser(false)}
                />
            )}
            {showDeleteUser && (
                <UserDelete user={selectedUser} handleClose={() => setShowDeleteUser(false)} />
            )}
        </Grid>
    );
};

export default Users;
