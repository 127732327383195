import React, { useState } from 'react';
import cx from 'classnames';
import { Box, Grid, createTheme } from '@mui/material';
import styles from 'assets/styles/reports.module.scss';
import GenerateMAS from './GenerateMAS';
import MASReports from './MASReports';
import { ThemeProvider } from '@emotion/react';
import { Tabs, Tab } from 'components/common/Tabs';
import { actions } from 'store/slice/reports';
import { useDispatch } from 'react-redux';

const materialTheme = createTheme({});

export default function MonthyAccountStatement() {
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch();

    return (
        <Grid container className={cx(styles.container)}>
            <h1 className={cx(styles.title)}>Monthly account statement</h1>
            <ThemeProvider theme={materialTheme}>
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        activeTab={activeTab}
                        onChange={(index) => {
                            setActiveTab(index);
                            dispatch(actions.resetMASQueue());
                        }}
                        customClassName={styles.tabsContainer}>
                        <Tab label="Queue MAS">
                            <GenerateMAS setTab={(index) => setActiveTab(index)} />
                        </Tab>
                        <Tab label="Download MAS">
                            <MASReports />
                        </Tab>
                    </Tabs>
                </Box>
            </ThemeProvider>
        </Grid>
    );
}
