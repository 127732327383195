import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, InputField, Loading, Otp } from 'components/common';
import { actions as accountActions } from 'store/slice/account';
import { actions as commonActions } from 'store/slice/common';
import { selectCreateAccount } from 'store/selectors/account';
import { selectRequestOtp } from 'store/selectors/common';
import styles from 'assets/styles/account.module.scss';
import DialogV2 from 'components/common/DialogV2';

export default function CreateSubAccount({
    displayModal,
    handleModal,
    accountMeta,
    pageType,
    pageMeta,
}) {
    const dispatch = useDispatch();
    const [displayOtpDialog, setDisplayOtpDialog] = useState(false);
    const { loading, message, error, isLoaded } = useSelector(selectCreateAccount);
    const { otpCount, otpToken, otpLoading, otpError, otpLoaded } = useSelector(selectRequestOtp);
    const errorLoadingOtp = otpLoaded && otpCount !== 0;
    const errorLoadingCreateReq = error.status >= 400;
    const { currency, accountId, accountName } = accountMeta;
    const accCreationValidationSchema = yup.object({
        name: yup
            .string()
            .required('Account name is required')
            .max(255, 'Account name must not exceed 255 characters')
            .matches(/^[a-zA-Z]/, 'Account name must start with a letter')
            .matches(
                /^[a-zA-Z][a-zA-Z0-9]*(?:\s+[a-zA-Z0-9]+)*\s?$/,
                'Account name can only contain letters, numbers and a white space'
            ),
        otp: yup.string().matches(/^[0-9]{6}$/, 'OTP must be exactly 6 digits'),
    });
    const initialValues = { name: accountName ?? '', otp: '' };
    const formik = useFormik({
        initialValues,
        validationSchema: accCreationValidationSchema,
        onSubmit: async ({ name }) => {
            if (formik.isValid) {
                dispatch(
                    commonActions.generateRequestOtp({
                        message: `{otp} is your passcode to add the account with alias name ${name}. Never share this code.`,
                        subject: 'Request to add account',
                        requestPath: `//v1/account`,
                        requestMethod: 'POST',
                        deliveryChannel: 'email',
                        requestPayload,
                    })
                );
            }
        },
    });

    const resetOtpDialog = useCallback(
        (state) => {
            if (errorLoadingCreateReq) {
                dispatch(accountActions.resetCreateCustomerAccount());
            }

            setDisplayOtpDialog(state);
        },
        [errorLoadingCreateReq]
    );
    const { values, isValid, dirty, touched, errors } = formik;

    const requestPayload = useMemo(
        () => ({
            name: values.name?.trim(),
            main_account_id: accountId,
            currency,
            type: 'customer_account',
        }),
        [currency, accountId, values.name]
    );

    const handleChange = useCallback(
        (event) => {
            const { name, value } = event.target;

            formik.setValues({
                ...values,
                [name]: value,
            });
        },
        [formik]
    );

    const onModalHandle = (val) => {
        dispatch(accountActions.resetCreateCustomerAccount());
        dispatch(commonActions.resetRequestOtp());
        handleModal(val);
    };

    const submitAccountNameOtp = useCallback(() => {
        if (!errors.otp) {
            dispatch(
                accountActions.createCustomerAccount({
                    requestPayload,
                    otp: {
                        otp: values.otp,
                        token: otpToken,
                    },
                })
            );
        }
    }, [values.otp, errors.otp, dispatch, requestPayload, otpToken]);

    const handleSubmitOtpResend = useCallback(() => {
        formik.handleSubmit();
        formik.setFieldValue('otp', '');
        dispatch(accountActions.resetCreateCustomerAccount());
    }, []);

    const onActionSubmit = () => {
        onModalHandle(false);
        switch (pageType) {
            case 'summary':
                dispatch(accountActions.getAccountsSummary());
                break;
            case 'accountList':
                if (pageMeta) {
                    const { currency, page, size } = pageMeta;
                    dispatch(
                        accountActions.getCurrencyAccounts({
                            currency,
                            page,
                            size,
                        })
                    );
                }
                break;
            default:
                return;
        }
    };

    const renderBody = () => (
        <InputField
            name={'name'}
            label={'Account alias name'}
            value={values.name}
            onChange={handleChange}
            error={touched.name && Boolean(errors.name)}
            helperText={errors.name}
            required={true}
            placeholder={'Enter the name'}
            disable={loading}
        />
    );

    useEffect(() => {
        if (displayModal) {
            formik.setValues(initialValues);
        }
        // eslint-disable-next-linew
    }, [displayModal]);

    useEffect(() => {
        if (isLoaded && !errorLoadingCreateReq) {
            setDisplayOtpDialog(false);
        }
    }, [isLoaded, errorLoadingCreateReq]);

    useEffect(() => {
        if (displayModal && otpLoaded && otpCount === 0 && otpError.status < 400) {
            setDisplayOtpDialog(true);
        }
    }, [displayModal, otpLoaded, otpCount, otpError.status]);

    return (
        <>
            <Dialog
                title={'Create account'}
                fullWidth
                open={displayModal}
                setOpen={onModalHandle}
                isSingleButton={isLoaded && !errorLoadingCreateReq}
                submitButton={isLoaded && !errorLoadingCreateReq ? 'Close' : 'Create'}
                cancelButton={'Cancel'}
                cancelAction={() => onModalHandle(false)}
                submitAction={() => {
                    formik.handleSubmit();
                }}
                isActions={true}
                isDisable={(!isLoaded && !(dirty && isValid)) || otpLoading}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <form className={cx(styles.form)} onSubmit={formik.handleSubmit}>
                    {(otpLoading || loading) && <Loading className={cx(styles.formLoading)} />}
                    {renderBody()}
                </form>
            </Dialog>

            <Dialog
                title={'Authenticate'}
                open={displayOtpDialog}
                isSingleButton={false}
                setOpen={(val) => {
                    if (!val) {
                        resetOtpDialog(val);
                        formik.setFieldValue('otp', '');
                    }
                }}
                fullWidth
                submitButton={null}
                isActions={false}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <div className={cx(styles.dialog)}>
                    <Otp
                        name={'otp'}
                        heading={'Enter one-time passcode sent to the registered email id.'}
                        otp={values.otp}
                        onChange={(val) => {
                            if (values.otp?.length === 0 && error.message?.length) {
                                dispatch(accountActions.resetCreateCustomerAccount());
                            }
                            formik.setFieldValue('otp', val);
                        }}
                        disabled={values.otp?.length !== 6}
                        length={6}
                        onSubmit={submitAccountNameOtp}
                        onResend={handleSubmitOtpResend}
                        error={error}
                        buttonText={'Submit'}
                        otpCount={otpCount}
                        maxTime={45}
                        isSecured={true}
                        loading={loading}
                    />
                </div>
            </Dialog>

            <DialogV2
                title={errorLoadingOtp ? 'Error' : 'Success'}
                open={(isLoaded && !errorLoadingCreateReq) || errorLoadingOtp}
                setOpen={(state) => {
                    if (!state) {
                        onActionSubmit();
                    }
                }}
                isSingleButton={true}
                submitButton={errorLoadingOtp ? 'Retry' : 'Close'}
                variant={errorLoadingOtp ? 'error' : 'info'}
                submitAction={() => {
                    if (errorLoadingOtp) {
                        dispatch(commonActions.resetRequestOtp());
                        return;
                    }
                    onActionSubmit();
                }}>
                {errorLoadingOtp ? otpError.message : message}
            </DialogV2>
        </>
    );
}
