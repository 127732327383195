import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledNumberInput = styled(NumberFormat)(({ error }) => ({
    height: '56px',
    border: '1px solid #C4C4C4',
    ...(error && {
        border: '1px solid #00A09B',
    }),
    boxSizing: 'border-box',
    borderRadius: '15px',
    font: 'inherit',
    letterSpacing: 'inherit',
    background: 'none',
    display: 'block',
    minWidth: 0,
    width: '100%',
    animationDuration: '10ms',
    padding: '16.5px 14px',
    marginBottom: '16px',
    '&:focus-visible': {
        border: '1px solid #00A09B',
        outline: 'none',
    },
}));

const ErrorMessage = styled.div`
    color: #ff8888;
    font-size: 0.875rem;
    margin: -10px 0 15px;
`;

const StyledRequired = styled.span`
    color: #ff8888;
    margin-bottom: 10px;
`;

const StyledLabel = styled.div`
    padding-top: 16px;
    margin-bottom: 5px;
`;

const NumberInput = (props) => {
    const {
        placeholder,
        isNumber,
        isThousandOperator,
        value,
        onValueChange,
        name,
        error,
        helpertext,
        required,
        key,
        label,
    } = props;
    return (
        <React.Fragment key={key}>
            {label && (
                <StyledLabel>
                    {label} {required && <StyledRequired>*</StyledRequired>}
                </StyledLabel>
            )}
            <StyledNumberInput
                placeholder={placeholder}
                name={name}
                isNumericString={!isNumber}
                thousandSeparator={isThousandOperator}
                value={value}
                onValueChange={(val) => onValueChange(val.value)}
                {...props}
                error={error ? helpertext : false} // Input expects error to be of string type. Overriding the type from above props
            />
            {error && required && <ErrorMessage>{helpertext}</ErrorMessage>}
        </React.Fragment>
    );
};

export default NumberInput;

NumberInput.propTypes = {
    placeholder: PropTypes.string,
    isNumber: PropTypes.bool,
    isThousandOperator: PropTypes.bool,
    value: PropTypes.string,
    onValueChange: PropTypes.func,
    name: PropTypes.string,
    error: PropTypes.bool,
    helpertext: PropTypes.string,
    required: PropTypes.bool,
};
