import {
    POST_LOGIN_PENDING,
    POST_LOGIN_SUCCESS,
    POST_LOGIN_FAILURE,
    POST_LOGIN_OTP_PENDING,
    POST_LOGIN_OTP_SUCCESS,
    POST_LOGIN_OTP_FAILURE,
    LOGOUT,
    CLEAR_STATE,
    CLEAR_ERRORS,
    SET_RE_CAPTCHA_TOKEN,
} from '../actionTypes';

const initialState = {
    login: {},
    signup: {},
    isLoading: false,
    loginError: null,
    regError: null,
    isSessionExpired: false,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case SET_RE_CAPTCHA_TOKEN:
            return {
                login: {
                    x_recaptcha_token: action.data,
                },
            };
        case POST_LOGIN_PENDING:
        case POST_LOGIN_OTP_PENDING:
            return {
                ...state,
                loginError: null,
                regError: null,
                isLoading: true,
            };

        case POST_LOGIN_SUCCESS:
            return {
                ...state,
                login: {
                    ...action.data,
                    isOtpVerified: false,
                },
                isLoading: false,
            };
        case POST_LOGIN_OTP_SUCCESS:
            return {
                ...state,
                login: {
                    ...action.data,
                    isOtpVerified: true,
                },
                isLoading: false,
            };

        case POST_LOGIN_FAILURE:
        case POST_LOGIN_OTP_FAILURE:
            return {
                ...state,
                isLoading: false,
                loginError: {
                    message: action.message,
                    status: action.status,
                },
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                isLoading: false,
                [action.option]: null,
            };

        case LOGOUT:
        case CLEAR_STATE:
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('companyId');
            localStorage.removeItem('userId');
            localStorage.removeItem('email');
            localStorage.removeItem('userName');
            localStorage.removeItem('envName');
            // localStorage.removeItem('authorizeToken')
            return {
                ...state,
                login: {},
                signup: {},
                loginError: null,
                regError: null,
                isLoading: false,
                isSessionExpired: action?.data?.isSessionExpired,
            };

        default:
            return state;
    }
};

export default auth;
