import { call, put, takeLatest } from 'redux-saga/effects';
import {
    postLoginSuccess,
    postLoginFailure,
    postLoginOtpSuccess,
    postLoginOtpFailure,
} from '../actions';
import { POST_LOGIN_PENDING, POST_LOGIN_OTP_PENDING } from '../actionTypes';
import { actions as userActions } from 'store/slice/user';
import { request } from 'utils/api';

function* postLoginWorker({ option }) {
    try {
        const { data } = yield call(request().post, '/auth/login', option);

        yield put(postLoginSuccess(data));
    } catch (error) {
        const { data, status } = error.response;
        yield put(postLoginFailure(data?.message, status));
    }
}

export function* postLogin() {
    yield takeLatest(POST_LOGIN_PENDING, postLoginWorker);
}

function* postLoginOtpWorker({ option }) {
    try {
        const { data } = yield call(request().post, '/auth/2fa-verify', option);

        yield put(postLoginOtpSuccess(data));

        if (data) {
            const user = yield call(request().get, `user-management/user/${data.user_id}`);
            yield put(userActions.getUserDataSuccess(user.data));
        }
    } catch (error) {
        const { data } = error.response;
        yield put(postLoginOtpFailure(data?.message, 403));
    }
}

export function* postLoginOtp() {
    yield takeLatest(POST_LOGIN_OTP_PENDING, postLoginOtpWorker);
}
