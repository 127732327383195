import React, { useEffect } from 'react';

import { Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAccessToken, getOtp } from 'utils/auth';

const ProtectedRoutes = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isOtpVerified = getOtp();
    const auth = getAccessToken();
    let { userId, token } = useParams();

    useEffect(() => {
        if (auth) {
            if (location.pathname === '/') {
                navigate('/dashboard/home');
            }
        }
    }, [auth, userId, token, location.pathname, navigate]);

    return auth && isOtpVerified ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
