import React from 'react';
import styled from 'styled-components';
import { Icon } from 'components/common';
import cx from 'classnames';
import styles from 'assets/styles/send-funds.module.scss';
import PropTypes from 'prop-types';

const StepperIcon = styled('div')(({ ownerState }) => ({
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    ...(ownerState.error && {
        color: '#FF8888',
    }),
}));

const CustomStepperIcon = (props) => {
    const { active, completed, className, error } = props;
    return (
        <StepperIcon ownerState={{ active }} className={className}>
            {completed && !error && <Icon name="passed" className="completedIcon" />}
            {active && !error && !completed && <Icon name="step-active" className="activeIcon" />}
            {!active && !completed && !error && <Icon name="ellipse" className="circle" />}
            {error && <Icon name="error-close" className={cx(styles['error-icon'])} />}
        </StepperIcon>
    );
};

export default CustomStepperIcon;

CustomStepperIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    className: PropTypes.string,
    error: PropTypes.bool,
};
