import { createSlice } from '@reduxjs/toolkit';

/*
 *  @desc The funds slice manages the state of Send Funds
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    payment_request_id: null,
    created_payment_request: null,
    loading: false,
    loadingPayment: false,
    error: null,
    fromAccount: null,
    recipient: null,
    account: null,
    currentStep: 0,
    disableStep: true,
    payment_details: {},
    paymentRequestStatus: null,
    paymentRequestError: null,
    otp: false,
    otpCount: 0,
};

export const queuePaymentsSlice = createSlice({
    name: 'queuepayments',
    initialState,
    reducers: {
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        generatePaymentDetails: (state, action) => {
            state.payment_details = { ...action.payload };
        },
        fetchFromAccount: (state, action) => {
            state.fromAccount = null;
        },
        fetchFromAccountSuccess: (state, action) => {
            state.fromAccount = action.payload;
        },
        fetchFromAccountFailure: (state, action) => {
            state.error = action.error;
        },

        createPaymentRequest: (state, action) => {
            state.paymentRequestStatus = 'pending';
        },
        createPaymentRequestSuccess: (state, action) => {
            state.created_payment_request = action.payload;
            state.payment_request_id = action.payload.alias_id;
            state.paymentRequestStatus = 'success';
            state.currentStep = 2;
        },
        createPaymentRequestError: (state, action) => {
            state.paymentRequestStatus = 'error';
            state.paymentRequestError = action.payload.message ?? 'Oops! Something went wrong...';
            state.currentStep = 2;
        },
        resetPaymentRequestStatus: (state, action) => {
            state.paymentRequestStatus = null;
            state.paymentRequestError = null;
        },
        resetPaymentRequest: () => initialState,
    },
});

export const { actions, reducer, name } = queuePaymentsSlice;
