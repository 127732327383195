import React, { useCallback, useEffect, useRef } from 'react';
import { Button } from 'components/common';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/queuepayments';
import selectState from 'store/selectors/queuepayments';
import styles from 'assets/styles/send-funds.module.scss';
import { Loading } from 'components/common';
import { v4 as uuidv4 } from 'uuid';
import { PaymentDetail } from 'components/common/Payment';
import DialogV2 from 'components/common/DialogV2';

const PaymentRequestReview = ({ handleCancel }) => {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const { payment_details, loading, paymentRequestStatus } = selectState();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [showCancelModal, setShowCancelModal] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const clientNonce = uuidv4();

    useEffect(() => {
        if (paymentRequestStatus === 'error') {
            setIsError(true);
        }
        return () => {
            setIsError(false);
        };
    }, [isError, paymentRequestStatus]);

    const submitPaymentRequest = useCallback(() => {
        dispatch(
            actions.createPaymentRequest({
                payment_request_details: {
                    ...payment_details,
                    client_nonce: clientNonce,
                },
            })
        );
    }, [payment_details, dispatch, clientNonce]);

    function cancelRequest() {
        setIsModalOpen(false);
    }

    const requestConfirmation = () => {
        setIsModalOpen(true);
    };

    return (
        <div
            ref={ref}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                position: 'relative',
            }}>
            {loading && <Loading className={cx(styles.fundsLoading)} />}
            <PaymentDetail
                details={payment_details}
                handleOnEdit={() => dispatch(actions.setCurrentStep(0))}
            />
            <div className={cx(styles.divider)}></div>
            <div className={cx(styles.paymentActions)}>
                <div className={cx(styles.actionBtn)}>
                    <Button
                        text={'Cancel'}
                        variant="outlined"
                        onClick={() => setShowCancelModal(true)}
                    />
                </div>
                <div className={cx(styles.actionBtn)}>
                    <Button text={'Confirm'} onClick={requestConfirmation} disabled={loading} />
                </div>
            </div>

            <DialogV2
                title={'Cancel payment request'}
                open={showCancelModal}
                isSingleButton={false}
                setOpen={() => setShowCancelModal(false)}
                cancelButton={'No'}
                submitButton={'Yes'}
                variant={'warning'}
                submitAction={handleCancel}
                cancelAction={() => setShowCancelModal(false)}>
                On clicking Yes, the changes will be discarded. Are you sure you want to cancel this
                payment request?
            </DialogV2>

            <DialogV2
                title={'Confirm payment request'}
                open={isModalOpen}
                setOpen={() => setIsModalOpen(!isModalOpen)}
                submitButton={'Confirm'}
                cancelButton={'Cancel'}
                submitAction={submitPaymentRequest}
                cancelAction={cancelRequest}
                variant={'warning'}
                content={``}>
                <div>Are you sure you want to create the payment request? </div>
                <p style={{ marginTop: '20px', fontSize: '14px', lineHeight: '20px' }}>
                    Note: Any payment request that is not approved or rejected within 45 days will
                    automatically expire.
                </p>
            </DialogV2>
        </div>
    );
};

export default PaymentRequestReview;
