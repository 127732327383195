import React from 'react';
import { CustomTooltip, Icon } from 'components/common';
import { actions } from 'store/slice/reports';
import { useDispatch } from 'react-redux';

const DownloadButton = ({ url, filename, hoverInfo }) => {
    const dispatch = useDispatch();
    const handleDownload = async () => {
        try {
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = filename || 'document.pdf';
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
            dispatch(actions.downloadSuccess());
        } catch (error) {
            dispatch(actions.downloadFailure());
            console.error('Download failed:', error);
        }
    };

    return (
        <CustomTooltip info={hoverInfo}>
            <Icon name={'download-available'} onClick={handleDownload} />
        </CustomTooltip>
    );
};

export default DownloadButton;
