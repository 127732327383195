import { createSlice } from '@reduxjs/toolkit';

/*
 *  @desc The reports slice manages the state of the transactions
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    loading: false,
    fromAccount: null,
    error: {
        status: false,
        message: '',
    },
    transactionStatus: null,
    transactions: [],
    monthlyStatement: {
        loading: false,
        message: '',
        success: false,
        error: false,
        reportsList: {
            items: [],
            page: 1,
            size: 10,
            total: 0,
        },
        isDownloading: {
            success: false,
            error: false,
        },
    },
};

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        getAllTransactions(state) {
            state.loading = true;
            state.transactionStatus = null;
            state.error = {
                status: false,
            };
        },
        getAllTransactionsSuccess(state, action) {
            state.loading = false;
            const txns = action.payload;
            state.transactions = txns;
            if (txns.length === 0) {
                state.transactionStatus = 'NO_TRANSACTIONS';
            } else {
                state.transactionStatus = 'SUCCESS';
            }
            state.error = {
                status: false,
            };
        },
        getAllTransactionsError(state, action) {
            state.loading = false;
            state.error = {
                message: action.payload,
                status: true,
            };
            state.transactionStatus = 'FAILED';
        },
        clearTransactions(state) {
            state.transactions = [];
        },

        fetchFromAccount: (state, action) => {
            state.fromAccount = null;
        },
        fetchFromAccountSuccess: (state, action) => {
            state.fromAccount = action.payload;
        },
        fetchFromAccountFailure: (state, action) => {
            state.error = action.error;
        },
        createAccountStatement: (state) => {
            state.loading = true;
        },
        createAccountStatementSuccess: (state) => {
            state.loading = false;
        },
        createAccountStatementFailure: (state) => {
            state.loading = false;
        },
        generateMonthlyStatement(state) {
            state.monthlyStatement.loading = true;
        },
        generateMASSuccess(state) {
            state.monthlyStatement.loading = false;
            state.monthlyStatement.success = true;
            state.monthlyStatement.message =
                'MAS is queued and when ready it will be available under Download tab.';
        },
        generateMASError(state) {
            state.monthlyStatement.loading = false;
            state.monthlyStatement.error = true;
            state.monthlyStatement.message =
                'An error was encountered while queuing the MAS. Please retry in a few minutes or contact cs@merge.money if the issue persists.';
        },
        resetMASQueue(state) {
            state.monthlyStatement.success = false;
            state.monthlyStatement.error = false;
            state.monthlyStatement.message = '';
        },
        fetchMASReports(state) {
            state.monthlyStatement.loading = true;
            state.monthlyStatement.reportsList = [];
        },
        listMASReportsSuccess(state, { payload }) {
            state.monthlyStatement.loading = false;
            state.monthlyStatement.reportsList = payload;
            state.monthlyStatement.error = false;
        },
        listMASReportsError(state) {
            state.monthlyStatement.loading = false;
            state.monthlyStatement.error = true;
        },
        downloadSuccess(state) {
            state.monthlyStatement.isDownloading.success = true;
            state.monthlyStatement.isDownloading.error = false;
        },
        downloadFailure(state) {
            state.monthlyStatement.isDownloading.success = false;
            state.monthlyStatement.isDownloading.error = true;
        },
        resetDownload(state) {
            state.monthlyStatement.isDownloading.success = false;
            state.monthlyStatement.isDownloading.error = false;
        },
    },
});

export const { actions, reducer, name } = reportsSlice;
