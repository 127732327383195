import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';
import styles from 'assets/styles/reports.module.scss';

import selectState from 'store/selectors/reports';

import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/reports';
import { exportToCsv, exportToXlsx } from 'utils/helpers';
import MerlinReport from './MerlinReport';
import { pdf } from '@react-pdf/renderer';
import FileSaver from 'file-saver';
import moment from 'moment';
import DialogV2 from 'components/common/DialogV2';
import selectCompanyState from 'store/selectors/company';
import NeptuneReport from './NeptuneReport';
import ReportsFilterV2 from './ReportsFilterV2';

const Report = () => {
    const { loading, transactionStatus, transactions } = selectState();
    const { isMerlinOnly } = selectCompanyState();
    const dispatch = useDispatch();
    const [format, setFormat] = useState('csv');
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const [statusMessage, setStatusMessage] = useState(null);
    const [statusVariant, setStatusVariant] = useState('info');

    const onDownload = useCallback(
        (data) => {
            setIsDownloadModalOpen(true);
            setFormat(data.format);
            const from_date = data.from_date.toLocaleDateString('en-CA') + 'T00:00:00';
            const to_date = data.to_date.toLocaleDateString('en-CA') + 'T23:59:59';
            dispatch(
                actions.getAllTransactions({
                    payment_type: data.payment_type,
                    status: data.status,
                    from_date: moment(from_date).format('X'),
                    to_date: moment(to_date).format('X'),
                    ...(data.report_source === 'account'
                        ? { account_id: data.fromAccount?.id ?? null }
                        : {}),
                    ...(data.report_source === 'currency' ? { currency: data.currency } : {}),
                })
            );
        },
        [dispatch]
    );

    const filterMerlinTxnFields = (txn) => {
        const {
            transaction_id,
            amount,
            currency,
            status,
            created_at,
            account_id,
            account_type,
            reference,
            destination_account_number,
            destination_sort_code,
            destination_iban,
            destination_bic,
            destination_name,
            destination_email,
            source_name,
            source_account_number,
            source_sort_code,
            source_iban,
            source_bic,
            source_type,
            source_email,
            payment_date,
            payment_direction,
            payment_type,
            status_reason,
            status_note,
        } = txn;
        return {
            transaction_id,
            amount,
            currency,
            status,
            created_at,
            account_id,
            account_type,
            reference,
            destination_account_number,
            destination_sort_code,
            destination_iban,
            destination_bic,
            destination_name,
            destination_email,
            source_name,
            source_account_number,
            source_sort_code,
            source_iban,
            source_bic,
            source_type,
            source_email,
            payment_date,
            payment_direction,
            payment_type,
            status_reason,
            status_note,
        };
    };

    const filterMccyTxnFields = (txn) => {
        const {
            transaction_id,
            amount,
            currency,
            status,
            created_at,
            account_id,
            account_type,
            reference,
            destination_currency,
            destination_amount,
            destination_account_number,
            destination_sort_code,
            destination_iban,
            destination_bic,
            destination_name,
            destination_email,
            source_name,
            source_currency,
            source_amount,
            source_account_number,
            source_sort_code,
            source_iban,
            source_bic,
            source_type,
            source_email,
            quote_id,
            exchange_rate,
            parent_transaction_id,
            payment_date,
            payment_direction,
            payment_type,
            type_description,
            status_reason,
            status_note,
        } = txn;
        return {
            transaction_id,
            amount,
            currency,
            status,
            created_at,
            account_id,
            account_type,
            reference,
            destination_name,
            destination_currency,
            destination_amount,
            destination_account_number,
            destination_sort_code,
            destination_iban,
            destination_bic,
            destination_email,
            source_name,
            source_currency,
            source_amount,
            source_account_number,
            source_sort_code,
            source_iban,
            source_bic,
            source_type,
            source_email,
            quote_id,
            exchange_rate,
            parent_transaction_id,
            payment_date,
            payment_direction,
            payment_type,
            type_description,
            status_reason,
            status_note,
        };
    };

    const MyDoc = (data) => {
        if (isMerlinOnly) {
            return <MerlinReport data={data} />;
        } else {
            return <NeptuneReport data={data} />;
        }
    };

    const downloadPdf = async (txns, fileName) => {
        let blobPDF = await pdf(MyDoc(txns)).toBlob();
        FileSaver.saveAs(blobPDF, fileName);
    };

    useEffect(() => {
        if (transactions.length > 0) {
            let filteredTransactions = [];
            if (isMerlinOnly) {
                filteredTransactions = transactions.map((txn) => filterMerlinTxnFields(txn));
            } else {
                filteredTransactions = transactions.map((txn) => filterMccyTxnFields(txn));
            }
            const timestamp = moment().format('YYYYMMDD_HHmmss');
            const sorted_txns = filteredTransactions.toReversed();
            const fileName = `merge_report_${timestamp}`;
            if (format === 'csv') {
                exportToCsv(sorted_txns, `${fileName}.csv`);
            } else if (format === 'xls') {
                exportToXlsx(sorted_txns, `${fileName}.xlsx`);
            } else if (format === 'pdf') {
                downloadPdf(sorted_txns, `${fileName}.pdf`);
            }
            dispatch(actions.clearTransactions());
        }
        // eslint-disable-next-line
    }, [dispatch, transactions, format]);

    useEffect(() => {
        var message = null;
        var statusVariant = 'message';
        if (!transactionStatus) {
            message = 'Report downloading...';
            statusVariant = 'info';
        } else if (transactionStatus === 'NO_TRANSACTIONS') {
            message = 'No transactions found';
            statusVariant = 'success';
        } else if (transactionStatus === 'SUCCESS') {
            message = 'Report downloaded successfully';
            statusVariant = 'success';
        } else if (transactionStatus === 'FAILED') {
            message =
                'Report failed to download. Please retry in some time. If issue persists reach out to cs@merge.money';
            statusVariant = 'error';
        }
        setStatusMessage(message);
        setStatusVariant(statusVariant);
    }, [loading, transactions, transactionStatus]);
    return (
        <>
            <div className={cx(styles.container)}>
                <h1 className={cx(styles.title)}>Transaction reports</h1>
                <ReportsFilterV2 onDownload={(data) => onDownload(data)} />
            </div>
            <DialogV2
                title={'Download report'}
                open={isDownloadModalOpen}
                setOpen={() => setIsDownloadModalOpen(!isDownloadModalOpen)}
                className={'reports-modal'}
                submitButton={'Close'}
                submitAction={() => setIsDownloadModalOpen(false)}
                variant={statusVariant}>
                {statusMessage}
            </DialogV2>
        </>
    );
};

export default Report;
