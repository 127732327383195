export const paymentRequestStatuses = [
    {
        label: 'Approved',
        value: 'approved',
    },
    {
        label: 'Created',
        value: 'created',
    },
    {
        label: 'Expired',
        value: 'expired',
    },
    {
        label: 'Processing',
        value: 'processing',
    },
    {
        label: 'Rejected',
        value: 'rejected',
    },
    {
        label: 'Retry',
        value: 'retry',
    },
];

export const paymentRequestTypes = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Payout',
        value: 'payout',
    },
];
