import { createSlice } from '@reduxjs/toolkit';

/*
 *  @desc The users slice manages the state of all users
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    loading: false,
    usersList: [],
    userAdminCount: 0,
    usersListOutcome: {
        status: null,
        error: '',
    },
    rolesList: [],
    rolesListOutcome: {
        status: null,
        error: '',
    },
    updateUserOutcome: {
        status: null,
        user: null,
        error: '',
    },
    addUserOutcome: {
        status: null,
        error: '',
    },
    deleteUserOutcome: {
        status: null,
        error: '',
    },
};

const getAdminCount = (usersList) => {
    let _userAdminsCount = 0;
    usersList.forEach((user) => {
        if (user.roles.indexOf('user_admin') > -1) {
            _userAdminsCount += 1;
        }
    });
    return _userAdminsCount;
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        clearUserCreateState(state) {
            state.loading = false;
            state.addUserOutcome = {
                status: null,
                error: '',
            };
        },
        clearUserUpdateState(state) {
            state.loading = false;
            state.updateUserOutcome = {
                status: null,
                user: null,
                error: '',
            };
        },
        clearUserDeleteState(state) {
            state.loading = false;
            state.deleteUserOutcome = {
                status: null,
                error: '',
            };
        },
        clearState(state) {
            state.loading = false;
            state.usersListOutcome = {
                status: null,
                error: '',
            };
            state.usersList = [];
            state.userAdminCount = 0;
            state.updateUserOutcome = {
                status: null,
                user: null,
                error: '',
            };
            state.addUserOutcome = {
                status: null,
                error: '',
            };
            state.deleteUserOutcome = {
                status: null,
                error: '',
            };
        },
        getUsers(state) {
            state.loading = true;
        },
        getUsersSuccess(state, action) {
            state.usersList = action.payload.data;
            state.userAdminCount = getAdminCount(action.payload.data);
            state.usersListOutcome = {
                status: 'success',
                error: '',
            };
            state.updateUserOutcome = {
                status: null,
                user: null,
                error: '',
            };
            state.addUserOutcome = {
                status: null,
                error: '',
            };
            state.deleteUserOutcome = {
                status: null,
                error: '',
            };
            state.loading = false;
        },
        getUsersFailed(state, action) {
            state.loading = false;
            state.usersList = [];
            state.usersListOutcome = {
                status: 'failed',
                error: { ...action.payload },
            };
        },
        getRoles(state) {
            state.loading = true;
        },
        getRolesSuccess(state, action) {
            state.loading = false;
            state.rolesList = action.payload.data;
            state.rolesListOutcome = {
                status: 'success',
                error: '',
            };
        },
        getRolesFailed(state, action) {
            state.loading = false;
            state.rolesList = [];
            state.rolesListOutcome = {
                status: 'failed',
                error: { ...action.payload },
            };
        },
        addUser(state) {
            state.loading = true;
        },
        addUserSuccess(state) {
            state.loading = false;
            state.addUserOutcome = {
                status: 'success',
                error: '',
            };
        },
        addUserFailed(state, action) {
            state.loading = false;
            state.addUserOutcome = {
                status: 'failed',
                error: { ...action.payload },
            };
        },
        deleteUser(state) {
            state.loading = true;
        },
        deleteUserSuccess(state) {
            state.loading = false;
            state.deleteUserOutcome = {
                status: 'success',
                error: '',
            };
        },
        deleteUserFailed(state, action) {
            state.loading = false;
            state.deleteUserOutcome = {
                status: 'failed',
                error: { ...action.payload },
            };
        },
        updateUser(state) {
            state.loading = true;
        },
        updateUserSuccess(state, action) {
            state.loading = false;
            state.updateUserOutcome = {
                status: 'success',
                user: action.payload.user,
                error: '',
            };
        },
        updateUserFailed(state, action) {
            state.loading = false;
            state.updateUserOutcome = {
                status: 'failed',
                user: null,
                error: { ...action.payload },
            };
        },
    },
});

export const { actions, reducer, name } = usersSlice;
