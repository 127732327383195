export const transactionTypes = [
    {
        label: 'Payout',
        value: 'payout',
    },
    {
        label: 'Payin',
        value: 'payin',
    },
    {
        label: 'Fee',
        value: 'fee',
    },
];

export const transactionStatuses = [
    {
        label: 'Processing',
        value: 'processing',
    },
    {
        label: 'Successful',
        value: 'successful',
    },
    {
        label: 'Failed',
        value: 'failed',
    },
];

export const statement_format = [
    {
        label: 'PDF',
        value: 'pdf',
        type: 'format',
    },
];

export const download_format = [
    {
        label: 'CSV',
        value: 'csv',
        type: 'format',
    },
    {
        label: 'XLS',
        value: 'xls',
        type: 'format',
    },
    {
        label: 'PDF',
        value: 'pdf',
        type: 'format',
    },
];

export const report_sources = [
    {
        label: 'Account',
        value: 'account',
        type: 'report_source',
    },
    {
        label: 'Currency',
        value: 'currency',
        type: 'report_source',
    },
    {
        label: 'None',
        value: '',
        type: 'report_source',
    },
];
