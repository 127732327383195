import { createSelector } from '@reduxjs/toolkit';
import { initialState as usersState } from 'store/slice/userManagement';
import { useSelector } from 'react-redux';

const selectUserManagement = (state) => {
    return state.users || usersState;
};

const select = () => createSelector(selectUserManagement, (state) => state);

/* eslint-disable */
export default () => useSelector(select());
