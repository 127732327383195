import { createSlice } from '@reduxjs/toolkit';

/*
 *  @desc The payments slice manages the state of the dashboard
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    transactionSummary: {
        loading: false,
        error: null,
        transactions: [],    
    }
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        clearState: () => initialState,
        loadTransactions(state) {
            state.transactionSummary.loading = true;
            state.transactionSummary.transactions = [];
            state.transactionSummary.error = null;
        },
        loadTransactionsSuccess(state, action) {
            state.transactionSummary.loading = false;
            state.transactionSummary.transactions = action.payload;
            state.transactionSummary.error = null;
        },
        loadTransactionsError(state, action) {
            state.transactionSummary.loading = false;
            state.transactionSummary.transactions = [];
            state.transactionSummary.error = action.payload;
        },
    },
});

export const { actions, reducer, name } = dashboardSlice;
