import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { useSelector } from 'react-redux';

const selectDomain = (state) => state?.content || initialState;

const select = () => createSelector([selectDomain], (state) => state);

// eslint-disable-next-line
export default () => useSelector(select());
