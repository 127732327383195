import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slice/dashboard';
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';

const {
    transactions: { transaction },
} = Endpoints;

export function* loadTransactions() {
    try {
        const { data } = yield call(request().get, transaction.url());
        if (data) {
            yield put(actions.loadTransactionsSuccess(data.items));
        }
    } catch (err) {
        yield put(actions.loadTransactionsError(err.message));
    }
}

export function* dashboardSaga() {
    yield takeLatest(actions.loadTransactions.type, loadTransactions);
}
