import React, { useState } from 'react';
import cx from 'classnames';
import Icon from '../Icon';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './sidemenu.module.scss';
import CustomTooltip from '../CustomTooltip';
import {
    List,
    Collapse,
    ListItemButton,
    ListItemText,
    useMediaQuery,
    Divider,
    Paper,
    ClickAwayListener,
} from '@mui/material';
import {
    FaviconWrapper,
    ListItemWrapper,
    StyledChevron,
    StyledDrawer,
    StyledFooterMenu,
    StyledIconListItem,
    StyledLogo,
    StyledMenuList,
    StyledPopper,
} from './styles';

const SideMenu = ({
    menuItems,
    handleNavigate,
    toggle,
    openSubMenu,
    footerMenu,
    menuDisabled,
    featureFlags,
    collapse,
    onCollapseClick,
    handleToggleClick,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    let window;
    const isMobile = useMediaQuery('(max-width:900px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSubMenuItem, setOpenSubMenuItem] = useState(false);

    const handleBlur = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setOpenSubMenuItem(false);
        }
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const listItems = (
        <List>
            {menuItems
                .filter((item) => item.hide !== true)
                .map((menu, index) => (
                    <React.Fragment key={index}>
                        <ListItemButton
                            button={'true'}
                            key={menu.id}
                            selected={location.pathname.includes(menu.path?.toLowerCase())}
                            onClick={() => {
                                handleNavigate(menu.path, !!menu?.children?.length);
                                if (isMobile) {
                                    handleToggleClick();
                                }
                            }}
                            disabled={!menuDisabled || menu.disabled}>
                            <ListItemWrapper>
                                {collapse ? (
                                    <CustomTooltip info={menu.title} enableInfoStyle={false}>
                                        <Icon
                                            name={menu.icon}
                                            className={cx(styles.menuItemsIcon)}
                                        />
                                    </CustomTooltip>
                                ) : (
                                    <Icon name={menu.icon} className={cx(styles.menuItemsIcon)} />
                                )}
                                {!collapse && <ListItemText primary={menu.title} />}
                                {!collapse && menu.children.length > 0 && (
                                    <Icon
                                        name={openSubMenu ? 'caret-up' : 'caret-down'}
                                        className={cx(styles.caretDown)}
                                    />
                                )}
                            </ListItemWrapper>
                        </ListItemButton>

                        {!collapse && (
                            <Collapse in={openSubMenu} key={menu.icon} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding key={index}>
                                    {menu?.children?.length > 0 &&
                                        openSubMenu &&
                                        menu.children?.map((subMenu) => (
                                            <ListItemButton
                                                button={'true'}
                                                key={subMenu.id}
                                                onClick={() => navigate(subMenu.path)}
                                                disabled={!menuDisabled || subMenu.disabled}
                                                selected={
                                                    location.pathname ===
                                                    subMenu.path?.toLowerCase()
                                                }>
                                                <StyledIconListItem sx={{ pl: 4 }}>
                                                    <Icon name={subMenu.icon} />
                                                </StyledIconListItem>
                                            </ListItemButton>
                                        ))}
                                </List>
                            </Collapse>
                        )}
                    </React.Fragment>
                ))}
        </List>
    );

    const footerItems = (
        <StyledFooterMenu>
            <Divider />
            <List>
                {footerMenu.map((menu, index) => (
                    <React.Fragment key={menu.path}>
                        <ListItemButton
                            button={'true'}
                            key={index}
                            selected={location.pathname.includes(menu.path?.toLowerCase())}
                            onClick={(event) => {
                                if (!openSubMenuItem && collapse && menu?.children?.length > 0) {
                                    setAnchorEl(event.currentTarget);
                                    setOpenSubMenuItem((state) => !state);
                                    return;
                                }
                                handleNavigate(menu.path, !!menu?.children?.length);
                                if (isMobile) {
                                    handleToggleClick();
                                }
                            }}
                            disabled={menu.disabled}
                            id={menu.icon}>
                            <ListItemWrapper>
                                <CustomTooltip
                                    info={collapse ? menu.title : ''}
                                    enableInfoStyle={false}>
                                    <Icon name={menu.icon} className={cx(styles.menuItemsIcon)} />
                                </CustomTooltip>
                                {!collapse && <ListItemText primary={menu.title} />}
                                {!collapse && menu?.children?.length > 0 && (
                                    <Icon
                                        name={openSubMenu ? 'caret-up' : 'caret-down'}
                                        className={cx(styles.caretDown)}
                                    />
                                )}
                            </ListItemWrapper>
                        </ListItemButton>
                        {!collapse && (
                            <Collapse in={openSubMenu} key={menu.icon} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding key={index}>
                                    {menu?.children?.length > 0 &&
                                        openSubMenu &&
                                        menu.children?.map((subMenu) => (
                                            <ListItemButton
                                                button={'true'}
                                                key={subMenu.id}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleNavigate(subMenu.path, false);
                                                    if (isMobile) {
                                                        handleToggleClick();
                                                    }
                                                }}
                                                disabled={subMenu.disabled}
                                                selected={
                                                    location.pathname ===
                                                    subMenu.path?.toLowerCase()
                                                }>
                                                <StyledIconListItem sx={{ pl: 4 }}>
                                                    <Icon name={subMenu.icon} />
                                                </StyledIconListItem>
                                                <ListItemText primary={subMenu.title} />
                                            </ListItemButton>
                                        ))}
                                </List>
                            </Collapse>
                        )}
                        <StyledPopper
                            id={menu.id || 'default-popper-id'}
                            open={openSubMenuItem}
                            anchorEl={anchorEl}
                            placement="right-end"
                            transition>
                            <ClickAwayListener onClickAway={() => setOpenSubMenuItem(false)}>
                                <Paper role="presentation">
                                    <List component="div" disablePadding key={index}>
                                        {menu?.children?.length > 0 &&
                                            openSubMenuItem &&
                                            menu.children?.map((subMenu) => (
                                                <ListItemButton
                                                    button={'true'}
                                                    key={subMenu.id}
                                                    onFocus={(event) => {
                                                        event.stopPropagation();
                                                        handleNavigate(subMenu.path, false);
                                                        if (isMobile) {
                                                            handleToggleClick();
                                                        }
                                                    }}
                                                    onBlur={handleBlur}
                                                    disabled={subMenu.disabled}
                                                    selected={
                                                        location.pathname ===
                                                        subMenu.path?.toLowerCase()
                                                    }>
                                                    <Icon name={subMenu.icon} />
                                                    <ListItemText primary={subMenu.title} />
                                                </ListItemButton>
                                            ))}
                                    </List>
                                </Paper>
                            </ClickAwayListener>
                        </StyledPopper>
                    </React.Fragment>
                ))}
            </List>
        </StyledFooterMenu>
    );

    return (
        <>
            {!isMobile && (
                <StyledChevron collapse={collapse}>
                    <Icon name={'left-chevron'} onClick={onCollapseClick} />
                </StyledChevron>
            )}
            <StyledDrawer
                container={container}
                anchor="left"
                collapse={Boolean(collapse).toString()}
                variant={`${isMobile ? 'temporary' : 'permanent'}`}
                open={isMobile ? toggle : true}
                className={cx(styles.drawer)}>
                <StyledLogo collapse={collapse}>
                    {!collapse && toggle && (
                        <Icon
                            name={'menu'}
                            onClick={handleToggleClick}
                            style={{ marginRight: '20px' }}
                        />
                    )}
                    {collapse ? (
                        <CustomTooltip info={'Home'} enableInfoStyle={false}>
                            <FaviconWrapper
                                onClick={() => {
                                    navigate('/dashboard/home');
                                }}>
                                <Icon name={'merge-favicon'} className={styles.logoIcon} />
                            </FaviconWrapper>
                        </CustomTooltip>
                    ) : (
                        <CustomTooltip info={'Home'} enableInfoStyle={false}>
                            <Icon
                                onClick={() => {
                                    navigate('/dashboard/home');
                                }}
                                name={'logo'}
                                className={styles.logoIcon}
                            />
                        </CustomTooltip>
                    )}
                </StyledLogo>
                <StyledMenuList>
                    {listItems}
                    {footerItems}
                </StyledMenuList>
            </StyledDrawer>
        </>
    );
};
export default SideMenu;
