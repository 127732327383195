import { createSlice } from '@reduxjs/toolkit';

/*
 *  @desc The payments slice manages the state of the pending payments
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    loading: false,
    error: {
        status: 200,
        message: '',
    },
    otpError: {
        status: 200,
        message: '',
    },
    deactivateOtpError: {
        status: 200,
        message: '',
    },
    otp: false,
    deactivateOtp: false,
    token: null,
    deactivateToken: null,
    apiKey: null,
    createdKey: null,
    deactivateKeyStatus: null,
    otpCount: 0,
    deactivateOtpCount: 0,
};

export const developerSlice = createSlice({
    name: 'developer',
    initialState,
    reducers: {
        getApiKeys(state) {
            state.loading = true;
            state.error = {
                status: 200,
            };
        },
        getApiKeysSuccess(state, action) {
            state.loading = false;
            state.apiKey = action.payload;
            state.deactivateKeyStatus = null;
            state.error = {
                status: 200,
            };
        },
        getApiKeysError(state, action) {
            state.loading = false;
            state.apiKey = null;
            state.deactivateKeyStatus = null;
            state.error = { ...action.payload };
        },
        getOtp: (state, action) => {
            state.otp = false;
            state.token = '';
            state.otpError = {
                status: 200,
                message: '',
            };
            state.loading = true;
        },
        getOtpSuccess: (state, action) => {
            state.otp = true;
            state.token = action.payload?.token;
            state.loading = false;
        },
        getOtpError: (state, action) => {
            state.otp = false;
            state.token = null;
            state.otpError = { ...action.payload };
            state.loading = false;
        },
        getDeactivateOtp: (state, action) => {
            state.deactivateOtp = false;
            state.deactivateOtpError = {
                status: 200,
                message: '',
            };
            state.deactivateToken = '';
            state.loading = true;
        },
        getDeactivateOtpSuccess: (state, action) => {
            state.deactivateOtp = true;
            state.deactivateToken = action.payload?.token;
            state.loading = false;
        },
        getDeactivateOtpError: (state, action) => {
            state.deactivateOtp = false;
            state.deactivateToken = null;
            state.deactivateOtpError = { ...action.payload };
            state.loading = false;
        },
        clearData: (state) => {
            state.otp = false;
            state.token = null;
            state.deactivateOtp = false;
            state.deactivateToken = null;
            state.error = {};
            state.otpError = {};
            state.deactivateOtpError = {
                status: 200,
                message: '',
            };
            state.loading = false;
            state.createdKey = null;
            state.apiKey = null;
            state.deactivateKeyStatus = null;
            state.otpCount = 0;
            state.deactivateOtpCount = 0;
        },
        createApiKey(state) {
            state.loading = true;
            state.error = {
                status: false,
            };
        },
        createApiKeySuccess(state, action) {
            state.loading = false;
            state.createdKey = action.payload;
            state.error = {
                status: false,
            };
        },
        createApiKeyError(state, action) {
            state.loading = false;
            state.apiKey = null;
            state.otpError = { ...action.payload };
            state.otpCount = state.otpCount + 1;
        },
        deactivateApiKey(state) {
            state.loading = true;
            state.deactivateOtpError = {
                status: false,
                message: '',
            };
        },
        deactivateApiKeySuccess(state) {
            state.loading = false;
            state.apiKey = null;
            state.deactivateKeyStatus = 'success';
            state.deactivateOtpError = {
                status: false,
                message: '',
            };
        },
        deactivateApiKeyError(state, action) {
            state.loading = false;
            state.deactivateKeyStatus = 'failed';
            state.deactivateOtpError = { ...action.payload };
            state.deactivateOtpCount = state.deactivateOtpCount + 1;
        },
    },
});

export const { actions, reducer, name } = developerSlice;
