import styles from 'assets/styles/users.module.scss';
import { useFormik } from 'formik';
import { actions } from 'store/slice/userManagement';
import Checkbox from '@mui/material/Checkbox';
import { updateRolesValue, validationSchema } from './utils';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import cx from 'classnames';
import { Grid } from '@mui/material';
import { CustomTooltip, Dialog, InputField } from 'components/common';
import selectUsersState from 'store/selectors/userManagement';
import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import DialogV2 from 'components/common/DialogV2';

const UserCreate = ({ handleClose }) => {
    const { loading, rolesList, userAdminCount, addUserOutcome } = selectUsersState();
    const dispatch = useDispatch();
    const [toggleIcon, setToggleIcon] = useState('show-password');
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            phone_number: '',
            txn_email_otp_enabled: false,
            is_active: true,
            roles: [],
        },
        validationSchema: validationSchema(userAdminCount, false, false),
        onSubmit: (values) => {
            dispatch(actions.addUser(values));
        },
    });

    useEffect(() => {
        if (addUserOutcome.status === null) {
            return;
        }
        if (addUserOutcome.status === 'failed') {
            setErrorMsg(addUserOutcome.error.message);
        }
        if (addUserOutcome.status === 'success') {
            formik.resetForm();
            // Resetting roles manually as it is not happening automatically
            formik.setFieldTouched('roles', false);
            formik.setFieldValue('roles', []);
            dispatch(actions.clearUserCreateState());
            setErrorMsg(null);
            setShowSuccessModal(true);
        }
        // eslint-disable-next-line
    }, [addUserOutcome, dispatch]);

    const closeDialog = () => {
        dispatch(actions.clearUserCreateState());
        formik.resetForm({
            values: formik.initialValues,
        });
        handleClose();
    };

    const toggleShowPassword = useCallback(() => {
        setToggleIcon(toggleIcon === 'show-password' ? 'hide-password' : 'show-password');
    }, [toggleIcon]);

    const handleChange = useCallback(
        (event) => {
            formik.setValues((values) => ({
                ...values,
                [event.target.name]: event.target.value,
            }));
        },
        [formik]
    );

    const handlePhoneNumberChange = useCallback(
        (event) => {
            const value = event.target.value;
            if (value === '') {
                // Allow empty values to clean existing field
                formik.setValues((values) => ({
                    ...values,
                    [event.target.name]: '+',
                }));
                return;
            }
            if (value.search(/^((\+\d{0,20}))$/) !== 0) {
                // Allow only specific values
                return;
            }
            formik.setValues((values) => ({
                ...values,
                [event.target.name]: value,
            }));
        },
        [formik]
    );

    const handleRolesChange = useCallback(
        (event) => {
            formik.setFieldTouched('roles', true);
            formik.setFieldValue(
                'roles',
                updateRolesValue(formik.values.roles, event.target.value, event.target.checked)
            );
        },
        [formik]
    );

    return (
        <>
            <Dialog
                title={'Create user'}
                fullWidth
                open={true}
                isSingleButton={false}
                // Disable the buttons on load(i.e. submitting user create) or if the form values are not valid
                isDisable={loading || !formik.dirty || (formik.dirty && !formik.isValid)}
                className={'users-modal'}
                setOpen={closeDialog}
                submitButton={'Create User'}
                cancelButton={'Cancel'}
                cancelAction={closeDialog}
                submitAction={() => {
                    dispatch(actions.addUser(formik.values));
                }}
                paperProps={{
                    maxWidth: '100%',
                    width: '80%',
                }}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <div className={cx(styles.confirmationDialog)}>
                    <div className={cx(styles.confirmation)}>
                        {errorMsg && <h3 className={cx(styles.errorMsg)}>{errorMsg}</h3>}
                        <form onSubmit={formik.handleSubmit}>
                            <Grid className={cx(styles.gridRow)}>
                                <div className={cx(styles.gridRowEl)}>
                                    <InputField
                                        name={'first_name'}
                                        label={'First name'}
                                        required={true}
                                        value={formik.values.first_name}
                                        onChange={handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.first_name &&
                                            Boolean(formik.errors.first_name)
                                        }
                                        helperText={
                                            formik.touched.first_name && formik.errors.first_name
                                        }
                                        id={'first_name'}
                                        className={styles.usersInput}
                                    />
                                </div>
                                <div className={cx(styles.gridRowEl)}>
                                    <InputField
                                        name={'last_name'}
                                        label={'Last name'}
                                        required={true}
                                        value={formik.values.last_name}
                                        onChange={handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.last_name &&
                                            Boolean(formik.errors.last_name)
                                        }
                                        helperText={
                                            formik.touched.last_name && formik.errors.last_name
                                        }
                                        id={'last_name'}
                                    />
                                </div>
                            </Grid>
                            <Grid className={cx(styles.gridRow)}>
                                <div className={cx(styles.gridRowEl)}>
                                    <InputField
                                        name={'email'}
                                        label={'Email'}
                                        required={true}
                                        value={formik.values.email}
                                        onChange={handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        id={'email'}
                                    />
                                </div>
                                <div className={cx(styles.gridRowEl)}>
                                    <InputField
                                        type={toggleIcon === 'show-password' ? 'password' : 'text'}
                                        name={'password'}
                                        label={'Password'}
                                        value={formik.values.password}
                                        onBlur={formik.handleBlur}
                                        onChange={handleChange}
                                        handleIconClick={toggleShowPassword}
                                        required={true}
                                        hasIcon={true}
                                        iconPosition="end"
                                        iconName={toggleIcon}
                                        error={
                                            formik.touched.password &&
                                            Boolean(formik.errors.password)
                                        }
                                        helperText={
                                            formik.touched.password && formik.errors.password
                                        }
                                        id={'password'}
                                    />
                                </div>
                            </Grid>
                            <Grid item className={cx(styles.gridRow)} xs={6}>
                                <div className={cx(styles.gridRowEl)}>
                                    <InputField
                                        name={'phone_number'}
                                        label={'Mobile number'}
                                        required={true}
                                        value={formik.values.phone_number || '+'}
                                        onBlur={formik.handleBlur}
                                        onChange={handlePhoneNumberChange}
                                        error={
                                            formik.touched.phone_number &&
                                            Boolean(formik.errors.phone_number)
                                        }
                                        helperText={
                                            formik.touched.phone_number &&
                                            formik.errors.phone_number
                                        }
                                        id={'phone_number'}
                                    />
                                </div>
                            </Grid>
                            <Grid container className={styles.userWrapper}>
                                <Grid item className={styles.usersRolesLabel}>
                                    Roles:
                                </Grid>
                                <Grid item>
                                    <FormControl
                                        required
                                        error={Boolean(formik.errors.roles)}
                                        component="fieldset"
                                        variant="standard">
                                        <FormGroup className={cx(styles.rolesWrapper)}>
                                            {rolesList.map(({ label, value, description }) => {
                                                return (
                                                    <CustomTooltip
                                                        key={value}
                                                        info={description ?? ''}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={formik.values.roles?.includes(
                                                                        value
                                                                    )}
                                                                    name={label}
                                                                    value={value}
                                                                    onChange={handleRolesChange}
                                                                />
                                                            }
                                                            label={label}
                                                            key={label}
                                                        />
                                                    </CustomTooltip>
                                                );
                                            })}
                                        </FormGroup>
                                        {formik.touched.roles && formik.errors.roles && (
                                            <FormHelperText className={cx(styles.invalid)}>
                                                {formik.errors.roles}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Dialog>
            <DialogV2
                title={'User created'}
                open={showSuccessModal}
                setOpen={handleClose}
                className={'users-modal'}
                submitButton={'Continue'}
                cancelButton={'Cancel'}
                cancelAction={handleClose}
                submitAction={() => {
                    setShowSuccessModal(false);
                }}
                variant={'success'}>
            User is created successfully. If you want to create another user then press Continue, else press Cancel.
            </DialogV2>
        </>
    );
};

export default UserCreate;
