import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slice/company';
import { request } from '../../utils/api';

function* getCompanyDataWorker() {
    try {
        const { data } = yield call(request().get, 'business-information/company');
        yield put(actions.getCompanySuccess(data));
    } catch (error) {
        const { data, status } = error?.response;
        yield put(actions.getCompanyError(data?.message, status));
    }
}

export function* companySaga() {
    yield takeLatest(actions.getCompanyData.type, getCompanyDataWorker);
}
