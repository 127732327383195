import React, { useCallback, useEffect, useState } from 'react';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, TextField } from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/common.module.scss';
import { Icon } from '../index';
import InputAdornment from '@mui/material/InputAdornment';

const RangePicker = ({ values, minDate, maxDate, onChange, required }) => {
    const [value, setValue] = useState([null, null]);

    useEffect(() => {
        setValue(values);
    }, [values]);

    const startAdornment = {
        startAdornment: (
            <InputAdornment position={'start'} className={cx(styles['start-adornment'])}>
                <Icon name={'calendar'} />
            </InputAdornment>
        ),
    };

    // useEffect(() => {
    //     if(reset){
    //         setValue([null, null]);
    //     }
    // }, [reset]);

    const handleChange = useCallback(
        (date) => {
            setValue(date);
            onChange(date);
        },
        [onChange]
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
                minDate={minDate}
                maxDate={maxDate}
                calendars={1}
                value={value}
                disableFuture={true}
                disableAutoMonthSwitching={true}
                disableCloseOnSelect={false}
                onChange={handleChange}
                renderInput={(startProps, endProps) => (
                    <React.Fragment>
                        <Box className={styles.fromDate}>
                            <p>From (Date) {required && <span className={cx(styles.asterisk)}> *</span>}</p>
                            <TextField
                                {...startProps}
                                label={''}
                                name={'fromDate'}
                                className={cx(styles.pickerRange)}
                                InputProps={startAdornment}
                                // sx={{width: '100%', marginLeft: '6px'}}
                            />
                        </Box>
                        <Box className={styles.toDate}>
                            <p>To (Date) {required && <span className={cx(styles.asterisk)}> *</span>}</p>
                            <TextField
                                {...endProps}
                                name={'toDate'}
                                label={''}
                                className={cx(styles.pickerRange)}
                                InputProps={startAdornment}
                                // sx={{maxWidth: '146px', marginRight: '8px'}}
                            />
                        </Box>
                    </React.Fragment>
                )}
            />
        </LocalizationProvider>
    );
};

export default RangePicker;
