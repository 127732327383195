import React from 'react';
import { Collapse } from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/transaction.module.scss';
import SimulateTransaction from './SimulateTransaction';
import { isProd } from 'utils/helpers';
import TransactionView from 'components/common/Transaction/TransactionView';

const TransactionDetails = ({ transaction, isOpen }) => {
    const { payment_type, status, transaction_id } = transaction;
    const simulateMeta = {
        paymentType: payment_type,
        status,
        transactionId: transaction_id,
    };

    return (
        <Collapse
            in={isOpen}
            timeout="auto"
            unmountOnExit
            key={transaction.id}
            className={cx(styles.collapsible)}>
            {!isProd && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '50px' }}>
                    <SimulateTransaction simulateMeta={simulateMeta} />
                </div>
            )}
            <TransactionView transaction={transaction}/>
        </Collapse>
    );
};

export default TransactionDetails;
