import React from 'react';
import { Autocomplete, Box, createTheme, Icon, TextField } from '@mui/material';
import CustomTooltip from '../CustomTooltip';
import styles from 'assets/styles/common.module.scss';
import { ThemeProvider } from '@emotion/react';
import cx from 'classnames';
import CountryIcon from '../CountryIcon';

const SelectCountry = (props) => {
    const {
        onChange,
        name,
        value,
        label,
        info,
        error,
        disable,
        required,
        countries,
        className,
        selectClassName,
    } = props;

    const getSelectedItem = () => {
        const item = countries.find((opt) => opt.value === value);
        return item || {};
    };

    const handleOnChange = (_, v) => {
        onChange(name, v?.value ?? '');
    };
    const materialTheme = createTheme({
        components: {
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-root': {
                            padding: '4.5px 9px',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        width: '100%',
                    },
                },
            },
        },
    });

    return (
        <div style={{ height: error ? '110px' : '80px' }} className={className}>
            <div className={cx(disable ? styles.disable : styles.label)}>
                {label}
                {required && <span className={cx(styles.asterisk)}> *</span>}
                {info && (
                    <CustomTooltip info={info}>
                        <Icon name={'info'} />
                    </CustomTooltip>
                )}
            </div>
            <ThemeProvider theme={materialTheme}>
                <div className={cx([error ? styles.errorBorder : styles.default, selectClassName])}>
                    <Autocomplete
                        options={countries}
                        name={name}
                        value={getSelectedItem()}
                        disabled={disable}
                        autoHighlight
                        onChange={handleOnChange}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => {
                            return `${option.label ?? ''}`;
                        }}
                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                                <Box
                                    key={key}
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...optionProps}>
                                    <CountryIcon
                                        countryCode={option.value}
                                        style={{
                                            height: 'auto',
                                            width: '20px',
                                        }}
                                    />
                                    {option.label} ({option.value})
                                </Box>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </div>
            </ThemeProvider>
        </div>
    );
};

export default SelectCountry;
