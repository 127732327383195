import React, { useCallback, useEffect, useRef, useState } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { createTheme } from '@mui/material/styles';
import { InputAdornment, Box, TextField } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import { ThemeProvider } from '@emotion/react';
import styles from 'assets/styles/common.module.scss';
import { Icon } from '../index';
import cx from 'classnames';

const materialTheme = (open) =>
    createTheme({
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: '15px',
                        height: '380px',
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-root': {
                            ...(open && {
                                borderColor: '#016C87',
                                boxShadow: '0 0 5px #016C87',
                            }),
                        },
                    },
                },
            },
            MuiInputAdornment: {
                styleOverrides: {
                    positionEnd: {
                        transform: `scale(1.13) rotate(${open ? 180 : 0}deg)`,
                    },
                },
            },
        },
    });

const DatePickerField = ({
    label,
    name,
    onChange,
    value,
    maxDate,
    minDate,
    disableFuture = true,
    textFieldClassname,
    labelClassname,
}) => {
    const [date, setDate] = useState(value);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const ref = useRef();

    useEffect(() => {
        setDate(value);
    }, [value]);

    const handleChange = useCallback(
        (newDate) => {
            setDate(newDate);
            onChange(newDate);
            setOpen(false);
        },
        [onChange]
    );

    const handleInputClick = () => {
        setOpen(true);
        setAnchorEl(ref.current);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={materialTheme(open)}>
                <DatePicker
                    value={date}
                    name={name}
                    maxDate={maxDate}
                    minDate={minDate}
                    onChange={handleChange}
                    disableFuture={disableFuture}
                    open={open}
                    onClose={() => setOpen(false)}
                    PopperProps={{
                        placement: 'bottom-end',
                        anchorEl,
                    }}
                    renderInput={(params) => (
                        <Box className={styles.toDateWrapper}>
                            <p className={labelClassname}>{label}</p>
                            <TextField
                                {...params}
                                label=""
                                ref={ref}
                                className={cx(styles.pickerRange, textFieldClassname)}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                            className={cx(styles['start-adornment'])}>
                                            <Icon name="calendar" />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon name="down-chevron" />
                                        </InputAdornment>
                                    ),
                                    onClick: handleInputClick,
                                }}
                            />
                        </Box>
                    )}
                />
            </ThemeProvider>
        </LocalizationProvider>
    );
};

export default DatePickerField;
