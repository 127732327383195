import { call, put, takeLatest } from 'redux-saga/effects';

import { actions } from 'store/slice/user';
import { request } from 'utils/api';

function* getUserDataWorker({ id }) {
    try {
        const { data } = yield call(request().get, `user-management/user/${id}`);
        yield put(actions.getUserDataSuccess(data));
    } catch (error) {
        yield put(actions.getUserDataFailure(error));
    }
}

export function* getUserData() {
    yield takeLatest(actions.getUserDataFailure.type, getUserDataWorker);
}
