import { createSlice } from '@reduxjs/toolkit';

/*
 *  @desc The app slice manages the state of feature flags
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    featureFlags: {
        maker_approver_enabled: false,
        monthly_account_statement_enabled: false,
    },
    loading: false,
    error: null,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        getFeatureFlag: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        getFeatureFlagsSuccess: (state, action) => {
            state.featureFlags = action.payload;
            state.loading = false;
            state.error = null;
        },
        getFeatureFlagsError: (state, action) => {
            state.featureFlags = action.payload;
            state.loading = false;
            state.error = true;
        },
    },
});

export const { actions, reducer, name } = appSlice;
