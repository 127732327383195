import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/users.module.scss';
import { Dialog } from 'components/common';
import { actions } from 'store/slice/userManagement';
import { userRoleNamesFromValues } from './utils';
import selectUsersState from 'store/selectors/userManagement';
import DialogV2 from 'components/common/DialogV2';

const UserDelete = ({ user, handleClose }) => {
    const dispatch = useDispatch();
    const { loading, rolesList, deleteUserOutcome } = selectUsersState();
    const [showReview, setShowReview] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);

    const closeDialog = () => {
        dispatch(actions.clearUserDeleteState());
        handleClose();
    };

    const deleteUser = (id) => {
        dispatch(actions.deleteUser({ id }));
    };

    useEffect(() => {
        if (deleteUserOutcome.status === null) {
            return;
        }
        if (deleteUserOutcome.status === 'failed') {
            setShowConfirmation(false);
            setErrorMsg(
                'The user could not be deleted. Please retry in some time. If issue persists reach out to cs@merge.money.'
            );
            setSuccessMsg(null);
        }
        if (deleteUserOutcome.status === 'success') {
            setShowReview(false);
            setShowConfirmation(false);
            setErrorMsg(null);
            setSuccessMsg('Deleted the user successfully');
            setShowSuccess(true);
        }
    }, [deleteUserOutcome]);

    return (
        <>
            <Dialog
                title={'Delete user'}
                fullWidth
                open={showReview}
                isActions={true}
                isSingleButton={false}
                isDisable={loading}
                className={'users-delete-modal'}
                setOpen={closeDialog}
                submitButton={'Delete User'}
                cancelButton={'Cancel'}
                cancelAction={closeDialog}
                submitAction={() => {
                    setShowConfirmation(true);
                }}
                paperProps={{
                    maxWidth: '100%',
                    width: '80%',
                }}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <>
                    {errorMsg && <h3 className={cx(styles.errorMsg)}>{errorMsg}</h3>}
                    {successMsg && <h3 className={cx(styles.successMsg)}>{successMsg}</h3>}
                    <Grid item xs={12} md={12} lg={12} sm={12} className={cx(styles['userReview'])}>
                        <Grid container>
                            <Grid
                                item
                                md={5}
                                xs={12}
                                sm={5}
                                lg={5}
                                xl={5}
                                className={cx(styles['review-step'])}>
                                <div className={cx(styles['review-sub-title'])}>
                                    <div>Review User details</div>
                                </div>
                                <div className={cx(styles['review-detail-label'])}>
                                    <div>First name:</div>
                                    <div
                                        className={cx(styles['review-detail-item'])}
                                        style={{ float: 'right' }}>
                                        {user?.first_name}
                                    </div>
                                </div>
                                <div className={cx(styles['review-detail-label'])}>
                                    <div style={{ minWidth: 150 }}>Email Address:</div>
                                    <div
                                        className={cx(styles['review-detail-item'])}
                                        style={{ float: 'right' }}>
                                        {user ? user.email : '-'}
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                md={5}
                                xs={12}
                                sm={5}
                                lg={5}
                                xl={5}
                                className={cx(styles['review-step'])}>
                                <div className={cx(styles['review-sub-title'])}>
                                    <div>&nbsp;</div> {/** Empty details to level up the details */}
                                </div>
                                <div className={cx(styles['review-detail-label'])}>
                                    <div>Last Name:</div>
                                    <div
                                        className={cx(styles['review-detail-item'])}
                                        style={{ float: 'right' }}>
                                        {user?.last_name}
                                    </div>
                                </div>
                                <div className={cx(styles['review-detail-label'])}>
                                    <div>Mobile number:</div>
                                    <div
                                        className={cx(styles['review-detail-item'])}
                                        style={{ float: 'right' }}>
                                        {user && user.phone_number}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} className={cx(styles['review-step'])}>
                                <div
                                    className={cx(styles['review-detail-label'])}
                                    style={{ justifyContent: 'start' }}>
                                    <div>Roles:</div>
                                    <div className={cx(styles['review-detail-item'])}>
                                        {userRoleNamesFromValues(rolesList, user.roles).join(', ')}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            </Dialog>
            <DialogV2
                    title={'Delete user'}
                    open={showConfirmation}
                    setOpen={() => setShowConfirmation(false)}
                    cancelButton={`Cancel`}
                    submitButton={'Delete'}
                    submitAction={() => deleteUser(user.id)}
                    cancelAction={() => setShowConfirmation(false)}
                    variant={'warning'}
                    content={''}
                    className={'deleteModal'}>
                Are you sure you want to delete this user?
            </DialogV2>
            <DialogV2
                    title={'User deleted'}
                    open={showSuccess}
                    setOpen={closeDialog}
                    isSingleButton={true}
                    submitButton={'Close'}
                    submitAction={closeDialog}
                    variant={'success'}>
                The user has been successfully deleted.
            </DialogV2>
        </>
    );
};

export default UserDelete;
