import React from 'react';
import Icon from './Icon';

const Loading = ({ className }) => {
    return (
        <div className={className}>
            <Icon name={'loading'} />
        </div>
    );
};
export default Loading;
