import React from 'react';
import { Grid } from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/send-funds.module.scss';
import selectState from 'store/selectors/funds';
import { Button, Icon } from 'components/common';
import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/funds';
import { useSearchParams } from 'react-router-dom';
import TransactionLink from 'components/common/Transaction/TransactionLink';

const Confirm = () => {
    const {
        transaction_details: { fromAccount },
        transaction_id,
        transactionStatus,
        transactionError,
    } = selectState();
    const dispatch = useDispatch();
    const [, setSearchParams] = useSearchParams();

    return (
        <Grid item xs={12} md={12} lg={12} sm={12} className={cx(styles['confirm-step'])}>
            <Grid container mb={4}>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                    {transactionStatus && transactionStatus === 'success' && (
                        <>
                            <Grid item md={12} xs={12} sm={12} className={cx(styles.txnSuccess)}>
                                <h1> Transaction is being processed!</h1>
                                <div className={cx(styles.txnInfo)}>
                                    Transaction ID:&nbsp;
                                    <TransactionLink accountId={fromAccount.id} transactionId={transaction_id}/>
                                </div>
                            </Grid>
                            <Grid item md={12} xs={12} sm={12}>
                                <Grid item md={12} xs={12} sm={12}>
                                    <Icon name={'funds-success'} size={'226px'} />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {transactionStatus && transactionStatus === 'error' && (
                        <>
                            <Grid item md={12} xs={12} sm={12}>
                                <h1 className={cx(styles['confirm-title-error'])}>
                                    {transactionError ?? 'Something went wrong!'}
                                </h1>
                            </Grid>
                            <Grid item md={12} xs={12} sm={12}>
                                <Icon name={'funds-error'} size={'175px'} />
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Button
                        text={'Make another payment'}
                        style={{ width: '33%' }}
                        className={cx(styles['review-button'], styles['submitButton'])}
                        size="lg"
                        onClick={() => {
                            setSearchParams({ from: fromAccount.id });
                            dispatch(actions.resetFunds());
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Confirm;
