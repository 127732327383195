import React from 'react';
import { Icon } from 'components/common';

import styles from 'assets/styles/background.module.scss';
import cx from 'classnames';

const Background = ({ children }) => {
    return (
        <div className={cx(styles.background)}>
            {/*<Icon name={'circle-gradient'} className={cx(styles.circleGradient)}/>*/}
            <div className={cx(styles.background__image)} />
            <div className={cx(styles.background__content)}>
                <div className={cx(styles.container)}>
                    <Icon name={'logo'} />
                    <p className={cx(styles.title)}>
                        Business
                        <br />
                        <span className={styles.green}>solutions</span>for
                        <br />
                        <span className={styles.gray}>digital</span>payments
                    </p>
                    <div className={cx(styles.info)}>
                        <div className={cx(styles.item)}>
                            <Icon name={'account-balance'} />
                            <p>Hold and receive multiple currencies in a licensed account</p>
                        </div>
                        <div className={cx(styles.item)}>
                            <Icon name={'language'} />
                            <p>Move money seamlessly and globally</p>
                        </div>
                        <div className={cx(styles.item)}>
                            <Icon name={'gpp-good'} />
                            <p>Manage risk with scalable and automated checks</p>
                        </div>
                    </div>
                </div>
                <div className={cx(styles.children)}>{children}</div>
            </div>
            <div className={cx(styles.small)}>
                {/*<img src={mobile} alt="mobile" className={cx(styles.curveSM)}/>*/}
                <Icon name={'background-sm'} className={cx(styles.curveSM)} />
                <Icon name={'circle-gradient'} className={cx(styles.circleGradient)} />
            </div>
        </div>
    );
};

export default Background;
