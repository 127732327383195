
import React from 'react';
import cx from 'classnames';
import styles from 'assets/styles/common.module.scss';

const PaymentValue = ({ label, value, valueType }) => {
    const getValueClass = () => {
        if (valueType === 'text') {
            return styles.paymentText;
        } else if (valueType === 'number') {
            return styles.paymentNumber;
        }
        return '';
    }
    return (
        <div className={cx(styles.paymentValueContainer)}>
            <div className={cx(styles.paymentValueLabel)}>{label}</div>
            <div className={cx([styles.paymentValue, getValueClass()])}>
                {value}
            </div>
        </div>
    );
};

export default PaymentValue;
