import React, { useCallback, useEffect, useRef } from 'react';
import { Button, Dialog } from 'components/common';
import cx from 'classnames';
import { Otp } from 'components/common';
import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/funds';
import selectState from 'store/selectors/funds';
import styles from 'assets/styles/send-funds.module.scss';
import styled from 'styled-components/macro';
import insufficientFundsPng from 'assets/images/pages/send-funds/insufficient-funds.png';
import { StyledOtpButtonWrapper } from 'components/common/Otp/styles';
import { Loading } from 'components/common';
import { PaymentDetail } from 'components/common/Payment';
import DialogV2 from 'components/common/DialogV2';

const StyledTransaction = styled.div`
    text-align: center;
    h2 {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        color: #111326;
        padding: 0 70px;

        @media (max-width: 992px) {
            padding: 0 20px;
        }
    }
`;

const Review = ({ handleCancel }) => {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const {
        transaction_details,
        otp,
        error,
        otpCount,
        loading,
        loadingTransaction,
        token,
        transactionStatus,
    } = selectState();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [showCancelModal, setShowCancelModal] = React.useState(false);
    const [OTP, setOTP] = React.useState('');
    const [isError, setIsError] = React.useState(false);

    useEffect(() => {
        if (transactionStatus === 'error') {
            setIsError(true);
        }
        return () => {
            setIsError(false);
        };
    }, [isError, transactionStatus]);

    useEffect(() => {
        dispatch(actions.resetOtp());
        if (otp) {
            setIsModalOpen(true);
        }
    }, [dispatch, otp]);

    const submitOTP = useCallback(() => {
        if (OTP.length === 6) {
            if (transaction_details.isQuoteTransaction) {
                dispatch(
                    actions.createQuoteTransaction({
                        OTP,
                        token,
                        transaction_details,
                    })
                );
            } else {
                dispatch(
                    actions.createTransaction({
                        OTP,
                        token,
                        transaction_details,
                    })
                );
            }
        } else {
            setIsError(true);
        }
    }, [transaction_details, OTP, token, dispatch]);

    const getOTP = useCallback(() => {
        if (transaction_details.isQuoteTransaction) {
            dispatch(actions.getQuoteTransactionOtp({ transaction_details }));
        } else {
            dispatch(actions.getOtp({ transaction_details }));
        }
    }, [dispatch, transaction_details]);

    const handleResend = useCallback(() => {
        setOTP('');
        getOTP();
        dispatch(actions.resetTransactionStatus());
    }, [dispatch, getOTP]);

    return (
        <div
            ref={ref}
            style={{
                display: 'flex',
                // minHeight: `calc(100vh - ${ref?.current?.offsetTop}px - 75px - 10px)`,
                flexDirection: 'column',
                position: 'relative',
            }}>
            {loading && <Loading className={cx(styles.fundsLoading)} />}
            <PaymentDetail
                details={transaction_details}
                handleOnEdit={() => dispatch(actions.setCurrentFundStep(0))}
            />
            <div className={cx(styles.divider)}></div>
            <div className={cx(styles.paymentActions)}>
                <div className={cx(styles.actionBtn)}>
                    <Button
                        text={'Cancel'}
                        variant="outlined"
                        onClick={() => setShowCancelModal(true)}
                    />
                </div>
                <div className={cx(styles.actionBtn)}>
                    <Button text={'Confirm'} onClick={getOTP} disabled={loading} />
                </div>
            </div>

            <DialogV2
                title={'Cancel transaction'}
                open={showCancelModal}
                isSingleButton={false}
                setOpen={() => setShowCancelModal(false)}
                cancelButton={'No'}
                submitButton={'Yes'}
                variant={'warning'}
                submitAction={handleCancel}
                cancelAction={() => setShowCancelModal(false)}>
                On clicking Yes, the changes will be discarded. Are you sure you want to cancel this
                transaction?
            </DialogV2>

            <Dialog
                title={!isError ? 'Authenticate' : 'Insufficient funds'}
                fullWidth
                open={isModalOpen}
                isSingleButton={false}
                className={'recipients-modal'}
                setOpen={() => setIsModalOpen(!isModalOpen)}
                submitButton={null}
                isActions={false}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <StyledTransaction>
                    {!isError ? (
                        <Otp
                            loading={loadingTransaction}
                            heading={
                                'Enter the one-time passcode sent to the registered mobile number.'
                            }
                            otp={OTP}
                            onChange={setOTP}
                            disabled={OTP.length !== 6 || transactionStatus === 'pending'}
                            length={6}
                            onSubmit={() => submitOTP()}
                            onResend={handleResend}
                            error={error}
                            buttonText={'Confirm and send funds'}
                            otpCount={otpCount}
                            maxTime={45}
                            isSecured={true}
                        />
                    ) : (
                        <>
                            <h2>Insufficient funds. Please select another amount and try again</h2>
                            <img
                                src={insufficientFundsPng}
                                alt={'insufficient-funds'}
                                style={{
                                    maxWidth: '100%',
                                    width: '164px',
                                    height: '164px',
                                    margin: '20px 0',
                                }}
                            />
                            <StyledOtpButtonWrapper>
                                <Button
                                    onClick={() => {
                                        dispatch(actions.resetTransactionStatus());
                                        setIsModalOpen(!isModalOpen);
                                    }}
                                    size="lg"
                                    text={'Try Again'}
                                />
                            </StyledOtpButtonWrapper>
                        </>
                    )}
                </StyledTransaction>
            </Dialog>
        </div>
    );
};

export default Review;
