import jwt from 'jwt-decode';
import store from 'store';

const initialState = {
    login: {
        isOtpVerified: false,
        user_id: '',
        company_id: '',
    },
    signup: {},
    isLoading: false,
    loginError: null,
    regError: null,
};

export const Roles = Object.freeze({
    BasicUser: 'basic_user',
    Developer: 'developer',
    ViewerAll: 'viewer_all',
    MakerAll: 'maker_all',
    ApproverAll: 'approver_all',
    InstantPayerAll: 'instant_payer_all',
    Reporter: 'reporter',
    UserAdmin: 'user_admin',
    AccountAdmin: 'account_admin',
});

export const UserType = Object.freeze({
    Developer: 'Developer',
    BasicUser: 'Basic',
    Viewer: 'Viewer',
    Maker: 'Maker',
    Approver: 'Approver',
    InstantPayer: 'Instant Payer',
    Reporter: 'Reporter',
    UserAdmin: 'User Admin',
    AccountAdmin: 'Account Admin',
});

export const AllowedUsers = Object.freeze({
    Dashboard: [UserType.Viewer, UserType.Maker, UserType.Approver, UserType.InstantPayer],
    Transaction: [UserType.Viewer, UserType.Maker, UserType.Approver, UserType.InstantPayer],
    SendFunds: [UserType.InstantPayer],
    MakePaymentRequest: [UserType.Maker, [UserType.Maker, UserType.Approver]],
    ApprovePendingPayments: [
        UserType.Viewer,
        UserType.Maker,
        UserType.Approver,
        UserType.InstantPayer,
    ],
    Recipient: [UserType.Viewer, UserType.Maker, UserType.Approver, UserType.InstantPayer],
    Accounts: [UserType.Viewer, UserType.Maker, UserType.Approver, UserType.InstantPayer],
    Reports: [UserType.Reporter],
    Developers: [UserType.Developer],
    Users: [UserType.UserAdmin],
    AddFunds: [UserType.Approver, UserType.InstantPayer],
    SimulateWebhooks: [UserType.Approver, UserType.InstantPayer],
    AccountAdmin: [UserType.AccountAdmin],
});

export const belongsTo = (userTypes, expectedTypes) => {
    return expectedTypes?.some((expectedType) => {
        if (typeof expectedType === 'string') {
            return userTypes?.includes(expectedType);
        }
        if (typeof expectedType === 'object') {
            return expectedType.every((e) => userTypes?.includes(e));
        }
        return false;
    });
};

export const getJwtExpiryTime = () => {
    const state = store.getState();
    if (state) {
        const login = state.auth.login;

        if (login) {
            const { X_TOKEN } = login;
            if (X_TOKEN) {
                const nowToSeconds = Math.floor(Date.now() / 1000);
                const jwtToken = jwt(X_TOKEN);
                const { exp: jwtExpire } = jwtToken;

                if (jwtExpire > nowToSeconds) {
                    return jwtExpire - nowToSeconds;
                }
            }
        }
    }
    return 0;
};

export const isLogged = () => {
    const state = store.getState();
    if (state) {
        const login = state.auth.login;

        if (login) {
            const { X_TOKEN } = login;
            if (X_TOKEN) {
                const nowToSeconds = Math.floor(Date.now() / 1000);
                const jwtToken = jwt(X_TOKEN);
                const { exp: jwtExpire } = jwtToken;

                if (jwtExpire >= nowToSeconds) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const getOtp = () => {
    const state = store.getState();
    if (state) {
        const login = state.auth.login;

        if (login) {
            const { isOtpVerified } = login;
            if (isOtpVerified) {
                return true;
            }
        }
    }
    return false;
};

export const getAccessToken = () => {
    const state = store.getState();
    const login = state.auth.login;
    if (login) {
        const { X_TOKEN } = login;
        return X_TOKEN;
    }
};

export const getCurrentUser = () => {
    const state = store ? store.getState() : initialState;
    const login = state.auth.login;
    if (login) {
        return login;
    }
};

export const getCompany = () => {
    const state = store ? store.getState() : initialState;
    const company = state?.companyData;
    if (company) {
        return company;
    }
};

export const getOtpToken = () => {
    const state = store.getState();
    const login = state.auth.login;
    if (login) {
        const { token } = login;
        return token;
    }
};

export const getReCaptchaToken = () => {
    const state = store.getState();
    const login = state.auth.login;

    if (login) {
        const { x_recaptcha_token } = login;
        return x_recaptcha_token;
    }
};
