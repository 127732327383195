import React, { useState } from 'react';
import { Grid } from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/payments.module.scss';
import selectState from 'store/selectors/queuepayments';
import { Button, Dialog, Icon } from 'components/common';
import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/queuepayments';
import { useSearchParams } from 'react-router-dom';
import { PendingPaymentReview } from 'components/common/Payment';

const Confirm = () => {
    const {
        payment_details: { fromAccount },
        created_payment_request,
        paymentRequestStatus,
        paymentRequestError,
    } = selectState();

    const dispatch = useDispatch();
    const [, setSearchParams] = useSearchParams();
    const [showPaymentDetails, setShowPaymentDetails] = useState(false);

    return (
        <>
            <Grid item xs={12} md={12} lg={12} sm={12} className={cx(styles['confirm-step'])}>
                <Grid container mb={4}>
                    <Grid item md={12} xs={12} sm={12} lg={12}>
                        {paymentRequestStatus && paymentRequestStatus === 'success' && (
                            <>
                                <Grid item md={12} xs={12} sm={12} className={cx(styles.paymentSuccess)}>
                                    <h1> Payment request created successfully!</h1>
                                    <div className={cx(styles.paymentInfo)}>
                                        Payment request ID:&nbsp;
                                        <span className={cx(styles.paymentId)} onClick={() => setShowPaymentDetails(true)}>{created_payment_request?.alias_id ?? ''}</span>
                                    </div>
                                </Grid>
                                <Grid item md={12} xs={12} sm={12}>
                                    <Grid item md={12} xs={12} sm={12}>
                                        <Icon name={'funds-success'} size={'226px'} />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {paymentRequestStatus && paymentRequestStatus === 'error' && (
                            <>
                                <Grid item md={12} xs={12} sm={12}>
                                    <h1 className={cx(styles['confirm-title-error'])}>
                                        {paymentRequestError ?? 'Something went wrong!'}
                                    </h1>
                                </Grid>
                                <Grid item md={12} xs={12} sm={12}>
                                    <Icon name={'funds-error'} size={'175px'} />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            text={'Queue another payment'}
                            style={{ width: '33%' }}
                            className={cx(styles['review-button'], styles['submitButton'])}
                            size="lg"
                            variant="gradient"
                            onClick={() => {
                                setSearchParams({ from: fromAccount.id });
                                dispatch(actions.resetPaymentRequest());
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                title="Payment request details"
                fullWidth
                open={showPaymentDetails}
                setOpen={(val) => {
                    setShowPaymentDetails(false);
                }}
                submitButton={'Close'}
                submitAction={() => setShowPaymentDetails(false)}
                cancelButton={null}
                className={'review-modal'}
                paperProps={{
                    maxWidth: '100%',
                    width: '90%',
                }}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <PendingPaymentReview payment={created_payment_request} />
            </Dialog>
        </>
    );
};

export default Confirm;
