import React, { useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Button, InputField, MultiSelectField } from 'components/common';
import { transactionStatuses, transactionTypes } from './constants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';
import styles from 'assets/styles/transaction.module.scss';
import RangePicker from 'components/common/Picker/RangePicker';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getQueryParams } from 'utils/helpers';
import MultiSelectAutocomplete from 'components/common/SelectInput/MultiSelectAutocomplete';
import selectGlobalState from 'store/selectors/global';
import { ButtonV2 } from 'components/common/Button';

const validationSchema = yup.object({
    payment_type: yup.array(yup.string('Enter transaction type')),
    status: yup.array(yup.string('Enter transaction status')),
    search: yup.string('Search').nullable(),
    search_account: yup.string('Search account').nullable(),
    from_date: yup.date().nullable(),
    to_date: yup.date().nullable(),
});

const TransactionFilter = ({ loading, onFilter, disableCurrencyFilter, disableAccountFilter }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {enabledCurrencies} = selectGlobalState();

    const addQuery = useCallback(
        (values) => {
            const pathname = location.pathname;
            const params = getQueryParams(values);
            navigate({
                pathname: pathname,
                search: params.toLocaleLowerCase(),
            });
        },
        [location, navigate]
    );

    const formik = useFormik({
        initialValues: {
            payment_type: searchParams.getAll('payment_type') ?? [],
            status: searchParams.getAll('status') ?? [],
            search: searchParams.get('search') ?? '',
            search_account: searchParams.get('search_account') ?? '',
            currency: searchParams.getAll('currency')?.map((x) => x.toUpperCase()) ?? [],
            from_date: searchParams.get('from_date'),
            to_date: searchParams.get('to_date'),
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values && !loading) {
                addQuery(values);
            }
        },
        onReset: () => {
            searchParams.delete('payment_type');
            searchParams.delete('status');
            searchParams.delete('from_date');
            searchParams.delete('to_date');
            searchParams.delete('currency');
            searchParams.delete('search');
            searchParams.delete('search_account');
            navigate({
                pathname: location.pathname,
                search: searchParams.toString().toLocaleLowerCase(),
            });
        },
    });

    useEffect(() => {
        addQuery({ ...formik.values });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const data = Object.fromEntries([...searchParams]);
        formik.setValues((prev) => ({
            ...prev,
            ...data,
            payment_type: searchParams.getAll('payment_type') ?? [],
            status: searchParams.getAll('status') ?? [],
            currency: searchParams.getAll('currency')?.map((x) => x.toUpperCase()) ?? [],
        }));
        onFilter(searchParams.toString());
        // eslint-disable-next-line
    }, [searchParams]);

    const handleSelect = useCallback(
        (name, value) => {
            formik.setValues((prev) => ({ ...prev, [name]: value }));
        },
        [formik]
    );

    const handleInputChange = useCallback(
        (event) => {
            formik.setValues((data) => ({
                ...data,
                [event.target.name]: event.target.value?.toLowerCase()?.trim(),
            }));
        },
        [formik]
    );

    const handlePickerChange = useCallback(
        (date) => {
            formik.setValues((prev) => ({
                ...prev,
                from_date: date[0],
                to_date: date[1],
            }));
        },
        [formik]
    );

    return (
        <Grid container className={styles.filter}>
            <div className={styles.filterWrapper}>
                <MultiSelectField
                    name={'payment_type'}
                    defaultText={'Select transaction type'}
                    label={'Transaction type'}
                    options={transactionTypes}
                    onChange={handleSelect}
                    disableItems={['all']}
                    value={formik.values.payment_type}
                    error={formik.touched.payment_type && Boolean(formik.errors.payment_type)}
                    helperText={formik.touched.payment_type && formik.errors.payment_type}
                    selectClassName={cx(styles.searchField)}
                />
            </div>
            <div className={styles.filterWrapper}>
                <MultiSelectField
                    name={'status'}
                    label={'Transaction status'}
                    defaultText={'Select transaction status'}
                    options={transactionStatuses}
                    onChange={handleSelect}
                    disableItems={['all']}
                    value={formik.values.status}
                    error={formik.touched.status && Boolean(formik.errors.status)}
                    helperText={formik.touched.status && formik.errors.status}
                    selectClassName={cx(styles.searchField)}
                />
            </div>
            {!disableCurrencyFilter && (
                <div className={styles.filterCurrency}>
                    <MultiSelectAutocomplete
                        name={'currency'}
                        label={'Currency'}
                        handleChange={handleSelect}
                        value={formik.values.currency}
                        currencies={enabledCurrencies}
                    />
                </div>
            )}
            <div className={styles.filterPicker}>
                <RangePicker
                    values={[
                        formik.values.from_date ? new Date(formik.values.from_date) : null,
                        formik.values.to_date ? new Date(formik.values.to_date) : null,
                    ]}
                    onChange={handlePickerChange}
                    // reset={resetDates}
                />
            </div>
            <div className={styles.filterSearch}>
                <InputField
                    name="search"
                    placeholder="Search by reference"
                    hasIcon
                    iconName="search"
                    iconPosition="start"
                    value={formik.values.search}
                    className={cx(styles.search)}
                    onChange={handleInputChange}
                />
                {!disableAccountFilter && (
                    <InputField
                        name="search_account"
                        placeholder="Search by name or account details"
                        hasIcon
                        iconName="search"
                        iconPosition="start"
                        value={formik.values.search_account}
                        className={cx(styles.search)}
                        onChange={handleInputChange}
                    />
                )}
                <div style={{display: 'flex', gap: '10px'}}>
                    <ButtonV2
                        text="Clear"
                        variant="secondary"
                        size="sm"
                        onClick={formik.handleReset}
                    />
                    <ButtonV2
                        text="Apply"
                        size="sm"
                        onClick={formik.handleSubmit}
                    />
                </div>
                {/* <Button
                    text="Clear"
                    className={cx(styles.searchButton)}
                    variant="outlined"
                    size="sm"
                    onClick={formik.handleReset}
                />
                <Button
                    text="Apply"
                    className={cx(styles.searchButton)}
                    variant="outlined"
                    size="sm"
                    onClick={formik.handleSubmit}
                /> */}
            </div>
        </Grid>
    );
};

export default TransactionFilter;
