import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slice/transactions';
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';
import moment from 'moment';

const {
    accounts: { accounts },
    transactions: { transaction, simulateWebhook },
} = Endpoints;

export function* getTransactions(action) {
    const { page, size, queryParams } = action.payload;
    const searchParameters = new URLSearchParams(queryParams);
    const from_date = searchParameters.get('from_date');
    const to_date = searchParameters.get('to_date');

    if (searchParameters.has('currency')) {
        const currencies = searchParameters.getAll('currency');
        currencies.forEach((currency) => searchParameters.append('currency_mccy', currency));
        searchParameters.delete('currency');
    }
    if (from_date) {
        searchParameters.set('from_date', moment(from_date + 'T00:00:00').format('X'));
    }
    if (to_date) {
        searchParameters.set('to_date', moment(to_date + 'T23:59:59').format('X'));
    }

    try {
        const { data } = yield call(
            request().get,
            transaction.url(page, size, searchParameters.toString(), null)
        );
        const groups = data?.items?.reduce((groups, transaction) => {
            const groupedDate = transaction.created_at;
            const date = moment(groupedDate).format('YYYY-MM-DD');
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(transaction);
            return groups;
        }, {});
        data.items = Object.keys(groups).map((date) => ({
            date,
            transactions: groups[date],
        }));
        if (data) {
            yield put(actions.getTransactionsSuccess(data));
        }
    } catch (err) {
        yield put(actions.getTransactionsError(err.message));
    }
}

export function* getDefaultAccount({ payload }) {
    try {
        const { data } = yield call(request().get, accounts.url());
        if (data) {
            yield put(actions.getAccountSuccess(data.items));
        }
    } catch (err) {
        yield put(actions.getAccountError(err.message));
    }
}

export function* simulateWebhookRequest({ payload }) {
    const { simulate, transactionId } = payload;
    try {
        const { data } = yield call(request().post, simulateWebhook.url(transactionId), {
            simulate,
        });
        if (data) {
            let message = '';
            switch (simulate) {
                case 'fee':
                    message = 'The request to simulate the fee charge is in progress.';
                    break;
                case 'successful':
                case 'failed':
                    message = 'The request to update the transaction status is in progress.';
                    break;
                default:
                    break;
            }
            yield put(actions.simulateWebhookRequestSuccess(message));
        }
    } catch (err) {
        let errMsg = '';
        const {
            data: { message },
            status,
        } = err.response;
        if (status === 400) {
            switch (simulate) {
                case 'fee':
                    if (message === 'The fee can be charged only once per transaction.') {
                        errMsg = 'The fee has already been charged for this transaction.';
                    } else {
                        errMsg =
                            'The request to simulate the fee charge is not supported for this transaction.';
                    }
                    break;
                case 'successful':
                case 'failed':
                    errMsg =
                        'The request to update the status of this transaction is not supported.';
                    break;
                default:
                    break;
            }
        } else {
            errMsg =
                'An error was encountered while simulating webhooks. Please retry after some time. If the issue persists, then contact support at cs@merge.money';
        }

        yield put(actions.simulateWebhookRequestFailed(errMsg));
    }
}

export function* transactionSaga() {
    yield takeLatest(actions.getTransactions.type, getTransactions);
    yield takeLatest(actions.getAccount.type, getDefaultAccount);
    yield takeLatest(actions.simulateWebhook.type, simulateWebhookRequest);
}
