import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'store/slice/developer';
import { useSelector } from 'react-redux';

const selectDeveloper = (state) => state.developer || initialState;

const select = () => createSelector([selectDeveloper], (state) => state);

/* eslint-disable */
export default () => useSelector(select());
