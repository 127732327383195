import React from 'react';
import cx from 'classnames';
import styles from 'assets/styles/common.module.scss';

const Tabs = ({ children, activeTab, onChange, customClassName }) => {
    const tabs = React.Children.toArray(children);

    return (
        <div className={cx(customClassName)}>
            <div role="tablist" aria-label="Tabs" className={styles.tabList}>
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        role="tab"
                        aria-selected={activeTab === index}
                        aria-controls={`tabpanel-${index}`}
                        id={`tab-${index}`}
                        tabIndex={activeTab === index ? 0 : -1}
                        onClick={() => onChange(index)}
                        className={cx(styles.tabButton, activeTab === index && styles.active)}>
                        {tab.props.label}
                    </button>
                ))}
            </div>
            <div role="tabpanel" id={`tabpanel-${activeTab}`} aria-labelledby={`tab-${activeTab}`}>
                {tabs[activeTab]}
            </div>
        </div>
    );
};

const Tab = ({ children }) => {
    return <div>{children}</div>;
};

export { Tabs, Tab };
