import { put, takeLatest, call } from 'redux-saga/effects';
import { actions } from 'store/slice/account';
import { actions as commonActions } from 'store/slice/common';
import Endpoints from 'utils/endpoints';
import { request } from 'utils/api';
import moment from 'moment';
import { clean } from 'utils/helpers';

const {
    accounts: {
        account,
        accounts,
        accountDetails,
        closeAccount,
        filterAccounts,
        summary,
        transactions,
        addFunds,
    },
} = Endpoints;

export function* getAccounts(action) {
    const { currency, page, size, status, search } = action.payload;
    try {
        const { data } = yield call(
            request().get,
            accounts.url({ currency, page, size, status, search })
        );
        if (data) {
            yield put(actions.getAccountsSuccess({ data }));
        }
    } catch (err) {
        yield put(actions.getAccountsError(err.message));
    }
}

export function* getCurrencyAccounts(action) {
    const searchParameters = new URLSearchParams(clean(action.payload));
    try {
        const { data } = yield call(request().get, filterAccounts.url(searchParameters));
        if (data) {
            yield put(actions.getCurrencyAccountsSuccess({ data }));
        }
    } catch (err) {
        yield put(actions.getCurrencyAccountsError(err.message));
    }
}

export function* getSelectedAccount(action) {
    const { accountId } = action.payload;
    try {
        const { data } = yield call(request().get, accountDetails.url(accountId));
        if (data) {
            yield put(actions.getSelectedAccountSuccess({ account: data }));
        }
    } catch (err) {
        yield put(actions.getSelectedAccountError(err.message));
    }
}

export function* getSubAccountTransactions(action) {
    const { account_id, page, size, queryParams } = action.payload;
    const searchParameters = new URLSearchParams(queryParams);
    const from_date = searchParameters.get('from_date');
    const to_date = searchParameters.get('to_date');

    if (from_date) {
        searchParameters.set('from_date', moment(from_date).format('YYYY-MM-DD') + 'T00:00:00');
    }
    if (to_date) {
        searchParameters.set('to_date', moment(to_date).format('YYYY-MM-DD') + 'T23:59:59');
    }

    try {
        const { data } = yield call(
            request().get,
            transactions.url(account_id, page, size, searchParameters.toString())
        );
        const groups = data?.items?.reduce((groups, transaction) => {
            const date = transaction.created_at.split('T')[0];
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(transaction);
            return groups;
        }, {});
        data.items = Object.keys(groups).map((date) => ({
            date,
            transactions: groups[date],
        }));
        if (data) {
            yield put(actions.getSubAccountTransactionsSuccess(data));
        }
    } catch (err) {
        yield put(actions.getSubAccountTransactionsError(err.message));
    }
}

export function* getAccountsSummary() {
    try {
        const { data } = yield call(request().get, summary.url);
        if (data) {
            yield put(actions.getAccountsSummarySuccess({ data }));
        }
    } catch (err) {
        yield put(actions.getAccountsSummaryError(err.message));
    }
}

export function* addAccountsFunds(action) {
    try {
        const { accountId, amount } = action.payload;
        const { data } = yield call(request().post, addFunds.url(accountId), {
            amount,
        });
        if (data) {
            const { message } = data;
            yield put(actions.addAccountsFundsSuccess(message));
        }
    } catch (err) {
        yield put(actions.addAccountsFundsError());
    }
}

export function* createCustomerAccount({ payload }) {
    try {
        const {
            requestPayload,
            otp: { otp, token },
        } = payload;
        const { data } = yield call(request().post, account.url, requestPayload, {
            headers: {
                X_OTP_CODE: otp,
                X_OTP_TOKEN: token,
            },
        });

        if (data) {
            const message = 'Account created successfully';
            yield put(actions.createCustomerAccountSuccess(message));
            yield put(commonActions.resetRequestOtp());
        } else {
            yield put(
                actions.createCustomerAccountError({
                    message: 'Invalid OTP',
                    status: 403,
                })
            );
        }
    } catch (err) {
        yield put(
            actions.createCustomerAccountError({
                message: 'Invalid OTP',
                status: 403,
            })
        );
    }
}

export function* updateAccount({ payload }) {
    try {
        const {
            accountId,
            requestPayload,
            otp: { otp, token },
        } = payload;
        const { data } = yield call(
            request().patch,
            accountDetails.url(accountId),
            requestPayload,
            {
                headers: {
                    X_OTP_CODE: otp,
                    X_OTP_TOKEN: token,
                },
            }
        );

        if (data) {
            const message = 'Account updated successfully';
            yield put(actions.updateAccountSuccess(message));
            yield put(commonActions.resetRequestOtp());
        } else {
            yield put(
                actions.updateAccountError({
                    message: 'Invalid OTP',
                    status: 403,
                })
            );
        }
    } catch (err) {
        yield put(
            actions.updateAccountError({
                message: 'Invalid OTP',
                status: 403,
            })
        );
    }
}

export function* closeCustomerAccount({ payload }) {
    try {
        const {
            accountId,
            otp: { otp, token },
        } = payload;
        const { data } = yield call(
            request().post,
            closeAccount.url(accountId),
            {},
            {
                headers: {
                    X_OTP_CODE: otp,
                    X_OTP_TOKEN: token,
                },
            }
        );

        if (data) {
            const { message = 'Request to close the account is in progress' } = data;

            yield put(actions.closeCustomerAccountSuccess(message));
            yield put(commonActions.resetRequestOtp());
        } else {
            yield put(
                actions.closeCustomerAccountError({
                    message: 'Invalid OTP',
                    status: 403,
                })
            );
        }
    } catch (err) {
        yield put(
            actions.closeCustomerAccountError({
                message: 'Invalid OTP',
                status: 403,
            })
        );
    }
}

export function* accountsSaga() {
    yield takeLatest(actions.getAccounts.type, getAccounts);
    yield takeLatest(actions.getCurrencyAccounts.type, getCurrencyAccounts);
    yield takeLatest(actions.getSelectedAccount.type, getSelectedAccount);
    yield takeLatest(actions.getSubAccountTransactions.type, getSubAccountTransactions);
    yield takeLatest(actions.getAccountsSummary.type, getAccountsSummary);
    yield takeLatest(actions.addAccountsFunds.type, addAccountsFunds);
    yield takeLatest(actions.createCustomerAccount.type, createCustomerAccount);
    yield takeLatest(actions.updateAccount.type, updateAccount);
    yield takeLatest(actions.closeCustomerAccount.type, closeCustomerAccount);
}
