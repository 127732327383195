import React from 'react';
import iconMap from '../../assets/icons/icon-map';
import cx from 'classnames';
import styles from 'assets/styles/global.module.scss';

const Icon = ({ name, size, color, style, disabled, ...rest }) => {
    const IconMap = iconMap[name];
    if (!IconMap) {
        return '';
    }
    return (
        <IconMap
            className={`custom-icon icon ${disabled ? cx(styles['icon-disabled']) : ''}`}
            fill={color}
            color={color}
            style={{
                ...style,
                fill: color,
                color: color,
                width: size,
                height: size,
            }}
            {...rest}
        />
    );
};

export default Icon;
