import FileSaver from 'file-saver';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';
import { Parser } from '@json2csv/plainjs';

const parser = new Parser();

export const getInitials = (name) => {
    const array = name?.match(/\b(\w)/g);
    return ((array?.shift() || '') + '' + (array?.pop() || '')).toUpperCase();
};

const CHARS = new Set('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
export const isAlphanumeric = (char) => {
    return CHARS.has(char);
};

export const validateReference = (val) => {
    const re = /^[A-Za-z\d\-.&/\s]*$/;
    return re.test(val);
};

export const toDateTimeString = (timestamp) => {
    return moment(timestamp).format('DD MMM YYYY HH:mm:ss');
};

export const toDateString = (timestamp) => {
    return moment(timestamp).format('DD MMM YYYY');
};

export const isQuoteEnabled = (account, isFlagEnabled = true) => {
    const accountFeatures = account?.features ?? [];

    return accountFeatures.some(
        ({ name, enabled }) => name === 'po_quote_required' && enabled === isFlagEnabled
    );
};

export const accountFeatureFlag = (account, featureName, isFlagEnabled = true) => {
    const accountFeatures = account?.features ?? [];

    return accountFeatures.some(
        ({ name, enabled }) => name === featureName && enabled === isFlagEnabled
    );
};

const currencyDetails = {
    GBP: {
        countryCode: 'gb',
        currencySymbol: '£',
        description: 'Pound Sterling',
    },
    EUR: {
        countryCode: 'eu',
        currencySymbol: '€',
        description: 'Euro',
    },
    SGD: {
        countryCode: 'sg',
        currencySymbol: '$',
        description: 'Singapore Dollar',
    },
    JPY: {
        countryCode: 'jp',
        currencySymbol: '¥',
        description: 'Japan Yen',
    },
    USD: {
        countryCode: 'us',
        currencySymbol: '$',
        description: 'US Dollar',
    },
    AED: {
        countryCode: 'ae',
        currencySymbol: 'د.إ',
        description: 'UAE Dirham',
    },
    AUD: {
        countryCode: 'au',
        currencySymbol: '$',
        description: 'Australian Dollar',
    },
    BBD: {
        countryCode: 'bb',
        currencySymbol: '$',
        description: 'Barbados Dollar',
    },
    BDT: {
        countryCode: 'bd',
        currencySymbol: '৳',
        description: 'Bangladeshi Taka',
    },
    BGN: {
        countryCode: 'bg',
        currencySymbol: 'лв',
        description: 'Bulgarian Lev',
    },
    BHD: {
        countryCode: 'bh',
        currencySymbol: 'د.ب',
        description: 'Bahraini Dinar',
    },
    BWP: {
        countryCode: 'bw',
        currencySymbol: 'P',
        description: 'Botswana Pula',
    },
    CAD: {
        countryCode: 'ca',
        currencySymbol: '$',
        description: 'Canadian Dollar',
    },
    CHF: {
        countryCode: 'ch',
        currencySymbol: '₣',
        description: 'Swiss Franc',
    },
    CNY: {
        countryCode: 'cn',
        currencySymbol: '¥',
        description: 'Yuan Renminbi',
    },
    CZK: {
        countryCode: 'cz',
        currencySymbol: 'Kč',
        description: 'Czech Koruna',
    },
    DKK: {
        countryCode: 'dk',
        currencySymbol: 'kr.',
        description: 'Danish Krone',
    },
    ETB: {
        countryCode: 'et',
        currencySymbol: 'Br.',
        description: 'Ethiopian Birr',
    },
    GHS: {
        countryCode: 'gh',
        currencySymbol: '₵',
        description: 'Ghana Cedi',
    },
    GMD: {
        countryCode: 'gm',
        currencySymbol: 'GMD',
        description: 'Gambian Dalasi',
    },
    HKD: {
        countryCode: 'hk',
        currencySymbol: '$',
        description: 'Hong Kong Dollar',
    },
    HUF: {
        countryCode: 'hu',
        currencySymbol: 'Ft',
        description: 'Hungarian Forint',
    },
    ILS: {
        countryCode: 'il',
        currencySymbol: '₪',
        description: 'New Israeli Sheqel',
    },
    INR: {
        countryCode: 'in',
        currencySymbol: '₹',
        description: 'Indian Rupee',
    },
    JOD: {
        countryCode: 'jo',
        currencySymbol: 'د.ا',
        description: 'Jordanian Dinar',
    },
    KES: {
        countryCode: 'ke',
        currencySymbol: 'KSh',
        description: 'Kenyan Shilling',
    },
    KRW: {
        countryCode: 'kr',
        currencySymbol: '₩',
        description: 'South Korean Won',
    },
    KWD: {
        countryCode: 'kw',
        currencySymbol: 'د.ك',
        description: 'Kuwaiti Dinar',
    },
    LKR: {
        countryCode: 'lk',
        currencySymbol: 'රු',
        description: 'Sri Lanka Rupee',
    },
    MAD: {
        countryCode: 'ma',
        currencySymbol: 'د.م.',
        description: 'Moroccan Dirham',
    },
    MGA: {
        countryCode: 'mg',
        currencySymbol: 'Ar',
        description: 'Malagasy Ariary',
    },
    MUR: {
        countryCode: 'mu',
        currencySymbol: 'Rs',
        description: 'Mauritius Rupee',
    },
    MXN: {
        countryCode: 'mx',
        currencySymbol: '$',
        description: 'Mexican Peso',
    },
    MZN: {
        countryCode: 'mz',
        currencySymbol: 'MT',
        description: 'Mozambique Metical',
    },
    NGN: {
        countryCode: 'ng',
        currencySymbol: '₦',
        description: 'Nigerian Naira',
    },
    NOK: {
        countryCode: 'no',
        currencySymbol: 'kr',
        description: 'Norwegian Krone',
    },
    NPR: {
        countryCode: 'np',
        currencySymbol: 'Rs',
        description: 'Nepalese Rupee',
    },
    NZD: {
        countryCode: 'nz',
        currencySymbol: '$',
        description: 'New Zealand Dollar',
    },
    OMR: {
        countryCode: 'om',
        currencySymbol: '﷼',
        description: 'Rial Omani',
    },
    PGK: {
        countryCode: 'pg',
        currencySymbol: 'K',
        description: 'Papua New Guinea Kina',
    },
    PHP: {
        countryCode: 'ph',
        currencySymbol: '₱',
        description: 'Philippine Peso',
    },
    PKR: {
        countryCode: 'pk',
        currencySymbol: 'Rs',
        description: 'Pakistan Rupee',
    },
    PLN: {
        countryCode: 'pl',
        currencySymbol: 'zł',
        description: 'Polish Zloty',
    },
    QAR: {
        countryCode: 'qa',
        currencySymbol: 'QR',
        description: 'Qatari Rial',
    },
    RON: {
        countryCode: 'ro',
        currencySymbol: 'lei',
        description: 'Romanian Leu',
    },
    RWF: {
        countryCode: 'rw',
        currencySymbol: '₣',
        description: 'Rwanda Franc',
    },
    SAR: {
        countryCode: 'sa',
        currencySymbol: '﷼',
        description: 'Saudi Riyal',
    },
    SEK: {
        countryCode: 'se',
        currencySymbol: 'kr',
        description: 'Swedish Krona',
    },
    THB: {
        countryCode: 'th',
        currencySymbol: '฿',
        description: 'Thai Baht',
    },
    TND: {
        countryCode: 'tn',
        currencySymbol: 'د.ت',
        description: 'Tunisian Dinar',
    },
    TRY: {
        countryCode: 'tr',
        currencySymbol: '₺',
        description: 'Turkish Lira',
    },
    TTD: {
        countryCode: 'tt',
        currencySymbol: '$',
        description: 'Trinidad and Tobago Dollar',
    },
    TZS: {
        countryCode: 'tz',
        currencySymbol: 'TSh',
        description: 'Tanzanian Shilling',
    },
    UGX: {
        countryCode: 'ug',
        currencySymbol: 'USh',
        description: 'Uganda Shilling',
    },
    XAF: {
        countryCode: 'cf',
        currencySymbol: '₣',
        description: 'CFA Franc BEAC',
    },
    XCD: {
        countryCode: 'ai',
        currencySymbol: '$',
        description: 'East Caribbean Dollar',
    },
    XOF: {
        countryCode: 'bf',
        currencySymbol: 'CFA',
        description: 'CFA Franc BCEAO',
    },
    ZAR: {
        countryCode: 'za',
        currencySymbol: 'R',
        description: 'South African Rand',
    },
    NAD: {
        countryCode: 'na',
        currencySymbol: '$',
        description: 'Namibia Dollar',
    },
    ZMW: {
        countryCode: 'zm',
        currencySymbol: 'ZK',
        description: 'Zambian Kwacha',
    },
};

export const currencyList = Object.keys(currencyDetails)
    .map((currency) => {
        const { countryCode, currencySymbol, description } = currencyDetails[currency];

        return {
            currency,
            currencyName: `${currency} - ${description} (${currencySymbol})`,
            countryCode,
            currencySymbol,
        };
    })
    .sort((a, b) => {
        if (a.currencyName.toLowerCase() < b.currencyName.toLowerCase()) {
            return -1;
        }
        if (a.currencyName.toLowerCase() > b.currencyName.toLowerCase()) {
            return 1;
        }
        return 0;
    });

export const getCountryCode = (currency) => {
    return currencyDetails[currency]?.countryCode ?? '';
};

export const getCurrencySymbol = (currency) => {
    return currencyDetails[currency]?.currencySymbol ?? '';
};

export const getCurrencyDescription = (currency) => {
    return currencyDetails[currency]?.description ?? '';
};

export const toCapitalize = (string = '') => {
    return string?.toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match?.toUpperCase());
};

export const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

export const clean = (obj) => {
    for (let propName in obj) {
        if (
            obj[propName] === null ||
            obj[propName] === undefined ||
            obj[propName] === '' ||
            obj[propName]?.length === 0
        ) {
            delete obj[propName];
        }
    }
    return obj;
};

export const getQueryParams = (values) => {
    const searchParams = new URLSearchParams();
    for (let key in values) {
        if (values[key] !== null && values[key] !== '') {
            if (values[key] instanceof Date) {
                values[key] = moment(values[key]).format('YYYY-MM-DD');
            }
            if (values[key] instanceof Array) {
                values[key].forEach((x) => {
                    searchParams.append(key, x);
                });
            } else if (values[key] === 'all') {
                searchParams.delete(key);
            } else {
                searchParams.set(key, values[key]);
            }
        }
    }
    return searchParams.toString().toLocaleLowerCase();
};

export function exportToXlsx(data, fileName = 'report.xlsx', title = 'Worksheet') {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, title);
    writeFile(workbook, fileName, { compression: true });
}

export function exportToCsv(data, fileName = 'report.csv', title = 'Worksheet') {
    const csv = parser.parse(data);
    let blob = new Blob(['', csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, fileName);
}

export const formatAmount = (amount, decimals = 2) => {
    if (!amount) {
        return '';
    }
    return parseFloat(amount).toFixed(decimals);
};
