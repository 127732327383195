import { all } from 'redux-saga/effects';
import { postLogin, postLoginOtp } from './auth';
import { getUserData } from './user';
import { companySaga } from './company';
import { recipientsSaga } from './recipients';
import { getFeatureFlagsSaga } from 'AppSagas';
import { commonSaga } from './common';
import { globalSaga } from './global';
import { dashboardSaga } from './dashboard';
import { sendFundsSaga } from './funds';
import { transactionSaga } from './transactions';
import { accountsSaga } from './account';
import { queuepaymentsSaga } from './queuepayments';
import { paymentsSaga } from './pendingpayments';
import { profileSaga } from './profile';
import { reportsSaga } from './reports';
import { developerSaga } from './developer';
import { usersSaga } from './userManagement';

export default function* rootSaga() {
    yield all([
        postLogin(),
        postLoginOtp(),
        getUserData(),
        companySaga(),
        commonSaga(),
        globalSaga(),
        dashboardSaga(),
        recipientsSaga(),
        getFeatureFlagsSaga(),
        sendFundsSaga(),
        transactionSaga(),
        accountsSaga(),
        queuepaymentsSaga(),
        paymentsSaga(),
        profileSaga(),
        reportsSaga(),
        developerSaga(),
        usersSaga(),
    ]);
}
