import React, { useEffect, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import selectState from './AppSelectors';
import { useSelector } from 'react-redux';

import NoFoundComponent from 'components/NoFoundComponent';

import Login from './components/auth/Login';
import Dashboard from './components/dashboard/Dashboard';
import Recipients from './components/dashboard/recipients/Recipients';

import Transactions from './components/dashboard/transactions/Transactions';
import ApiKey from 'components/dashboard/developer/ApiKey';
import Profile from './components/dashboard/profile/Profile';
import Users from './components/dashboard/users/Users';
import Funds from './components/dashboard/funds';
import QueuePaymentRequest from 'components/dashboard/queuepayments';
import PendingPayments from 'components/dashboard/pendingpayments/PendingPayments';
import Report from 'components/dashboard/reports/Reports';
import WithPermission from 'components/common/Permission/WithPermission';

import ProtectedRoutes from './routes/ProtectedRoutes';
import PublicRoutes from './routes/PublicRoute';
import AccountTransactions from './components/dashboard/account/AccountTransactions';
import Accounts from './components/dashboard/account/Accounts';
import TermsAndConditions from './components/conditions/TermsAndConditions';
import TermsOfUse from './components/conditions/TermsOfUse';
import PrivacyPolicyPage from './components/conditions/PrivacyPolicyPage';
import ContentLayout from './components/layouts/ContentLayout';
import TermsAndConditionsModulrUK from './components/conditions/TermsAndConditionsModulrUK';
import { clearState } from './store/slice';
import { isLogged, AllowedUsers } from './utils/auth';
import { useDispatch } from 'react-redux';
import AccountsSummary from 'components/dashboard/account/Summary';
import { AccountStatement } from 'components/dashboard/reports';

const DashboardLayout = lazy(() => import('./components/layouts/DashboardLayout'));
const EmptyLayout = lazy(() => import('./components/layouts/EmptyLayout'));

const App = () => {
    const dispatch = useDispatch();
    const { featureFlags } = useSelector(selectState);

    const privateRoutes = [
        {
            id: 4,
            path: 'home',
            component: (
                <WithPermission containsTypes={AllowedUsers.Dashboard}>
                    <Dashboard />
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 6,
            path: 'transactions',
            component: (
                <WithPermission containsTypes={AllowedUsers.Transaction}>
                    <Transactions />
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 7,
            path: 'recipients',
            component: (
                <WithPermission containsTypes={AllowedUsers.Recipient}>
                    <Recipients />
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 9,
            path: 'funds',
            component: (
                <WithPermission containsTypes={AllowedUsers.SendFunds}>
                    <Funds />
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 31,
            path: 'payment-request',
            component: (
                <WithPermission containsTypes={AllowedUsers.MakePaymentRequest}>
                    <QueuePaymentRequest />
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 32,
            path: 'pending-payments',
            component: (
                <WithPermission containsTypes={AllowedUsers.ApprovePendingPayments}>
                    {' '}
                    <PendingPayments />{' '}
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 33,
            path: 'developer/api-key',
            component: (
                <WithPermission containsTypes={AllowedUsers.Developers}>
                    <ApiKey />
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 11,
            path: 'profile',
            component: <Profile />,
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 15,
            path: 'users',
            component: (
                <WithPermission containsTypes={AllowedUsers.Users}>
                    <Users />
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 13,
            path: `accounts/:accountId/transactions`,
            component: (
                <WithPermission containsTypes={AllowedUsers.Accounts}>
                    <AccountTransactions />
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 35,
            path: `accounts/:currency`,
            component: (
                <WithPermission containsTypes={AllowedUsers.Accounts}>
                    <Accounts />
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 14,
            path: 'accounts',
            component: (
                <WithPermission containsTypes={AllowedUsers.Accounts}>
                    <AccountsSummary />
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 34,
            path: 'transaction-report',
            component: (
                <WithPermission containsTypes={AllowedUsers.Reports}>
                    <Report />
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 37,
            path: 'account-statement',
            component: (
                <WithPermission containsTypes={AllowedUsers.Reports}>
                    <AccountStatement />
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
        {
            id: 38,
            path: 'report',
            component: (
                <WithPermission containsTypes={AllowedUsers.Reports}>
                    <Report />
                </WithPermission>
            ),
            layoutComponentName: 'DashboardLayout',
        },
    ];

    useEffect(() => {
        localStorage.setItem('envName', process.env.REACT_APP_ENVIRONMENT);
    }, []);

    useEffect(() => {
        if (!isLogged()) {
            dispatch(clearState());
        }
    }, [dispatch]);

    const publicRoutes = [
        {
            id: 2,
            path: 'login',
            component: (
                <EmptyLayout>
                    {' '}
                    <Login />
                </EmptyLayout>
            ),
            layoutComponentName: 'EmptyLayout',
        },
        {
            id: 29,
            path: 'terms-of-use',
            component: (
                <ContentLayout>
                    <TermsOfUse />
                </ContentLayout>
            ),
            layoutComponentName: 'ContentLayout',
        },
        {
            id: 30,
            path: 'privacy-policy',
            component: (
                <ContentLayout>
                    <PrivacyPolicyPage />
                </ContentLayout>
            ),
            layoutComponentName: 'ContentLayout',
        },
    ];

    const userRoutes = [
        {
            id: 27,
            path: 'terms-and-conditions',
            component: (
                <ContentLayout>
                    <TermsAndConditions />
                </ContentLayout>
            ),
            layoutComponentName: 'ContentLayout',
        },
        {
            id: 28,
            path: 'terms-and-conditions-modulr-uk',
            component: (
                <ContentLayout>
                    <TermsAndConditionsModulrUK />
                </ContentLayout>
            ),
            layoutComponentName: 'ContentLayout',
        },
    ];

    return (
        <Routes>
            <Route
                path="/dashboard"
                element={
                    <DashboardLayout featureFlags={featureFlags}>
                        <ProtectedRoutes />
                    </DashboardLayout>
                }>
                {privateRoutes.map(({ path, id, component, layoutComponentName }) => {
                    return (
                        <Route
                            exact
                            path={path}
                            key={id}
                            element={component}
                            layoutComponentName={layoutComponentName}
                        />
                    );
                })}
            </Route>
            <Route path="/" element={<ProtectedRoutes />}>
                {userRoutes.map(({ path, id, component, layoutComponentName }) => {
                    return (
                        <Route
                            exact
                            path={path}
                            key={id}
                            element={component}
                            layoutComponentName={layoutComponentName}
                        />
                    );
                })}
            </Route>
            <Route path="/" element={<PublicRoutes />}>
                {publicRoutes.map(({ path, id, component, layoutComponentName }) => {
                    return (
                        <Route
                            exact
                            path={path}
                            key={id}
                            element={component}
                            layoutComponentName={layoutComponentName}
                        />
                    );
                })}
            </Route>

            <Route path="*" element={<NoFoundComponent />} />
        </Routes>
    );
};
export default App;
