import { createSlice } from '@reduxjs/toolkit';

/*
 *  @desc The accounts slice manages the state of the common components
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    selectAccount: {
        loading: false,
        error: null,
        accounts: null,
    },
    selectRecipient: {
        loading: false,
        error: null,
        accounts: null,
    },
    quote: {
        loading: false,
        error: null,
        quoteInfo: null,
    },
    requestOtp: {
        otpError: {
            status: null,
            message: '',
        },
        otpLoading: false,
        otpLoaded: false,
        otpCount: 0,
        otpToken: null,
    },
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        getAccountsForSelect(state) {
            state.selectAccount = {
                loading: true,
                error: null,
                accounts: [],
            };
        },
        getAccountsForSelectSuccess(state, action) {
            state.selectAccount = {
                loading: false,
                error: null,
                accounts: action.payload,
            };
        },
        getAccountsforSelectError(state, action) {
            state.selectAccount = {
                loading: false,
                error: action.payload,
                accounts: null,
            };
        },
        clearAccountsForSelect(state) {
            state.selectAccount = {
                loading: true,
                error: null,
                accounts: [],
            };
        },

        getRecipientsForSelect(state) {
            state.selectRecipient = {
                loading: true,
                error: null,
                recipients: [],
            };
        },
        getRecipientsForSelectSuccess(state, action) {
            state.selectRecipient = {
                loading: false,
                error: null,
                recipients: action.payload,
            };
        },
        getRecipientsforSelectError(state, action) {
            state.selectRecipient = {
                loading: false,
                error: action.payload,
                accounts: null,
            };
        },

        invalidateQuote: (state) => {
            state.quote = initialState.quote;
        },

        createQuote: (state) => {
            state.quote = {
                quoteInfo: null,
                loading: true,
            };
        },
        createQuoteSuccess: (state, action) => {
            state.quote = {
                quoteInfo: action.payload,
                loading: false,
            };
        },
        createQuoteError: (state, action) => {
            state.quote = {
                quoteInfo: null,
                loading: false,
                error: action.payload?.message ?? 'Unable to create quote',
            };
        },
        generateRequestOtp(state) {
            state.requestOtp = { ...initialState.requestOtp, otpLoading: true };
        },
        generateRequestOtpSuccess(state, { payload }) {
            state.requestOtp.otpToken = payload;
            state.requestOtp.otpLoading = false;
            state.requestOtp.otpLoaded = true;
        },
        generateRequestOtpError(state, { payload }) {
            state.requestOtp.otpError = payload;
            state.requestOtp.otpLoading = false;
            state.requestOtp.otpLoaded = true;
            state.requestOtp.otpCount += 1;
        },
        resetRequestOtp(state) {
            state.requestOtp = { ...initialState.requestOtp };
        },
    },
});

export const { actions, reducer, name } = commonSlice;
