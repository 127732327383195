import React from 'react';
import Box from '@mui/material/Box';
// import DownloadFile from 'svg/Download'
// import Container from 'components/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TermsPdf from 'pdfs/terms-and-conditions/Introduced_Client_ Full_Agreement.pdf';
import { useNavigate } from 'react-router-dom';
import { TERMS_AND_CONDITIONS_SECTIONS } from './Types';
import selectState from './selectors';
import { actions } from './slice';

import {
    StyledGrid,
    StyledHeaderPage,
    StyledSubHeading,
    StyledBackButton,
    StyledHighlightedPanel,
    StyledParagrapgh,
} from './styles';
import { useDispatch } from 'react-redux';
// import ContentLayout from "../layouts/ContentLayout";
import { Container, Icon } from '../common';

const TermsAndConditions = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    });

    const { currentSection } = selectState();

    return (
        <Box id={'top'}>
            <Container
                sx={{
                    margin: 'unset',
                    paddingLeft: ['16px', '16px', '45px'],
                    paddingTop: ['0', '70px', '80px'],
                }}>
                <Grid container alignItems={'center'}>
                    <Grid item md={7}>
                        <StyledHeaderPage>Merge Terms & Conditions</StyledHeaderPage>
                    </Grid>
                    {isMd && (
                        <Grid
                            item
                            md={5}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                            {isLg && (
                                <div style={{ marginRight: '20px' }}>
                                    <p
                                        style={{
                                            color: '#00A09B',
                                            marginTop: '10px',
                                        }}>
                                        <a
                                            href={TermsPdf}
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{
                                                color: '#00A09B',
                                                textDecoration: 'none',
                                            }}>
                                            Download PDF{' '}
                                            <span
                                                style={{
                                                    margin: '0px 10px',
                                                    verticalAlign: 'text-top',
                                                }}>
                                                <Icon name={'file-download'} />
                                            </span>
                                        </a>
                                    </p>
                                </div>
                            )}
                            <div>
                                <StyledBackButton onClick={() => navigate(-1)}>
                                    Go back
                                </StyledBackButton>
                            </div>
                        </Grid>
                    )}
                </Grid>
                <Grid container>
                    <Grid item md={12} sx={{ margin: ['0 auto'] }}>
                        <Box sx={{ textAlign: 'center' }}>
                            <StyledSubHeading>
                                Merge Money Ltd. Terms and Conditions
                            </StyledSubHeading>
                        </Box>
                    </Grid>
                </Grid>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.TERMS_AND_CONDITIONS}
                    isSelected={
                        currentSection &&
                        currentSection.includes(TERMS_AND_CONDITIONS_SECTIONS.TERMS_AND_CONDITIONS)
                    }>
                    <Grid item>
                        <StyledParagrapgh>
                            <strong>Merge Business Terms and Conditions (version 1.0)</strong>
                        </StyledParagrapgh>
                        <StyledParagrapgh>
                            This agreement comprises of these terms and conditions and the cover
                            letter (collectively “Agreement”) is entered into by Merge Money Ltd.
                            with registered office at 7 Bell Yard, London WC2A 2JR, United Kingdom,
                            registration number 13463502 (hereinafter "we", “our”, "us" or "Merge")
                            on the one hand and the company identified in the cover letter on the
                            other hand (hereinafter referred to as “you”, “your”). In order for you
                            to obtain or continue utilising the Services, you agree to and accept
                            all terms and conditions of this Agreement.
                        </StyledParagrapgh>

                        <StyledParagrapgh>
                            Please read carefully the following terms and conditions (the “Terms and
                            Conditions”). By registering to the Services, through our website or
                            otherwise, you agree to be bound by the Terms and Conditions. If you do
                            not agree to the Terms and Conditions, you may not use, access or
                            register to the Services. Upon your request sent to{' '}
                            <a href={'mailto:support@mergedup.com'}>support@mergedup.com</a>, we
                            will send you a copy of the Terms and Conditions via email, on paper, or
                            on another durable medium. We communicate with you in the English
                            language.
                        </StyledParagrapgh>

                        <StyledParagrapgh>
                            These Terms and Conditions are effective upon you, acting in the
                            capacity of a signatory with authorisation to bind the company you are
                            representing, and you hereby accept the agreement during the onboarding
                            process, subject to the conditions as set forth in these Terms and
                            Conditions. In the event you are not duly or solely authorised to bind
                            the company as per company house registers, you will ensure a valid
                            power of attorney is signed by a (second) authorised representative to
                            permit you to enter into this agreement by yourself and submit such POA
                            with any KYC documents required. If for any reason, in our sole
                            discretion, we decide that we are unable to complete your onboarding
                            process, we may refuse to provide the Services to you and not issue your
                            electronic wallet. In these circumstances, this agreement between us
                            will terminate with immediate effect upon us communicating to you such a
                            decision.
                        </StyledParagrapgh>
                        <StyledParagrapgh>
                            You acknowledge that We have relied upon Your representation that this
                            Agreement is entered into for commercial or business purposes and not
                            for personal, family or household purposes.
                        </StyledParagrapgh>
                    </Grid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.MERGE_DISTRIBUTOR_AND_CONTACT_INFORMATION}
                    isSelected={
                        currentSection &&
                        currentSection.includes(
                            TERMS_AND_CONDITIONS_SECTIONS.MERGE_DISTRIBUTOR_AND_CONTACT_INFORMATION
                        )
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>1. Merge: Distributor and Contact Information</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                1.1 Merge is a limited liability company incorporated in the United
                                Kingdom and appointed as a distributor of electronic money on behalf
                                of our Partner(s). We may also be referred to by our Partner(s) as
                                Partner Platform.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                1.2 Merge offers its customers electronic wallets (e-wallets) to
                                hold funds and execute payment transactions and transfer funds to
                                third parties for payments due, which can be managed through Merge’s
                                portal and/or its API (hereinafter the “Services”). The Customer is
                                engaged in certain activities as described during the onboarding
                                process and approved by Merge for which it or its clients need to
                                receive the Services.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                1.3 All communications to us should be addressed to{' '}
                                <a href={'mailto:support@mergedup.com'}>support@mergedup.com</a> or
                                at 7 Bell Yard, London WC2A 2JR. To contact our customer support
                                team for any inquiries or as indicated in these Terms and
                                Conditions, please call 02034420305 or email{' '}
                                <a href={'mailto:support@mergedup.com'}>support@mergedup.com</a>.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.INTRODUCTION}
                    isSelected={
                        currentSection &&
                        currentSection.includes(TERMS_AND_CONDITIONS_SECTIONS.INTRODUCTION)
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>2. Introduction</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                2.1 These Terms and Conditions govern the opening, use and closure
                                of your electronic wallet, including the issuance and redemption of
                                electronic money and the execution of payments by us in relation to
                                your e-wallet. Your e-wallet can be managed online at{' '}
                                <a href={'https://www.mergedup.com'}>www.mergedup.com</a>.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                2.2 The Services are provided only to Users. You may not use the
                                Services if you are not eligible to be a User. We reserve the right
                                to not accept your application for our services with or without
                                providing you the reason(s) for declining your application. Your
                                e-wallet may not be activated unless we have been provided with the
                                required information.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                2.3 In order to become a User, you must register with us and provide
                                us all information requested by us, necessary for us to identify and
                                verify you as a customer and to review your application and risk
                                profile as per our acceptance policy. All information you provide
                                during the signup process must be accurate and truthful and up to
                                date. Whilst we will ask you to provide KYC information during the
                                onboarding process, we may also request further KYC information at
                                any time during your use of the Services to ensure continued
                                compliance with relevant legislation. Please note that you will need
                                to inform us without undue delay of any relevant change affecting
                                you and any information submitted to us. You understand that your
                                use of the services is strictly dependent upon the KYC information.
                                We shall not be held liable for any loss caused by your failure to
                                do so.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                2.4 You confirm that you fully understand, accept and agree to be
                                bound by these Terms and Conditions, including the cover letter and
                                the fees schedule.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                2.5 No person other than you shall have any rights under these Terms
                                and Conditions. Your e-wallet is personal to you and you may not
                                assign or otherwise transfer any rights under these Terms and
                                Conditions to any third party, without our prior written consent.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                2.6 You represent and warrant that you are acting on your (your
                                company’s) own behalf and not on behalf of any other person or
                                party.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                2.7 You must not use your e-wallet for an unlawful or illegal
                                purpose as further explained in section 9.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.DEFINITIONS}
                    isSelected={
                        currentSection &&
                        currentSection.includes(TERMS_AND_CONDITIONS_SECTIONS.DEFINITIONS)
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>3. Definitions</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                3.1 “Prohibited Purpose” means any purpose for using the Services
                                that is not allowed under applicable laws (including the laws that
                                govern the Services, where the User operates its business or the
                                laws that govern the destination of the Payment) and/or this
                                Agreement, including - but not limited to – making Payments: (i)
                                relating to funds that were obtained through illegal activity; (ii)
                                for the purpose of funding illegal activity; (iii) to avoid any
                                legal duty to report funds; (iv) that violate orders of any court or
                                authorities; and/or (iv) is relating to activity not permitted by us
                                pursuant to our acceptance policy and prohibited activity policy.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                3.2 “Recipient” means a person or legal entity that receives funds
                                from a User through the Services, following legitimate payments
                                being due by you to such person.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                3.3 “User” means a legal entity using the Services for commercial or
                                business purposes, which is not a Prohibited Purpose under these
                                Terms and Conditions.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                3.4 “Services” means the issuance of electronic wallets (e-wallets)
                                to hold funds (electronic money) in different currencies and the
                                execution of payment transactions and transfer funds to Recipients.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.YOUR_E_WALLET_AND_THE_SERVICES}
                    isSelected={
                        currentSection &&
                        currentSection.includes(
                            TERMS_AND_CONDITIONS_SECTIONS.YOUR_E_WALLET_AND_THE_SERVICES
                        )
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>4. Your e-wallet and the services</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                4.1 The provision of the services will be setup and rendered as
                                further described in the cover letter. Your e-wallet is an
                                electronic money account which enables you to send and receive
                                electronic payments. We may cooperate with and use the services of
                                one or more third party financial service providers in respect of
                                our operation of the e-wallet and transactions, including banks,
                                financial institutions, money services providers and others. For
                                more information see{' '}
                                <a
                                    target={'_blank'}
                                    rel="noreferrer"
                                    href={`#${TERMS_AND_CONDITIONS_SECTIONS.PARTNERS}`}
                                    onClick={() =>
                                        dispatch(
                                            actions.sectionSet(
                                                `#${TERMS_AND_CONDITIONS_SECTIONS.PARTNERS}`
                                            )
                                        )
                                    }>
                                    clause 10
                                </a>
                                .
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                4.2 Your e-wallet is denominated in a currency or more currencies of
                                your choice, as selected by you from the currencies we make
                                available from time to time. This will remain the currency of your
                                e-wallet for the duration of your agreement with us.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                4.3 You may use the Services only for business and commercial
                                purposes. You represent and warrant that - to the extent that you
                                are a User and hold an e-wallet - you are acting as a principal and
                                have legal title to all funds used in connection with your e-wallet
                                and the Services.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                4.4 We are allowed to engage third parties in connection with our
                                Services.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                4.5 Your ability to use or access the Services may occasionally be
                                interrupted, for example if we need to carry out maintenance on our
                                systems. If you are experiencing any problems using your e-wallet,
                                please contact our customer support team and we will endeavour to
                                resolve any problem.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                4.6 The availability, functioning, quality and functionality of the
                                Services depend on various factors, including software, hardware and
                                communication networks, which may be also provided by third parties,
                                at their responsibility. These factors are not error-free or
                                undisrupted. We are providing the Services to you on an “as is”
                                basis, without any warranty of any kind. We reserve the right to
                                modify or discontinue all or any part of the Services, our website
                                and/or our portal at any time, either temporarily or permanently.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                4.7 The Services contemplated under this Agreement are provided on
                                an execution only basis.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.MAINTAINING_YOUR_WALLET}
                    isSelected={
                        currentSection &&
                        currentSection.includes(
                            TERMS_AND_CONDITIONS_SECTIONS.MAINTAINING_YOUR_WALLET
                        )
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>5. Maintaining your wallet</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                5.1 You must ensure that the information recorded on your e-wallet
                                is always accurate and up to date and we shall not be liable for any
                                loss arising out of your failure to do so. You undertake to maintain
                                and promptly update all information provided to us and to keep it
                                true, accurate, current, correct and complete at all times. We may
                                ask you at any time to confirm the accuracy of your information or
                                to provide documents or other evidence. Any amendments to your
                                contact details should be communicated to us immediately, by sending
                                the relevant documentation to our customer support team.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                5.2 You undertake to provide us with any additional information and
                                documents We or our Partners may require in connection with the
                                Services (including in order to confirm the identity of relevant
                                persons, the identity of and relationship with Recipients, purpose
                                of the account or to verify any information or request made by you).
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                5.3 You will be able to view the history of all your transactions on
                                your wallet in our portal and/or via API and such history will
                                display any and all funds uploaded, payments received, payments sent
                                and fund withdrawals together with the date of receipt or
                                transmission (the debit value date), the fees charged and, where
                                applicable, any exchange rate used.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.PROTECTING_YOUR_E_WALLET}
                    isSelected={
                        currentSection &&
                        currentSection.includes(
                            TERMS_AND_CONDITIONS_SECTIONS.PROTECTING_YOUR_E_WALLET
                        )
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>6. Protecting your e-wallet</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                6.1 You are responsible for the safekeeping of and must take all
                                reasonable steps to keep your username and password confidential and
                                safe at all times and never disclose it to any third party or
                                unauthorised employee. You undertake to check your e-wallet at least
                                once a month to detect any irregular logins or activity.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                6.2 If you believe or suspect your e-wallet, login details, password
                                or other security feature is lost, stolen or used without
                                authorisation or is compromised, You should change your password.
                                You must contact our customer support team without undue delay on
                                becoming aware of any loss, theft or unauthorised use or compromise.
                                Any undue delay in notifying us may not only affect the security of
                                your e-wallet, but may also result in you being liable for any
                                losses as a result where your failure to notify us is intentional or
                                grossly negligent.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                6.3 We may suspend your e-wallet or otherwise restrict its
                                functionality on reasonable grounds relating to the security of the
                                e-wallet or any of its security features or if we reasonably suspect
                                that an unauthorised or fraudulent use of your e-wallet has occurred
                                or that any of its security features have been compromised. We will
                                notify you of any suspension or restriction and of the reasons for
                                such suspension or restriction in advance or, where we are unable to
                                do so, immediately after, unless notifying you would be unlawful or
                                compromise our reasonable security interests. We will lift the
                                suspension and/or the restriction as soon as practicable after the
                                reasons for the suspension and/or restriction have ceased to exist.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                6.4 If you give any of your employees access to your e-wallet, we
                                may assume that such employees are authorised to represent you
                                towards us, to initiate payments through the wallet and that they
                                are authorised to use the Services on your behalf. We do not have to
                                verify the above. You hereby agree that the security requirements
                                apply to each employee. Any information provided to an employee or
                                through the user account is deemed to be provided to and received by
                                you as the User.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.CLOSING_YOUR_WALLET}
                    isSelected={
                        currentSection &&
                        currentSection.includes(TERMS_AND_CONDITIONS_SECTIONS.CLOSING_YOUR_WALLET)
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>7. Closing your wallet</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                7.1 In the event of expiration or termination of this Agreement,
                                your e-wallet will be closed. You shall immediately cease carrying
                                out any payment requests, except in relation to withdrawal if
                                applicable.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                7.2 We reserve the right to carry out any necessary money
                                laundering, terrorism financing, fraud or other illegal activity
                                checks before authorising any withdrawal of your funds, including in
                                relation to returning any funds to you after you have closed your
                                e-wallet. Regardless of whether the termination is initiated by you
                                or us, we shall not be obliged to release or return any balance in
                                the e-wallet if there is any reason to believe there is any
                                fraudulent or illegal use of the account or if we are required to do
                                so by law, until and unless clearance has been obtained from the
                                relevant authorities, if applicable.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.DEPOSITING_OR_WITHDRAWING_FUNDS}
                    isSelected={
                        currentSection &&
                        currentSection.includes(
                            TERMS_AND_CONDITIONS_SECTIONS.DEPOSITING_OR_WITHDRAWING_FUNDS
                        )
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>
                                    8. Depositing funds into or withdrawing funds from your wallet
                                </strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                8.1 You can deposit and/or withdraw funds by logging into our portal
                                and following the relevant deposit and withdrawal instructions or
                                through our API. You may be presented with different deposit or
                                withdrawal methods, depending on which payment methods are available
                                in your country of residence. Such payment methods may be payment
                                services provided by a third party financial institution (for
                                example third party direct banking service providers) and are not
                                part of our Services. We may make changes to or discontinue the
                                acceptance of any particular payment method for depositing or
                                withdrawing your e-wallet at any time.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                8.2 When we receive funds for deposit from you, this money will be
                                held by our Partner(s) in their relevant safeguarded accounts in
                                exchange for the issuance by our Partner(s) to you of electronic
                                money. Upon acceptance of a deposit, we will update the balance in
                                your wallet, which you will be able to see through our portal. All
                                credit entries to your e-wallet are subject to our final receipt of
                                such funds. We shall not be responsible for the withdrawal payment
                                once the withdrawn funds are received by your payment service
                                provider.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                8.3 We may, at our sole discretion, refuse to accept a deposit or
                                withdrawal, if it does not comply with these Terms and Conditions or
                                if we reasonably believe that such deposit or withdrawal may violate
                                any applicable laws, the carrying out of the transaction may
                                compromise the security or integrity of our Services and our system
                                or such transaction not compliant with section 9.2.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                8.4 Loading or withdrawing funds are subject to fees including
                                currency conversion fees (if applicable), as set forth in the fees
                                schedule, as part of the cover letter.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                8.5 You have the right to withdraw funds from your e-wallet at any
                                time. Upon your request, we will return the total balance in your
                                e-wallet to the bank account or other payment method you used to
                                deposit funds, subject to any further conditions as set by us or our
                                Partner.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                8.6 You acknowledge that any funds held in the e-wallet may be
                                appropriated by our Partners pursuant to their terms and conditions,
                                for instance if you breach their terms or they incur any liability
                                in respect of the e-wallet held on your behalf.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.MAKING_PAYMENTS}
                    isSelected={
                        currentSection &&
                        currentSection.includes(TERMS_AND_CONDITIONS_SECTIONS.MAKING_PAYMENTS)
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>9. Making payments</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                9.1 To send a payment you are required to provide full details of
                                the payment and authorise the payment with your login details and
                                password and any other authentication methods. All payments are
                                subject to our checks (section 9.2) and approval prior execution
                                thereof.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                9.2 You acknowledge and agree that every recipient of a payment must
                                have a valid and legal purpose and you ensure that such payment
                                transfer does not violate any applicable laws. We retain the right
                                at all times to validate the purpose of the transfer and require any
                                necessary Know Your Customer (KYC) information or documentation in
                                accordance with our anti-money laundering policies.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                9.3 By submitting a payment request you accept that you are fully
                                informed regarding your obligations under this Agreement and the{' '}
                                <a href={'/terms-and-conditions-modulr-uk'}>
                                    {' '}
                                    Partner’s terms and conditions
                                </a>
                                . You further give thereby consent to execute the transaction. You
                                undertake to check whether we executed the transaction correctly and
                                fully.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                9.4 You must ensure that the funds on your e-wallet are sufficient
                                to cover the payments amount and any applicable fees. The value of
                                each payment request and any fees payable by you under this
                                Agreement will be deducted from the available balance on your
                                e-wallet.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                9.5 Sending payments is subject to fees including currency
                                conversion fees (if applicable), as set forth in the fees schedule.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                9.6 You undertake to check our confirmation of execution of a
                                payment request as soon as possible for errors such as inaccuracies
                                and omissions, and immediately inform us by phone and e-mail if any
                                transaction: (i) failed to reach the designated Recipient within
                                three business days of the approval of the applicable request;
                                and/or (ii) has been incorrectly executed by us.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                9.7 You may not use your e-wallet or the Services to receive or
                                transfer any funds on behalf of any other person or legal entity.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.PARTNERS}
                    isSelected={
                        currentSection &&
                        currentSection.includes(TERMS_AND_CONDITIONS_SECTIONS.PARTNERS)
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>10. Partners</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                10.1 In order to provide the Services, Merge may cooperate with and
                                use the services of third-party financial institutions and/or banks
                                (“Partners”).
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                10.2 Such Partners may provide you certain financial services,
                                including the issuance and management of electronic money accounts
                                (wallets). You acknowledge and agree that Merge may share your
                                information (including KYC and personal data) with Partners for the
                                purpose of providing the Services and that our Partners may perform
                                independent verification and credit checks on you.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                10.3 You acknowledge and agree that your use of the Partner(s)’
                                services may require you to accept their terms and conditions. A
                                link to such terms can be found in the above referenced list.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                10.4 You acknowledge and agree that we may provide any support to
                                you and our Partners in order for you to access Partners’ services,
                                including – but not limited – acting as intermediary and
                                communication point of reference. You hereby authorise us to
                                undertake the following activities in relating to your e-wallet
                                towards our Partners on your behalf: (i) to accept all data, files
                                and reports requiring communication between yourself and our
                                Partners, (ii) to be registered as authorised user on your e-wallet
                                for the purpose of receiving and sending payments, and holding
                                funds.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                10.5 To the extent the Partners’ terms and conditions are
                                applicable, Merge shall not be liable towards you for any acts or
                                omissions of Partners, unless attributable to us. We make reference
                                to the (limitation of) liability provisions under the Partners’
                                terms and conditions.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                10.6 The Electronic money account is provided by Modulr FS Limited,
                                authorised and regulated by the Financial Conduct Authority for
                                issuance of electronic money (FRN 900573).
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.END_USER_ACCOUNTS}
                    isSelected={
                        currentSection &&
                        currentSection.includes(TERMS_AND_CONDITIONS_SECTIONS.END_USER_ACCOUNTS)
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>11. End-user accounts</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                11.1 In the event you wish to set up specific sub-accounts in the
                                name of your end-users, the following additional conditions apply.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                11.2 You acknowledge and agree that as part of any sub-account setup
                                by you on behalf of your end-users (under your own wallet with us)
                                you ensure that you have received all necessary permissions from
                                your end-users to do so, to (technically) send us payment
                                instructions for such account on behalf of your end-users and to
                                manage such sub-accounts for the purposes as agreed between Parties.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                11.3 You acknowledge and agree that your permissions for such
                                sub-accounts may be restricted, for instance to ensure that you
                                cannot access or freely use the funds belonging to the end-user.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                11.4 We are authorised to act upon instructions given by you in
                                relation to such sub-accounts without making further enquiries into
                                the circumstances related to the instructions or checking or
                                considering the validity of the instructions. Any actions undertaken
                                by you will be deemed an action authorised by the end-user. It is
                                your responsibility to monitor the actions taken by your employees
                                and to alert us to any unauthorised access gained or actions taken.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                11.5 We will not be responsible for any consequences arising out of
                                any of your (or your employees’) actions, except due to our own
                                errors or gross negligence. In the event any payment order is
                                initiated by an unauthorised person, the following shall apply. You
                                are and remain liable for any wrong or unauthorised transaction
                                effected, irrespective of any reason including – but not limited to
                                - wrong information provided by you, funds sent without legal basis
                                and/or changes to recipient details. However, we will – but not be
                                obliged to do so - reasonably cooperate in reversal of a wrong
                                transaction, where you have notified us without undue delay on
                                becoming aware of the error.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                11.6 You agree to indemnify us and to hold us harmless from and
                                against all losses which we may incur or claims which may be brought
                                or established against us or any of our affiliates by any person
                                (including end-users) from or in connection with: (i) any payment
                                instructions and/or transaction or any other dealing between you and
                                an end-user and any claims from end-users; (ii) any action we
                                undertake pursuant to your instructions. At all times we retain the
                                right to require you to close a sub-account of a specific end-user,
                                for instance in circumstances relating to such end-user that would
                                give us the right to terminate under these Terms and Conditions as
                                per{' '}
                                <a
                                    href={`#${TERMS_AND_CONDITIONS_SECTIONS.TERM_SUSPENSION_AND_TERMINATION}`}
                                    onClick={() =>
                                        dispatch(
                                            actions.sectionSet(
                                                `#${TERMS_AND_CONDITIONS_SECTIONS.TERM_SUSPENSION_AND_TERMINATION}`
                                            )
                                        )
                                    }>
                                    clause 14.3
                                </a>
                                .{' '}
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.COMPLIANCE_WITH_LAWS_AND_PROHIBITED_ACTIVITY}
                    isSelected={
                        currentSection &&
                        currentSection.includes(
                            TERMS_AND_CONDITIONS_SECTIONS.COMPLIANCE_WITH_LAWS_AND_PROHIBITED_ACTIVITY
                        )
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>12. Compliance with laws and prohibited activity</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                12.1 You agree to only use the e-wallet and/or the Services for
                                lawful purposes and acknowledges and agrees that it is strictly
                                forbidden to use your e-wallet and/or the Services for any illegal
                                purposes, including - but not limited to - fraud and money
                                laundering and terrorist financing and any Prohibited Purpose. We
                                will report any suspicious activity to the relevant law enforcement
                                agency. You are prohibited from using your e-wallet in an attempt to
                                abuse, exploit or circumvent any usage restrictions imposed.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                12.2 It is your and not our responsibility to ensure that you only
                                may receive and/or send payments from or to persons or entities for
                                legitimate purposes in compliance with any applicable laws and
                                regulations. If you are in doubt as to the legality of a transfer,
                                you should not continue with your payment.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                12.3 It is strictly forbidden to send payments in connection with
                                the following prohibited categories for the sale or supply of:
                                illegal products or services, weapons and munitions, unlicensed
                                prescription drugs, hazardous materials, pyramid schemes,
                                counterfeit products, promotion of hatred, racism, religious
                                persecution or the initiation of force against others and/or funding
                                any of the items included in this list. This list is not exhaustive
                                and it is your responsibility to ensure that you do not use our
                                Services for transactions that may be considered illegal in your
                                jurisdiction. We reserve the right, in your sole discretion, to add
                                categories of prohibited transactions by adding such categories
                                either to these Terms and Conditions or the Terms of Use (these are
                                published on the website).
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                12.4 If you conduct or attempt to conduct any transaction in
                                violation of the prohibitions contained in this section or in
                                violation of applicable laws, we reserve the right to reverse the
                                transaction, report the transaction to the relevant law enforcement
                                agency, and/or claim damages from you. We also reserve the right to
                                suspend your access to or close your e-wallet at any time if we
                                reasonably believe to be required to do so by law or in order to
                                comply with recommendations issued by a relevant government
                                authority or other authorised body. To the extent necessary (or
                                required by law), we may freeze any funds in your e-wallet until
                                investigations conclude that no breach has occurred or until such
                                time as required pursuant to applicable laws or upon instructions of
                                a relevant government authority or other authorised body.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                12.5 We may impose limits on any and all transactions (deposit,
                                transfer, withdrawal) for the purpose of avoiding any fraudulent,
                                unauthorised, illegal or unlawful transactions or breach of this
                                section 10.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.FEES_AND_SET_OFF}
                    isSelected={
                        currentSection &&
                        currentSection.includes(TERMS_AND_CONDITIONS_SECTIONS.FEES_AND_SET_OFF)
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>13. Fees and set-off</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                13.1 All our Services are subject to the service fees set forth in
                                the cover letter. For clarity, the fees may be changed at any time
                                in accordance with this section. The fees for the Services may
                                include any additional charges or taxes applicable to each
                                transaction in accordance with applicable law.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                13.2 Any currency exchange fees shall be borne by you. For every
                                currency conversion, to the extent applicable, we will use a source
                                as made known to you plus a foreign exchange fee, which is displayed
                                in the fees schedule, as part of the cover letter. The foreign
                                exchange fee is payable in addition to the transaction fee. Currency
                                conversions may also be executed by our Partners, subject to their
                                terms.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                13.3 We may set-off all amounts you owe us against all amounts we
                                owe you at any time.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                13.4 You agree that any fees and other amounts due and payable to us
                                under this Agreement may be deducted from your e-wallet without
                                notice. Such deductions may be made at any time, as will be
                                reflected in the information on your e-wallet. Transaction fees will
                                be charged when the transaction is executed. If your e-wallet
                                balance is insufficient to cover the fees, we may refuse to execute
                                the payment.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                13.5 If the deduction of fees results in a negative e-wallet
                                balance, you will be required to repay such negative balance by
                                uploading sufficient funds into your e-wallet.{' '}
                                <a
                                    href={`#${TERMS_AND_CONDITIONS_SECTIONS.CLOSING_YOUR_WALLET}`}
                                    onClick={() =>
                                        dispatch(
                                            actions.sectionSet(
                                                `#${TERMS_AND_CONDITIONS_SECTIONS.CLOSING_YOUR_WALLET}`
                                            )
                                        )
                                    }>
                                    Section 7.2
                                </a>{' '}
                                shall apply.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                13.6 We reserve the right to change our fees by providing you at
                                least thirty (30) days notice. In the event any of our Partners or
                                underlying payment schemes change their fees, we retain the right to
                                reflect such changes in our Fees, by providing you notice thereof.{' '}
                                <a
                                    href={`#${TERMS_AND_CONDITIONS_SECTIONS.CHANGES_TO_THESE_TERMS}`}
                                    onClick={() =>
                                        dispatch(
                                            actions.sectionSet(
                                                `#${TERMS_AND_CONDITIONS_SECTIONS.CHANGES_TO_THESE_TERMS}`
                                            )
                                        )
                                    }>
                                    Clause 18.4
                                </a>{' '}
                                shall apply.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.TERM_SUSPENSION_AND_TERMINATION}
                    isSelected={
                        currentSection &&
                        currentSection.includes(
                            TERMS_AND_CONDITIONS_SECTIONS.TERM_SUSPENSION_AND_TERMINATION
                        )
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>14. Term, suspension and termination</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                14.1 This Agreement shall start on the Effective Date and continue
                                for an initial period of three (3) years after acceptance of these
                                Terms and Conditions and thereafter on a rolling 12 month basis
                                unless and until terminated by either party in accordance with this
                                section.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                14.2 We (or our Partners) may terminate your e-wallet or any payment
                                service associated with it by giving you two months’ prior notice.
                                You may terminate your e-wallet at any time by providing one months’
                                prior notice.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                14.3 We may suspend, limit your access to the e-wallet and Services
                                or terminate your e-wallet without prior notice and with immediate
                                effect, with no liability to you or to any third party for such
                                action: (i) you breach any conditions of this Agreement or the
                                Partners terms and conditions, (ii) we are requested by our Partner
                                as per their terms and conditions or requested by regulatory / law
                                enforcement authorities or required by applicable laws, (iii) you
                                violate (or we have reason to believe that you violate) applicable
                                laws, (iv) you are involved in any fraudulent or illegal activity or
                                if we consider the use of your e-wallet to be suspicious or unusual;
                                and/or (v) you have not provided information required by us, or
                                provided false, inaccurate or incomplete information. We can suspend
                                or block your e-wallet without prior notification to you and in such
                                cases, you may not be able to access all or part of your funds
                                unless the reasons that led to the suspension or block no longer
                                apply or exist and we deem that unblocking your e-wallet is
                                acceptable. We will give you notice as soon as possible of such
                                suspension or termination.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                14.4 We shall notify you either prior to the suspension or, if prior
                                notification is not possible under the circumstances, promptly after
                                the suspension unless we are prohibited by law to notify you.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                14.5 Termination of this Agreement shall not affect any transaction
                                previously carried out by you and shall not relieve either party of
                                any outstanding obligations arising out of this Agreement.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.LIMITATION_OF_LIABILITY}
                    isSelected={
                        currentSection &&
                        currentSection.includes(
                            TERMS_AND_CONDITIONS_SECTIONS.LIMITATION_OF_LIABILITY
                        )
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>15. (Limitation of) Liability</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                15.1 We shall not be liable for any indirect, special, punitive,
                                exemplary, statutory, incidental or consequential damage or any
                                similar damage or loss whatsoever (including, without limitation,
                                damages incurred for loss of business profits or revenue, loss of
                                privacy, business interruption, loss of business information or any
                                other loss) arising out or in connection with the Agreement and/or
                                the use of, or inability to use the Services, or from any failure,
                                delay, error or breakdown in the function of the Services (even if
                                we has been advised of the possibility of such damages). We shall
                                not be liable for any losses arising from our compliance with legal
                                and regulatory requirements.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                15.2 Subject to applicable law or any other provision under this
                                Section, our maximum liability to you under the Agreement or
                                otherwise in respect of the provision by us and use of the e-wallet
                                and any transactions is limited to the total fees paid by you in the
                                last six (6) months. In any event, our maximum liability for any
                                dispute in connection with certain transfer(s) processed by us shall
                                be the amount of the transfer that is under dispute.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                15.3 You undertake to notify us of any claim or action arising under
                                or relating to the Agreement as soon as possible and in any event,
                                no later than (12) months after the cause of action has occurred.
                                Failure to provide such notice shall be deemed as a waiver of such
                                claim or action.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                15.4 We shall not be liable for any disruption or impairment of our
                                Services or for disruptions or impairments of intermediary services
                                on which we rely for the performance of our obligations hereunder,
                                provided that such disruption or impairment is due to abnormal and
                                unforeseeable circumstances beyond our reasonable control or the
                                control of the intermediary affected.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                15.5 Nothing in these Terms and Conditions shall operate to exclude
                                liability for death or personal injury due to negligence or for
                                fraud or fraudulent misrepresentation or for any statutory liability
                                that cannot be excluded or amended by agreement between the parties.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                15.6 You agree to indemnify us for any claim, demand, expenses or
                                costs (including legal fees, fines or penalties) that we or our
                                affiliated companies incur or suffer due to or arising out of your
                                breach of these Terms and Conditions, you breach of any applicable
                                law or regulation and/or connected with your use of the Services.
                                This provision shall survive termination of the relationship between
                                parties.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.CONFIDENTIALITY_AND_DATA_PROTECTION}
                    isSelected={
                        currentSection &&
                        currentSection.includes(
                            TERMS_AND_CONDITIONS_SECTIONS.CONFIDENTIALITY_AND_DATA_PROTECTION
                        )
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>16. Confidentiality and data protection</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                16.1 You shall treat all information and knowledge obtained by you
                                in relation to our Services and/or derived as a result of your
                                access or use of the Services as confidential. We shall treat all
                                information received by you and designated as confidential or which
                                can be reasonably considered confidential. Following the foregoing,
                                each party shall take all precautions to prevent disclosure of any
                                such confidential information to any person or entity for any
                                reason.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                16.2 We respect your privacy. Our privacy policy and terms of use,
                                available on our website, are incorporated to these Terms and
                                Conditions by reference. Our privacy policy provides full details on
                                your rights as a data subject and our obligations as a data
                                controller. Please read this document carefully and ensure you
                                understand your rights.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                16.3 We will process your personal data in accordance with and for
                                the purposes described in our privacy policy. In order for us to
                                provide you with the Services relating to your e-wallet, we are
                                required to collect and process personal data about you, your
                                employees, shareholders and directors.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                16.4 We will implement and maintain adequate technical and
                                organisational measures for the security of your personal data.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.INTELLECTUAL_PROPERTY}
                    isSelected={
                        currentSection &&
                        currentSection.includes(TERMS_AND_CONDITIONS_SECTIONS.INTELLECTUAL_PROPERTY)
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>17. Intellectual property</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                17.1 All rights, title and interest in and to the Services,
                                including without limitation, patents, copyrights, trademarks, trade
                                names, service marks, trade secrets and other intellectual property
                                rights and any goodwill associated therewith, are our (or our
                                licensors’) exclusive property.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                17.2 We hereby grant you a personal, non-transferable,
                                non-sublicensable, limited time licence to use the website and our
                                portal for as long as you are a User of the Services, for the sole
                                purpose of accessing and using the Services.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.CHANGES_TO_THESE_TERMS}
                    isSelected={
                        currentSection &&
                        currentSection.includes(
                            TERMS_AND_CONDITIONS_SECTIONS.CHANGES_TO_THESE_TERMS
                        )
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>18. Changes to these terms</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                18.1 These Terms and Conditions may be subject to change. Changes
                                will be implemented with prior notice from us as set forth in this
                                section. We shall give notice to you of any proposed change by
                                sending an email to the primary email address registered with your
                                e-wallet.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                18.2 A change to our terms and conditions may take immediate effect
                                if the change is to your benefit, a result of changes to law and
                                regulations, a ruling by a court or action by regulators. We will
                                inform you as soon as possible if we make such a change.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                18.3 The proposed change shall come into effect two (2) months after
                                the date of the change notice, unless you have given us notice that
                                you object to the proposed changes before the changes come into
                                effect. Changes to exchange rates shall come into effect immediately
                                without notice and you shall not have the right to object to such a
                                change. These Terms and Conditions are also subject to change on
                                demand by local regulatory authorities and/or regulated financial
                                institutions that are involved in the Services with proper
                                notification. It is your responsibility to review your email account
                                for such notifications.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                18.4 In the event that you do not wish to accept such changes, you
                                may terminate the use of the Services free of charge. If you have
                                not indicated that you do not want to accept the amended terms, you
                                are deemed to have accepted the new terms after the relevant months
                                notice period has expired.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.COMMUNICATION_AND_COMPLAINTS}
                    isSelected={
                        currentSection &&
                        currentSection.includes(
                            TERMS_AND_CONDITIONS_SECTIONS.COMMUNICATION_AND_COMPLAINTS
                        )
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>19. Communication and complaints</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                19.1 We have an internal complaint procedure for complaints relating
                                to the use of our Services. Any complaints about us or the Services
                                we provide or our Partners’ services should be addressed to us in
                                the first instance by contacting our customer support team,
                                indicating that you wish to make a complaint. We will send you a
                                complaint acknowledgement by email within three business days of
                                receiving your complaint in accordance with our complaints
                                procedure. You can request a copy of our complaints procedure via
                                email.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                19.2 We will undertake all reasonable efforts to provide you with an
                                answer or resolution to your complaint within the timeframes as
                                outlined in the procedure, in accordance with regulatory
                                requirements. Should this not be possible due to unforeseen
                                circumstances or lack of information, we will contact you and notify
                                you thereof and provide the reasons and the deadline for a final
                                reply. Our Partners will handle any complaints relating to their
                                services in accordance with their policies, which are available upon
                                request to us.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                19.3 If Your complaint is not resolved to your satisfaction, you may
                                contact the Financial Ombudsman Service at{' '}
                                <a href={'mailto:complaint.info@financial-ombudsman.org.uk'}>
                                    complaint.info@financial-ombudsman.org.uk
                                </a>{' '}
                                and/or <a href={'tel:0800 023 4567'}>0800 023 4567</a>. For
                                additional contact details you may visit their website at:{' '}
                                <a
                                    href={'https://www.financial-ombudsman.org.uk/contact-us'}
                                    target={'_blank'}
                                    rel="noreferrer">
                                    https://www.financial-ombudsman.org.uk/contact-us
                                </a>
                                .
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
                <StyledHighlightedPanel
                    container
                    id={TERMS_AND_CONDITIONS_SECTIONS.GENERAL}
                    isSelected={
                        currentSection &&
                        currentSection.includes(TERMS_AND_CONDITIONS_SECTIONS.GENERAL)
                    }>
                    <StyledGrid container>
                        <Grid item>
                            <StyledParagrapgh>
                                <strong>20. General</strong>
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                20.1 You may not assign any rights under the Terms and Conditions to
                                any third party without our prior written consent. Any attempted or
                                actual assignment by you, without our prior written consent, shall
                                be null and void. You may furthermore not pledge or otherwise vest a
                                security interest in your e-wallet without our prior written
                                consent.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                20.2 We may assign this Agreement in its entirety, including all
                                right, duties, liabilities and obligations therein, upon notice to
                                you and without obtaining your further specific consent, to a
                                third-party, upon a merger, acquisition, change of control or the
                                sale of all or substantially all of its equity or assets relating to
                                the Agreement. By virtue of such assignment, the assignee assumes
                                our stead, including all right, duties, liabilities and obligations,
                                and we are irrevocably released therefrom.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                20.3 Your e-wallet is operated in the United Kingdom and the
                                Agreement shall be governed by and interpreted in accordance with
                                the laws of England. Any dispute under this Agreement or otherwise
                                in connection with your e-wallet shall be brought exclusively in the
                                courts of London, England.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                20.4 If any part of these Terms and Conditions is found by a court
                                of competent jurisdiction to be invalid, unlawful or unenforceable
                                by a competent court, then the provision shall be performed and
                                enforced to the maximum extent permitted by law to reflect as
                                closely as possible, the original intention of that provision, and
                                the remaining provisions of these Terms and Conditions shall
                                continue to remain in full force and effect.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                20.5 The section headings in these Terms and Conditions are included
                                for convenience only and shall take no part in the interpretation or
                                construing of these Terms and Conditions. Whenever used in these
                                Terms and Conditions, the term "Including", whether capitalised or
                                not, means without limitation to the preceding phrase. All examples
                                and e.g. notations are illustrative, not exhaustive.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                20.6 These Terms and Conditions and the cover letter constitute the
                                entire agreement between you and us concerning the subject matter
                                herein and supersede all prior and contemporaneous negotiations and
                                oral representations, agreements and statements.
                            </StyledParagrapgh>
                            <StyledParagrapgh>
                                20.7 No waiver, concession, extension, representation, alteration,
                                addition or derogation from these Terms and Conditions by us, or
                                pursuant to these Terms and Conditions, will be effective unless
                                consented to explicitly and executed in writing by our authorised
                                representative. Failure on our part to demand performance of any
                                provision in these Terms and Conditions shall not constitute a
                                waiver of any of our rights under these Terms and Conditions.
                            </StyledParagrapgh>
                        </Grid>
                    </StyledGrid>
                </StyledHighlightedPanel>
            </Container>
        </Box>
    );
};

export default TermsAndConditions;
