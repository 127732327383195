import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';

import CustomTooltip from '../CustomTooltip';
import Icon from '../Icon';

import styles from 'assets/styles/common.module.scss';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/common';
import selectState from 'store/selectors/common';
import selectGlobalSate from 'store/selectors/global';
import { SelectCurrency } from '.';
import Endpoints from 'utils/endpoints';
import { request } from 'utils/api';

const {
    recipients: { recipient },
} = Endpoints;

const SelectRecipientV2 = (props) => {
    const ref = useRef(null);

    const {
        selectRecipient: { recipients },
    } = selectState();
    const { enabledCurrencies } = selectGlobalSate();

    const {
        className,
        name,
        required,
        label,
        value,
        onChange,
        helperText,
        currency,
        error,
        disable,
        info,
        readonly = false,
    } = props;

    const [focus, setFocus] = useState(false);
    const [selected, setSelected] = useState(null);
    const [recipientCurrency, setRecipientCurrency] = useState(null);
    const [textInput, setTextInput] = useState(null);
    const dispatch = useDispatch();

    const handleChange = useCallback(
        (name, value) => {
            const selectedRecipient = recipients?.find((rec) => rec.id === value);
            if (selectedRecipient) {
                setTextInput(null);
            }
            setSelected(selectedRecipient);
            onChange(name, selectedRecipient ? selectedRecipient : null);
        },
        [onChange, recipients]
    );

    useEffect(() => {
        // It's a hack to differentiate, clear from component and outside component
        // Need to figure out better way to do it
        if (value === null || value === undefined) {
            // If value cleared from parent component
            setSelected(null);
            // Dont' clear the currency if it is explicitly set
            if (!currency) {
                setRecipientCurrency(null);
            }
        } else if (value === '') {
            setSelected(null);
        }
    }, [currency, value]);

    useEffect(() => {
        if (!value) {
            return;
        } else {
            const selectedRecipient = recipients?.find((rec) => rec.id === value);
            if (selectedRecipient) {
                setRecipientCurrency(selectedRecipient.currency);
                setTextInput(null);
            } else {
                // If the value is not present in the options, load it asynchronously
                request()
                    .get(`${recipient.url}/${value}`)
                    .then((response) => {
                        if (response.status === 200) {
                            const { data } = response;
                            setSelected(data);
                            setRecipientCurrency(data.currency);
                        } else {
                            // Something wrong
                        }
                    });
            }
            setSelected(selectedRecipient);
        }
    }, [recipients, value]);

    useEffect(() => {
        if (currency) {
            setRecipientCurrency(currency);
        }
    }, [currency]);

    useEffect(() => {
        if (recipientCurrency && (!textInput || textInput.length > 3)) {
            dispatch(
                actions.getRecipientsForSelect({
                    search: textInput,
                    currency: recipientCurrency,
                    size: 100,
                })
            );
        }
    }, [dispatch, recipientCurrency, textInput]);

    const materialTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        width: '100%',
                    },
                    input: {
                        '&.MuiSelect-select': {
                            height: '48px',
                        },
                        padding: '12px 14px',
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    popupIndicator: {
                        color: '#999',
                    },
                },
            },
        },
    });

    const renderDataBlock = (label, value) => {
        return (
            <div className={cx(styles.data)}>
                <div className={cx(styles.idLabel)}>{label}</div>
                <div className={cx(styles.idValue)}>{value}</div>
            </div>
        );
    };

    const renderRecipientInfo = (recipient, fillEmpty) => {
        return (
            <div className={cx(styles.accountMenuItem)}>
                <div className={cx(styles.accountDetails)}>
                    <div>
                        <div className={cx(styles.accountName)}>
                            {recipient.account_holder_name}
                        </div>
                        <div className={cx(styles.nickname)}>{recipient.nickname}</div>
                    </div>
                    <div>
                        <CustomTooltip info={`This recipient is a ${recipient.type}`}>
                            <Icon name={`recipient-${recipient.type}`} />
                        </CustomTooltip>
                    </div>
                </div>
                <div className={cx(styles.identifiers)}>
                    {recipient.account_number && renderDataBlock('Acc#', recipient.account_number)}
                    {recipient.iban && renderDataBlock('IBAN', recipient.iban)}
                    {recipient.sort_code && renderDataBlock('Sort#', recipient.sort_code)}
                    {recipient.bic && renderDataBlock('BIC', recipient.bic)}
                    {fillEmpty &&
                        [
                            recipient.account_number,
                            recipient.iban,
                            recipient.sort_code,
                            recipient.bic,
                        ]
                            .filter((x) => !x)
                            .map(() => renderDataBlock(' ', ' '))}
                </div>
            </div>
        );
    };

    const renderMenuItem = (recipient) => {
        return (
            <MenuItem
                onClick={(event, value) => handleChange(name, recipient.id)}
                style={{
                    maxWidth: ref?.current?.offsetWidth,
                    padding: '8px 0px !important',
                }}
                value={recipient.id}
                defaultValue={recipient.id}
                key={recipient.id}
                className={cx([
                    styles.selectMenuItem,
                    styles.selectAccountItem,
                    recipient.id === value && styles.selectMenuItemActive,
                ])}>
                {renderRecipientInfo(recipient, false)}
            </MenuItem>
        );
    };

    const renderSelectedValue = (recipient) => {
        return (
            <>
                {!readonly && (
                    <div className={cx(styles.close)}>
                        <Icon name="close" onClick={onClear} />
                    </div>
                )}
                <div className={cx(styles.accountCard)}>{renderRecipientInfo(recipient, true)}</div>
            </>
        );
    };

    const onClear = () => {
        onChange(name, '');
    };

    return (
        <div className={cx(styles.selectAccount)}>
            <div className={cx(styles.label)}>
                {label}
                {required && <span className={cx(styles.asterisk)}> *</span>}
                {info && (
                    <CustomTooltip info={info}>
                        <Icon name={'info'} />
                    </CustomTooltip>
                )}
            </div>
            <ThemeProvider theme={materialTheme}>
                <div className={cx(styles.currencyPicker)}>
                    <SelectCurrency
                        // If we have selected value disable the currency component
                        className={cx(styles.currencySelect)}
                        disable={disable || !!selected || readonly || currency}
                        placeHolderText={'Currency'}
                        name="recipientCurrency"
                        currencies={currency ? [currency] : enabledCurrencies}
                        value={recipientCurrency}
                        handleChange={(name, value) => {
                            setRecipientCurrency(value);
                        }}
                    />
                </div>
                <div
                    className={cx([
                        styles.accountHolder,
                        error ? styles.errorBorder : styles.default,
                        focus && styles.focus,
                        styles[className],
                    ])}>
                    {selected && renderSelectedValue(selected)}
                    {!selected && (
                        <Autocomplete
                            ref={ref}
                            name={name}
                            style={{
                                padding: '8px 14px',
                                background: '#fff',
                                borderRadius: '12px',
                            }}
                            disableClearable={true}
                            disabled={disable || !recipientCurrency || readonly}
                            isOptionEqualToValue={(option, value) => {
                                if (!value) {
                                    return true;
                                }
                                return option.id === value;
                            }}
                            ListboxProps={{
                                className: cx(styles.autoCompleteMenu),
                            }}
                            filterOptions={(x) => x}
                            value={value || ''}
                            options={recipients ?? []}
                            defaultValue={value || ''}
                            error={error}
                            noOptionsText="No recipients found"
                            renderInput={(params) => (
                                <TextField
                                    placeholder="Search by recipient details"
                                    {...params}
                                    fullWidth
                                />
                            )}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason === 'input') {
                                    setTextInput(newInputValue);
                                }
                            }}
                            renderOption={(props, recipient) => renderMenuItem(recipient)}
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.id
                            }
                            onFocus={() => setFocus((prevState) => !prevState)}
                            onChange={(event, newValue) => {
                                handleChange(event.target.name, event.target.value);
                            }}
                        />
                    )}
                    {helperText && <p className={cx(styles.asterisk)}>{helperText}</p>}
                </div>
            </ThemeProvider>
        </div>
    );
};

export default SelectRecipientV2;
