import { createSlice } from '@reduxjs/toolkit';

/*
 *  @desc The reports slice manages the state of the user
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    user: {},
    isLoading: false,
    error: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getUserDataPending(state) {
            state.isLoading = true;
        },
        getUserDataFailure(state, action) {
            state.user = {};
            state.isLoading = false;
            state.error = action.error;
        },
        getUserDataSuccess(state, action) {
            state.isLoading = false;
            state.user = action.payload;
        },
        clearUserData(state) {
            state.data = {};
            state.isLoading = false;
            state.error = null;
        },
    },
});

export const { actions, reducer, name } = userSlice;
