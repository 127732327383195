import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'store/slice/common';
import { useSelector } from 'react-redux';

const selectDomain = (state) => state.common || initialState;

export const selectRequestOtp = (state) => state.common.requestOtp || initialState.requestOtp;

const select = () => createSelector([selectDomain], (state) => state);

/* eslint-disable */
export default () => useSelector(select());
