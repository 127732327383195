import React, { useCallback, useState } from 'react';
import { Grid, List } from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/transaction.module.scss';
import moment from 'moment';
import TransactionItem from './TransactionItem';

const TransactionList = ({ transactions: { items } }) => {
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const onSelectHandler = useCallback(
        (txn) => {
            if (selectedTransaction?.transaction_id === txn.transaction_id) {
                setSelectedTransaction(null);
            } else {
                setSelectedTransaction(txn);
            }
        },
        [selectedTransaction]
    );

    return (
        <>
            <Grid item md={12} xs={12} className={cx(styles.list)}>
                {items?.length > 0 &&
                    items.map((item) =>
                        item.date ? (
                            <List key={item.date}>
                                <h1 className={cx(styles.createdDate)}>
                                    {moment(item.date).format('DD MMM YYYY')}
                                </h1>
                                {item.transactions?.length > 0 &&
                                    item.transactions.map((transaction) => (
                                        <TransactionItem
                                            key={transaction.transaction_id}
                                            transaction={transaction}
                                            onSelectHandler={onSelectHandler}
                                            isSelected={
                                                selectedTransaction?.transaction_id ===
                                                transaction.transaction_id
                                            }
                                        />
                                    ))}
                            </List>
                        ) : (
                            <></>
                        )
                    )}
            </Grid>
        </>
    );
};
export default TransactionList;
