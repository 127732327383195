import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonV2 } from 'components/common/Button';

const NoFoundComponent = () => {
    const navigate = useNavigate();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center', justifyContent: 'center', paddingTop: '20px' }}>
            <h1>404</h1>
            <p>Page not found</p>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <ButtonV2
                    text="Take me to the homepage"
                    size="lg"
                    onClick={() => {
                        navigate('/');
                    }}
                />
            </div>
        </div>
    );
};

export default NoFoundComponent;
