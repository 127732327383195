import React, { useCallback, useState } from 'react';
import { actions } from 'store/slice/pendingpayments';
import { useDispatch } from 'react-redux';
import selectState from 'store/selectors/pendingpayments';
import { Grid } from '@mui/material';
import cx from 'classnames';
import PendingPaymentsFilter from './PendingPaymentsFilter';
import styles from 'assets/styles/payments.module.scss';
import { Icon, Loading, Pagination } from 'components/common';
import PendingPaymentsList from './PendingPaymentsList';

const PendingPayments = () => {
    const { pendingPayments, loading } = selectState();
    const dispatch = useDispatch();
    const [params, setParams] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const handleFilter = useCallback(
        (queryParams = '', page = 1, size = 10) => {
            setParams(queryParams);
            dispatch(actions.getPendingPayments({ page, size, queryParams }));
        },
        [dispatch]
    );

    const refreshPayments = () => {
        dispatch(
            actions.getPendingPayments({
                page: page,
                size: perPage,
                queryParams: params,
            })
        );
    };

    const handlePerPageChange = useCallback(
        (value) => {
            setPerPage(value);
            handleFilter(params, 1, value);
        },
        [handleFilter, params]
    );

    const handlePageChange = useCallback(
        (page, perPage) => {
            setPage(page);
            handleFilter(params, page, perPage);
        },
        [handleFilter, params]
    );

    return (
        <Grid container className={cx(styles.container)}>
            <div className={cx(styles.header)}>
                <h1 className={cx(styles.title)}>Pending payments</h1>
                <Icon
                    name={'refresh'}
                    onClick={() =>
                        handleFilter(
                            params,
                            pendingPayments?.page ?? 1,
                            pendingPayments?.size ?? 10
                        )
                    }
                />
            </div>
            <Grid container>
                <PendingPaymentsFilter onFilter={handleFilter} loading={false} />
                {loading && <Loading className={cx(styles.loading)} />}
                {!loading && pendingPayments?.total === 0 && (
                    <h3 className={cx(styles.noData)}>No data found</h3>
                )}
                {!loading && pendingPayments?.items?.length > 0 && (
                    <PendingPaymentsList
                        pendingPayments={pendingPayments.items}
                        refreshHandler={refreshPayments}
                    />
                )}
                {!loading && pendingPayments?.items?.length > 0 && (
                    <Pagination
                        data={{
                            items: pendingPayments?.items,
                            page: pendingPayments?.page,
                            size: pendingPayments?.size,
                            total: pendingPayments?.total,
                        }}
                        pageChange={handlePageChange}
                        perPageChange={handlePerPageChange}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default PendingPayments;
