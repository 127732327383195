import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'store/slice/reports';
import { useSelector } from 'react-redux';

const selectDomain = (state) => state.reports || initialState;

export const selectMASReports = (state) =>
    state.reports.monthlyStatement || initialState.monthlyStatement;

const select = () => createSelector([selectDomain], (state) => state);

/* eslint-disable */
export default () => useSelector(select());
