import React, { useCallback, useRef, useState } from 'react';
import { Checkbox, ListItemIcon, ListItemText, MenuItem, Select } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import CustomTooltip from '../CustomTooltip';
import Icon from '../Icon';
import styles from 'assets/styles/common.module.scss';
import cx from 'classnames';
import CurrencyIcon from '../CurrencyIcon';

const MultiSelectField = (props) => {
    const ref = useRef(null);
    const {
        className,
        enableDefaultStyle = true,
        selectClassName,
        selectFieldClassname,
        labelClassname,
        name,
        options,
        required,
        label,
        value,
        onChange,
        helperText,
        error,
        defaultText = '',
        disable,
        disableItems = [],
        info,
    } = props;
    const [focus, setFocus] = useState(false);
    const isAllSelected = options.length > 0 && value.length === options.length;

    const handleChange = useCallback(
        (event) => {
            const target_value = event.target.value;
            if (target_value[target_value.length - 1] === 'all') {
                if (value.length === options.length) {
                    onChange(event.target.name, []);
                } else {
                    onChange(
                        event.target.name,
                        options.map((o) => o.value)
                    );
                }
                return;
            }
            onChange(event.target.name, target_value);
        },
        [onChange, value, options]
    );

    const materialTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        width: '100%',
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        paddingLeft: '4px',
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    icon: {
                        color: '#999',
                    },
                },
            },
        },
    });

    const getRenderValue = useCallback(
        (selected) => {
            if (selected.length === 0) {
                return null;
            }
            if (selected.length === options.length) {
                return 'All';
            }
            return options
                .filter((o) => selected.includes(o.value))
                .map((o) => o.label)
                .join(', ');
        },
        [options]
    );

    return (
        <div style={{ height: error ? '110px' : '80px' }} className={className}>
            <div className={cx(disable ? styles.disable : styles.label, labelClassname)}>
                {label}
                {required && <span className={cx(styles.asterisk)}> *</span>}
                {info && (
                    <CustomTooltip info={info}>
                        <Icon name={'info'} />
                    </CustomTooltip>
                )}
            </div>
            <ThemeProvider theme={materialTheme}>
                <div
                    className={cx([
                        error ? styles.errorBorder : enableDefaultStyle && styles.default,
                        focus && styles.focus,
                        selectClassName,
                    ])}>
                    <Select
                        ref={ref}
                        name={name}
                        open={focus}
                        multiple
                        disabled={disable}
                        value={value || []}
                        defaultValue={value || []}
                        error={error}
                        onOpen={() => setFocus(true)}
                        onClose={() => setFocus(false)}
                        renderValue={(selected) =>
                            getRenderValue(selected) ?? (
                                <span className={cx(styles.multiSelectPlaceholder)}>
                                    {defaultText}
                                </span>
                            )
                        }
                        onChange={handleChange}
                        displayEmpty={true}
                        className={cx(selectFieldClassname)}>
                        <MenuItem value="all">
                            <ListItemIcon>
                                <Checkbox
                                    style={{
                                        color: '#00A09B',
                                    }}
                                    classes={{
                                        indeterminate: cx(styles.indeterminateColor),
                                    }}
                                    checked={isAllSelected}
                                    indeterminate={
                                        value.length > 0 && value.length < options.length
                                    }
                                />
                            </ListItemIcon>
                            <ListItemText
                                // classes={{ primary: classes.selectAllText }}
                                primary="Select All"
                            />
                        </MenuItem>
                        <div className={cx(styles.divider)} />
                        {options?.map((option) => (
                            <MenuItem
                                style={{
                                    maxWidth: ref?.current?.offsetWidth,
                                    marginLeft: 0,
                                }}
                                value={option.value || ''}
                                defaultValue={option.value || ''}
                                key={option.value}
                                disabled={disableItems?.includes(option.value)}
                                className={cx([styles.multiSelectMenuItem])}>
                                <ListItemIcon>
                                    <Checkbox
                                        style={{
                                            color: '#00A09B',
                                        }}
                                        checked={value.includes(option.value)}
                                    />
                                </ListItemIcon>
                                {option.currency && (
                                    <ListItemIcon>
                                        <CurrencyIcon currency={option.currency} height="20px" />
                                    </ListItemIcon>
                                )}
                                <ListItemText
                                    // classes={{ primary: cx(styles.selectAllText) }}
                                    primary={option.label}
                                />
                            </MenuItem>
                        ))}
                    </Select>
                    {helperText && <p className={cx(styles.asterisk)}>{helperText}</p>}
                </div>
            </ThemeProvider>
        </div>
    );
};

export default MultiSelectField;
