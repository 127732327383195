import { UserType, Roles } from 'utils/auth';

export const getAuthData = (state) => state.auth;
export const getUserRole = (state) => state.auth?.login?.roles ?? [];

const containsRoles = (userRoles, expectedRoles) => {
    if (expectedRoles?.length === 0) {
        return false;
    }
    return expectedRoles?.every((role) => {
        return userRoles?.includes(role);
    });
};

export const getUserTypes = (state) => {
    var roles = state.auth?.login?.roles ?? [];
    if (roles?.length === 0) {
        return [UserType.BasicUser];
    }
    if (roles?.length === 1 && roles[0] === Roles.BasicUser) {
        return [UserType.BasicUser];
    }

    var userTypes = [];

    if (containsRoles(roles, [Roles.ViewerAll])) {
        userTypes.push(UserType.Viewer);
    }
    if (containsRoles(roles, [Roles.ViewerAll, Roles.MakerAll])) {
        userTypes.push(UserType.Maker);
    }
    if (containsRoles(roles, [Roles.ViewerAll, Roles.ApproverAll])) {
        userTypes.push(UserType.Approver);
    }
    if (containsRoles(roles, [Roles.ViewerAll, Roles.InstantPayerAll])) {
        userTypes.push(UserType.InstantPayer);
    }
    if (containsRoles(roles, [Roles.Developer])) {
        userTypes.push(UserType.Developer);
    }
    if (containsRoles(roles, [Roles.ViewerAll, Roles.Reporter])) {
        userTypes.push(UserType.Reporter);
    }
    if (containsRoles(roles, [Roles.UserAdmin])) {
        userTypes.push(UserType.UserAdmin);
    }

    if (containsRoles(roles, [Roles.ViewerAll, Roles.AccountAdmin])) {
        userTypes.push(UserType.AccountAdmin);
    }

    if (userTypes.length > 0) {
        return userTypes;
    }
    return [UserType.BasicUser];
};
