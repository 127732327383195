import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slice/userManagement';
import Endpoints from 'utils/endpoints';
import { request } from 'utils/api';

const {
    getUsersRequest,
    getRolesRequest,
    createUserRequest,
    deleteUserRequest,
    modifyUserRequest,
} = Endpoints.users;

export function* getUsers(action) {
    const { type } = action;
    try {
        const users = yield call(request().get, getUsersRequest.url);

        if (users && users.data && users.status === 200) {
            const userList = users.data.items;
            const sortedUsers = userList.sort(function (a, b) {
                const a_name = a.first_name + a.last_name;
                const b_name = b.first_name + b.last_name;
                if (a_name.toLowerCase() < b_name.toLowerCase()) {
                    return -1;
                }
                if (a_name.toLowerCase() > b_name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            yield put(actions.getUsersSuccess({ type, data: sortedUsers }));
        } else {
            yield put(
                actions.getUsersFailed({
                    message:
                        'Users list cannot be accessed right now. Please retry in a few minutes.',
                    status: users.status,
                })
            );
        }
    } catch (err) {
        yield put(
            actions.getUsersFailed({
                message: 'Users list cannot be accessed right now. Please retry in a few minutes.',
                status: err.status,
            })
        );
    }
}

export function* getRoles(action) {
    const { type } = action;
    try {
        const roles = yield call(request().get, getRolesRequest.url);

        if (roles && roles.data && roles.status === 200) {
            let modifyItems = [];

            roles.data.items.forEach((el) => {
                let _newOption = {};
                _newOption.label = el.display_name;
                _newOption.value = el.name;
                _newOption.description = el.description;
                modifyItems.push(_newOption);
            });

            yield put(actions.getRolesSuccess({ type, data: modifyItems }));
        }
    } catch (err) {
        yield put(
            actions.getRolesFailed({
                message: 'Roles list cannot be accessed right now. Please retry in a few minutes.',
                status: err.status,
            })
        );
    }
}

export function* addUser(action) {
    try {
        const newUser = yield call(request().post, createUserRequest.url, {
            email: action.payload.email,
            password: action.payload.password,
            phone_number: action.payload.phone_number,
            first_name: action.payload.first_name,
            last_name: action.payload.last_name,
            txn_email_otp_enabled: action.payload.txn_email_otp_enabled,
            is_active: action.payload.is_active,
            roles: action.payload.roles,
        });

        if (newUser && newUser.data && (newUser.status === 200 || newUser.status === 201)) {
            yield put(actions.addUserSuccess());
            yield put(actions.getUsers());
        } else if (newUser.status >= 400 && newUser.status < 500) {
            if (newUser?.data?.message === 'User already exist') {
                yield put(
                    actions.addUserFailed({
                        message:
                            'User email already exists. If the email is correct then reach out to cs@merge.money for additional support.',
                        status: newUser.status,
                    })
                );
            } else if (newUser?.data?.message === 'Email domain not enabled') {
                yield put(
                    actions.addUserFailed({
                        message:
                            'This email domain is not part of your business’s registered domain. Contact cs@merge.money to enable this email domain for your business.',
                        status: newUser.status,
                    })
                );
            } else {
                yield put(
                    actions.addUserFailed({
                        message:
                            'An error was encountered when creating the user. Retry after some time. If the issue persists, then contact support at cs@merge.money.',
                        status: newUser.status,
                    })
                );
            }
        } else {
            // status > 500
            yield put(
                actions.addUserFailed({
                    message:
                        'An error was encountered when creating the user. Retry after some time. If the issue persists, then contact support at cs@merge.money.',
                    status: newUser.status,
                })
            );
        }
    } catch (err) {
        if (err?.response?.status >= 400 && err?.response?.status < 500) {
            if (err?.response?.data?.message === 'User already exist') {
                yield put(
                    actions.addUserFailed({
                        message:
                            'User email already exists. If the email is correct then reach out to cs@merge.money for additional support.',
                        status: err?.response?.status,
                    })
                );
            } else if (err?.response?.data?.message === 'Email domain not enabled') {
                yield put(
                    actions.addUserFailed({
                        message:
                            'This email domain is not part of your business’s registered domain. Contact cs@merge.money to enable this email domain for your business.',
                        status: err?.response?.status,
                    })
                );
            } else {
                yield put(
                    actions.addUserFailed({
                        message:
                            'An error was encountered when creating the user. Retry after some time. If the issue persists, then contact support at cs@merge.money.',
                        status: err?.response?.status,
                    })
                );
            }
        } else {
            // status > 500
            yield put(
                actions.addUserFailed({
                    message:
                        'An error was encountered when creating the user. Retry after some time. If the issue persists, then contact support at cs@merge.money.',
                    status: err?.response?.status,
                })
            );
        }
    }
}
export function* updateUser(action) {
    try {
        const modifyUser = yield call(request().patch, modifyUserRequest.url(action.payload.id), {
            email: action.payload.email,
            password: action.payload.password,
            phone_number: action.payload.phone_number,
            first_name: action.payload.first_name,
            last_name: action.payload.last_name,
            txn_email_otp_enabled: action.payload.txn_email_otp_enabled,
            is_active: action.payload.is_active,
            roles: action.payload.roles,
        });

        if (
            modifyUser &&
            modifyUser.data &&
            (modifyUser.status === 200 || modifyUser.status === 201)
        ) {
            yield put(actions.updateUserSuccess({ user: modifyUser.data }));
            yield put(actions.getUsers());
        } else if (modifyUser.status >= 400 && modifyUser.status < 500) {
            if (modifyUser?.data?.message === 'User already exist') {
                yield put(
                    actions.addUserFailed({
                        message:
                            'User email already exists. If the email is correct then reach out to cs@merge.money for additional support.',
                        status: modifyUser.status,
                    })
                );
            } else if (modifyUser?.data?.message === 'Email domain not enabled') {
                yield put(
                    actions.addUserFailed({
                        message:
                            'This email domain is not part of your business’s registered domain. Contact cs@merge.money to enable this email domain for your business.',
                        status: modifyUser.status,
                    })
                );
            } else {
                yield put(
                    actions.updateUserFailed({
                        message:
                            'An error was encountered when updating the user. Retry after some time. If the issue persists, then contact support at cs@merge.money.',
                        status: modifyUser.status,
                    })
                );
            }
        } else {
            // status > 500
            yield put(
                actions.updateUserFailed({
                    message:
                        'An error was encountered when updating the user. Retry after some time. If the issue persists, then contact support at cs@merge.money.',
                    status: modifyUser.status,
                })
            );
        }
    } catch (err) {
        if (err?.response?.status >= 400 && err?.response?.status < 500) {
            if (err?.response?.data?.message === 'User already exist') {
                yield put(
                    actions.updateUserFailed({
                        message:
                            'User email already exists. If the email is correct then reach out to cs@merge.money for additional support.',
                        status: err?.response?.status,
                    })
                );
            } else if (err?.response?.data?.message === 'Email domain not enabled') {
                yield put(
                    actions.updateUserFailed({
                        message:
                            'This email domain is not part of your business’s registered domain. Contact cs@merge.money to enable this email domain for your business.',
                        status: err?.response?.status,
                    })
                );
            } else {
                yield put(
                    actions.updateUserFailed({
                        message:
                            'An error was encountered when updating the user. Retry after some time. If the issue persists, then contact support at cs@merge.money.',
                        status: err?.response?.status,
                    })
                );
            }
        } else {
            // status > 500
            yield put(
                actions.updateUserFailed({
                    message:
                        'An error was encountered when updating the user. Retry after some time. If the issue persists, then contact support at cs@merge.money.',
                    status: err?.response?.status,
                })
            );
        }
    }
}

export function* deleteUser(action) {
    try {
        const removeUser = yield call(request().delete, deleteUserRequest.url(action.payload.id));

        if (removeUser && removeUser.status >= 200 && removeUser.status < 300) {
            yield put(actions.deleteUserSuccess());
            yield put(actions.getUsers());
        } else {
            yield put(
                actions.deleteUserFailed({
                    message: 'Deleting the user failed. Please retry in a few minutes.',
                    status: removeUser.status,
                })
            );
        }
    } catch (err) {
        yield put(
            actions.deleteUserFailed({
                message: 'Deleting the user failed. Please retry in a few minutes.',
                status: err.status,
            })
        );
    }
}

export function* usersSaga() {
    yield takeLatest(actions.getUsers.type, getUsers);
    yield takeLatest(actions.getRoles.type, getRoles);
    yield takeLatest(actions.addUser.type, addUser);
    yield takeLatest(actions.deleteUser.type, deleteUser);
    yield takeLatest(actions.updateUser.type, updateUser);
}
