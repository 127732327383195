import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from '@mui/material';
import Icon from '../Icon';

import styles from 'assets/styles/common.module.scss';
import cx from 'classnames';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const InputFieldV2 = (props) => {
    const {
        label,
        value,
        hasIcon = false,
        iconName,
        type = 'text',
        placeholder,
        name,
        disable,
        helperText,
        error = false,
        onChange,
        required,
        handleIconClick,
        onBlur,
        iconPosition,
        additionalInputProps,
    } = props;

    //TODO Refactor this to handle this better
    const endAdornment = {
        endAdornment: (
            <InputAdornment position={'end'} className={cx(styles.adornment)}>
                {iconName ? <Icon name={iconName} onClick={() => handleIconClick()} /> : null}
            </InputAdornment>
        ),
    };

    const startAdornment = {
        startAdornment: (
            <InputAdornment position={'start'} className={cx(styles['start-adornment'])}>
                {iconName ? <Icon name={iconName} /> : null}
            </InputAdornment>
        ),
    };

    const materialTheme = createTheme({
        components: {
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        boxSizing: 'border-box',
                        height: '64px',
                    },
                },
            },
        },
    });

    return (
        <div className={cx(styles.inputFieldV2)}>
            <ThemeProvider theme={materialTheme}>
                <div className={cx(styles.inputContainer)}>
                    <div className={cx(styles.inputLabel)}>
                        {label}{required && <span className={cx(styles.asterisk)}> *</span>}
                    </div>
                    <TextField
                        name={name}
                        type={type}
                        fullWidth
                        className={cx(
                            styles.input,
                            error && styles.error,
                            name === 'password' && styles.password,
                            iconPosition === 'start' && styles['icon-start']
                        )}
                        disabled={disable}
                        placeholder={placeholder}
                        value={value || ''}
                        error={!!error}
                        onBlur={onBlur}
                        helperText={helperText ? helperText : error ? error : ''}
                        InputProps={
                            hasIcon
                                ? iconPosition === 'start'
                                    ? startAdornment
                                    : endAdornment
                                : {
                                    value: value || '',
                                }
                        }
                        inputProps={additionalInputProps}
                        onChange={onChange}
                    />
                </div>
            </ThemeProvider>
        </div>
    );
};

export default InputFieldV2;
