import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from '@mui/material';
import Icon from '../Icon';

import styles from 'assets/styles/common.module.scss';
import cx from 'classnames';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const InputField = (props) => {
    const {
        label,
        className,
        value,
        hasIcon = false,
        iconName,
        type = 'text',
        placeholder,
        name,
        disable,
        helperText,
        error = false,
        onChange,
        required,
        handleIconClick,
        onBlur,
        iconPosition,
        additionalInputProps,
        inputClassname,
        labelClassname,
    } = props;

    //TODO Refactor this to handle this better
    const endAdornment = {
        endAdornment: (
            <InputAdornment position={'end'} className={cx(styles.adornment)}>
                {iconName ? <Icon name={iconName} onClick={() => handleIconClick()} /> : null}
            </InputAdornment>
        ),
    };

    const startAdornment = {
        startAdornment: (
            <InputAdornment position={'start'} className={cx(styles['start-adornment'])}>
                {iconName ? <Icon name={iconName} /> : null}
            </InputAdornment>
        ),
    };

    const materialTheme = createTheme({
        components: {
            MuiInputBase: {
                styleOverrides: {
                    colorPrimary: {
                        borderRadius: '30px !important',
                        ':focused': {
                            backgroundColor: 'red',
                        },
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        color: '#FF8888',
                        fontSize: '14px',
                        lineHeight: '20px',
                        margin: '8px 0 0 0',
                    },
                },
            },
        },
    });

    return (
        <div className={className}>
            {label && (
                <div className={cx(disable ? styles.disable : styles.label, labelClassname)}>
                    {label}
                    {required && <span className={cx(styles.asterisk)}> *</span>}
                </div>
            )}
            <ThemeProvider theme={materialTheme}>
                <TextField
                    name={name}
                    type={type}
                    fullWidth
                    variant="outlined"
                    className={cx(
                        styles.inputField,
                        error && styles.error,
                        name === 'password' && styles.password,
                        iconPosition === 'start' && styles['icon-start'],
                        inputClassname
                    )}
                    disabled={disable}
                    placeholder={placeholder}
                    value={value || ''}
                    // value={value}
                    error={!!error}
                    onBlur={onBlur}
                    helperText={helperText ? helperText : error ? error : ''}
                    InputProps={
                        hasIcon
                            ? iconPosition === 'start'
                                ? startAdornment
                                : endAdornment
                            : {
                                  value: value || '',
                              }
                    }
                    inputProps={additionalInputProps}
                    onChange={onChange}
                />
            </ThemeProvider>
        </div>
    );
};

export default InputField;
