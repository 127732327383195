import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'store/slice/profile';
import { useSelector } from 'react-redux';

const selectProfile = (state) => state.profile || initialState;

const select = () => createSelector([selectProfile], (state) => state);

/* eslint-disable */
export default () => useSelector(select());
