import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { initialState } from './AppSlice';

export const selectDomain = (state) => state.app || initialState;

export const select = () => createSelector([selectDomain], (state) => state);

/* eslint-disable */
export default () => useSelector(select());
