import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slice/profile';
import Endpoints from 'utils/endpoints';
import { request } from 'utils/api';

const { updatePassword } = Endpoints.auth;
const { getRolesRequest } = Endpoints.users;

export function* changePassword(action) {
    try {
        const apiKeys = yield call(request().post, updatePassword.url, {
            old_password: action.payload.currentPassword,
            new_password: action.payload.password,
        });
        if (apiKeys && apiKeys.data && apiKeys.status === 200) {
            yield put(actions.changePasswordSuccess());
        } else if (apiKeys.status === 404) {
            yield put(
                actions.changePasswordError({
                    message: 'The current password does not match this console user’s password.',
                    status: apiKeys.status,
                })
            );
        } else {
            yield put(
                actions.changePasswordError({
                    message: 'Your password could not be updated. Please retry in a few minutes.',
                    status: apiKeys.status,
                })
            );
        }
    } catch (err) {
        const errorStatus = err.response.status;
        if (errorStatus === 404) {
            yield put(
                actions.changePasswordError({
                    message: 'The current password does not match this console user’s password.',
                    status: errorStatus,
                })
            );
        } else {
            yield put(
                actions.changePasswordError({
                    message: 'Your password could not be updated. Please retry in a few minutes.',
                    status: errorStatus,
                })
            );
        }
    }
}

export function* getRoles(action) {
    const { type } = action;
    try {
        const roles = yield call(request().get, getRolesRequest.url);

        if (roles && roles.data && roles.status === 200) {
            let modifyItems = [];

            roles.data.items.forEach((el) => {
                let _newOption = {};
                _newOption.label = el.display_name;
                _newOption.value = el.name;
                _newOption.description = el.description;
                modifyItems.push(_newOption);
            });

            yield put(actions.getRolesSuccess({ type, data: modifyItems }));
        }
    } catch (err) {
        yield put(
            actions.getRolesFailed({
                message: 'Roles list cannot be accessed right now. Please retry in a few minutes.',
                status: err.status,
            })
        );
    }
}
export function* profileSaga() {
    yield takeLatest(actions.changePassword.type, changePassword);
    yield takeLatest(actions.getRoles.type, getRoles);
}
