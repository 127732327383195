import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, InputField, Loading, Otp, Button } from 'components/common';
import { actions as commonActions } from 'store/slice/common';
import { actions as accountActions } from 'store/slice/account';
import { selectUpdateAccount } from 'store/selectors/account';
import { selectRequestOtp } from 'store/selectors/common';
import styles from 'assets/styles/account.module.scss';
import { StyledOtpButtonWrapper } from 'components/common/Otp/styles';
import DialogV2 from 'components/common/DialogV2';

export default function UpdateAccount({
    displayModal,
    handleModal,
    accountMeta,
    pageType,
    pageMeta,
}) {
    const dispatch = useDispatch();
    const [displayOtpDialog, setDisplayOtpDialog] = useState(false);
    const { loading, message, error, isLoaded } = useSelector(selectUpdateAccount);
    const { otpError, otpCount, otpToken, otpLoading, otpLoaded } = useSelector(selectRequestOtp);
    const errorLoadingUpdateReq = error.status >= 400;
    const errorLoadingOtp = otpLoaded && otpCount !== 0;
    const { accountName, accountId } = accountMeta;
    const accCreationValidationSchema = yup.object({
        name: yup
            .string()
            .required('Account name is required')
            .max(255, 'Account name must not exceed 255 characters')
            .matches(/^[a-zA-Z]/, 'Account name must start with a letter')
            .matches(
                /^[a-zA-Z][a-zA-Z0-9]*(?:\s+[a-zA-Z0-9]+)*\s?$/,
                'Account name can only contain letters, numbers and a white space'
            ),
        otp: yup.string().matches(/^[0-9]{6}$/, 'OTP must be exactly 6 digits'),
    });
    const initialValues = { name: accountName ?? '', otp: '' };
    const formik = useFormik({
        initialValues,
        validationSchema: accCreationValidationSchema,
        onSubmit: async ({ name }) => {
            if (formik.isValid) {
                dispatch(
                    commonActions.generateRequestOtp({
                        message: `{otp} is your passcode to update the account with alias name ${name}. Never share this code.`,
                        subject: 'Request to update account',
                        requestPath: `//v1/account/${accountId}`,
                        requestMethod: 'POST',
                        deliveryChannel: 'email',
                        requestPayload,
                    })
                );
            }
        },
    });
    const { values, isValid, dirty, touched, errors } = formik;

    const resetOtpDialog = (state) => {
        if (errorLoadingUpdateReq) {
            dispatch(accountActions.resetUpdateAccount());
        }
        setDisplayOtpDialog(state);
    };

    const requestPayload = useMemo(
        () => ({
            name: values.name?.trim(),
        }),
        [values.name]
    );

    const handleChange = useCallback(
        (event) => {
            const { name, value } = event.target;

            formik.setValues({
                ...values,
                [name]: value,
            });
        },
        [formik, values]
    );

    const submitAccountNameOtp = useCallback(() => {
        if (!errors.otp) {
            dispatch(
                accountActions.updateAccount({
                    accountId,
                    requestPayload,
                    otp: {
                        otp: values.otp,
                        token: otpToken,
                    },
                })
            );
        }
    }, [values.otp, errors.otp, dispatch, requestPayload, otpToken, accountId]);

    const handleSubmitOtpResend = useCallback(() => {
        formik.handleSubmit();
        formik.setFieldValue('otp', '');
        dispatch(accountActions.resetUpdateAccount());
    }, []);

    const onModalHandle = (val) => {
        dispatch(accountActions.resetUpdateAccount());
        dispatch(commonActions.resetRequestOtp());
        handleModal(val);
    };

    const onActionSubmit = () => {
        onModalHandle(false);
        switch (pageType) {
            case 'summary':
                dispatch(accountActions.getAccountsSummary());
                break;
            case 'accountList':
                if (pageMeta) {
                    const { currency, page, size } = pageMeta;
                    dispatch(
                        accountActions.getCurrencyAccounts({
                            currency,
                            page,
                            size,
                        })
                    );
                }
                break;
            default:
                return;
        }
    };

    const renderBody = () => (
        <InputField
            name={'name'}
            label={'Account alias name'}
            value={values.name}
            onChange={handleChange}
            error={touched.name && Boolean(errors.name)}
            helperText={errors.name}
            required={true}
            placeholder={'Enter the name'}
            disable={loading}
        />
    );

    useEffect(() => {
        if (displayModal) {
            formik.setValues(initialValues);
        }
        // eslint-disable-next-linew
    }, [displayModal]);

    useEffect(() => {
        if (isLoaded && !errorLoadingUpdateReq) {
            setDisplayOtpDialog(false);
        }
    }, [isLoaded, errorLoadingUpdateReq]);

    useEffect(() => {
        if (displayModal && otpLoaded && otpCount === 0 && otpError.status < 400) {
            setDisplayOtpDialog(true);
        }
    }, [displayModal, otpLoaded, otpCount, otpError.status]);

    return (
        <>
            <Dialog
                title={'Update account'}
                fullWidth
                open={displayModal}
                setOpen={onModalHandle}
                isSingleButton={isLoaded && !errorLoadingUpdateReq}
                submitButton={isLoaded && !errorLoadingUpdateReq ? 'Close' : 'Update'}
                cancelButton={'Cancel'}
                cancelAction={() => onModalHandle(false)}
                submitAction={() => {
                    formik.handleSubmit();
                }}
                isActions={true}
                isDisable={otpLoading || (!isLoaded && !(dirty && isValid))}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <form className={cx(styles.form)} onSubmit={formik.handleSubmit}>
                    {(otpLoading || loading) && <Loading className={cx(styles.formLoading)} />}
                    {renderBody()}
                </form>
            </Dialog>

            <Dialog
                title={'Authenticate'}
                open={displayOtpDialog}
                isSingleButton={false}
                setOpen={(val) => {
                    if (!val) {
                        resetOtpDialog(val);
                        formik.setFieldValue('otp', '');
                    }
                }}
                fullWidth
                submitButton={null}
                isActions={false}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <div className={cx(styles.dialog)}>
                    {!errors.name ? (
                        <Otp
                            name={'otp'}
                            heading={'Enter one-time passcode sent to the registered email id.'}
                            otp={values.otp}
                            onChange={(val) => {
                                if (values.otp?.length === 0 && error.message?.length) {
                                    dispatch(accountActions.resetUpdateAccount());
                                }
                                formik.setFieldValue('otp', val);
                            }}
                            disabled={values.otp?.length !== 6}
                            length={6}
                            onSubmit={submitAccountNameOtp}
                            onResend={handleSubmitOtpResend}
                            error={error}
                            buttonText={'Submit'}
                            otpCount={otpCount}
                            maxTime={45}
                            isSecured={true}
                            loading={loading}
                            addOnStyles={styles.centerLoader}
                        />
                    ) : (
                        <>
                            <StyledOtpButtonWrapper>
                                <Button
                                    onClick={() => {
                                        setDisplayOtpDialog(!displayOtpDialog);
                                    }}
                                    size="lg"
                                    variant="gradient"
                                    text={'Try Again'}
                                />
                            </StyledOtpButtonWrapper>
                        </>
                    )}
                </div>
            </Dialog>

            <DialogV2
                title={errorLoadingOtp ? 'Error' : 'Success'}
                open={(isLoaded && !errorLoadingUpdateReq) || errorLoadingOtp}
                setOpen={(state) => {
                    if (!state) {
                        onActionSubmit();
                    }
                }}
                isSingleButton={true}
                submitButton={errorLoadingOtp ? 'Retry' : 'Close'}
                variant={errorLoadingOtp ? 'error' : 'info'}
                submitAction={() => {
                    if (errorLoadingOtp) {
                        dispatch(commonActions.resetRequestOtp());
                        return;
                    }
                    onActionSubmit();
                }}>
                {errorLoadingOtp ? otpError.message : message}
            </DialogV2>
        </>
    );
}
