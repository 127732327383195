import React from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const BreadcrumbsNavigator = (props) => {
    const { prevPage, currentPage } = props;
    const navigate = useNavigate();

    const breadcrumbs = [
        <Typography
            sx={{
                '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }
            }}
            key="1"
            color="#111326"
            fontWeight={300}
            fontSize={'15px'}
            fontFamily={'Inter'}
            onClick={() => navigate(prevPage.href)}>
            {prevPage.title}
        </Typography>,
        <Typography key="2" color="#111326" fontWeight={500} fontSize={'15px'}>
            {currentPage.title}
        </Typography>,
    ];

    return (
        <Breadcrumbs
            style={{
                display: 'block',
                width: '100%',
                marginTop: '20px',
            }}
            separator={'>'}
            aria-label="breadcrumb">
            {breadcrumbs}
        </Breadcrumbs>
    );
};

export default BreadcrumbsNavigator;
