import axios from 'axios';
import { getAccessToken, getReCaptchaToken } from './auth';

const developmentDomain = 'https://api-development.merge.money';
const stagingDomain = 'https://api-staging.merge.money';
const sandboxDomain = 'https://api-sandbox.mergedup.com';
const productionDomain = 'https://api.mergedup.com';

let domain;
switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'staging':
        domain = stagingDomain;
        break;
    case 'sandbox':
        domain = sandboxDomain;
        break;
    case 'production':
        domain = productionDomain;
        break;
    default:
        domain = developmentDomain;
}

export const request = ({ prefix = '/v1' } = {}) => {
    const accessToken = getAccessToken();
    const reCaptchaToken = getReCaptchaToken();
    let token;
    let reCaptcha;
    if (accessToken) {
        token = { x_token: accessToken };
    }
    if (reCaptchaToken) {
        reCaptcha = { x_recaptcha_token: reCaptchaToken };
    }
    //@TODO add endpoint object
    const headers = {
        ...token,
        ...reCaptcha,
    };
    return axios.create({
        baseURL: `${domain}${prefix}`,
        headers,
    });
};
