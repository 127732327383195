import { Roles } from 'utils/auth';
import * as yup from 'yup';

export const otpTypes = [
    {
        type: 'txn_email_otp_enabled',
        label: 'Yes',
        value: true,
    },
    {
        type: 'txn_email_otp_enabled',
        label: 'No',
        value: false,
    },
];

export const isUserActiveTypes = [
    {
        label: 'True',
        value: true,
        type: 'is_active',
    },
    {
        label: 'False',
        value: false,
        type: 'is_active',
    },
];

export const userRoleNamesFromValues = (rolesList, userRoles) => {
    let userTextRoles = [];

    rolesList.forEach((role) => {
        if (userRoles.indexOf(role.value) > -1) {
            userTextRoles.push(role.label);
        }
    });

    return userTextRoles;
};

const areRolesValid = (roles) => {
    if (roles.length === 0) {
        return false;
    }
    const viewerPairedRoles = [
        Roles.MakerAll,
        Roles.ApproverAll,
        Roles.InstantPayerAll,
        Roles.Reporter,
    ];
    if (roles.some((role) => viewerPairedRoles.some((viewerRole) => role === viewerRole))) {
        if (!roles.includes(Roles.ViewerAll)) {
            return false;
        }
    }
    return true;
};

export const updateRolesValue = (currentValues, value, to_add) => {
    if (to_add) {
        currentValues.push(value);
    } else {
        const index = currentValues.indexOf(value);
        if (index > -1) {
            // only splice array when item is found
            currentValues.splice(index, 1); // 2nd parameter means remove one item only
        }
    }
    return currentValues;
};

const userAdminCountCheck = (roles, userAdminCount, isUserAdmin) => {
    if (userAdminCount === 0) {
        return roles.includes(Roles.UserAdmin);
    }
    // If the current user is user admin and company has only one admin, he can't remove the user admin role
    if (isUserAdmin && userAdminCount === 1) {
        return roles.includes(Roles.UserAdmin);
    }
    return true;
};

export const validationSchema = (userAdminCount, skipPassword, isUserAdmin) =>
    yup.object({
        first_name: yup.string('Enter your first name').required('First name is required'),
        last_name: yup.string('Enter your last name').required('Last name is required'),
        email: yup
            .string('Enter your email')
            .required('Email address is required')
            .email('Enter a valid email'),
        phone_number: yup
            .string('Enter you mobile number')
            .matches(/^((\+\d{5,20}))$/, 'Mobile number length must be between 5 and 20')
            .required('Mobile number is required'),
        roles: yup
            .array()
            .min(1, 'Select at least one role')
            .test('viewer-role-check', 'Viewer should be selected', areRolesValid)
            .test('atleast-one-admin', 'Atleast one admin should be selected', (roles) =>
                userAdminCountCheck(roles, userAdminCount, isUserAdmin)
            ),
        password: yup
            .string('Enter your password')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                'Password should contain at least 8 characters with 1 upper case letter, 1 lower case letter and 1 number'
            )
            .when('email', (_, schema) => {
                if (!skipPassword) {
                    return schema.required('Password is required');
                }
            }),
    });
