import React, { useCallback, useEffect, useState } from 'react';
import { actions } from 'store/slice/pendingpayments';
import selectState from 'store/selectors/pendingpayments';
import { useDispatch } from 'react-redux';
import { isQuoteEnabled } from 'utils/helpers';
import { PaymentDetail } from 'components/common/Payment';

const PendingPaymentReview = ({ payment }) => {
    const [error, setError] = useState(null);
    const {
        reviewAccount,
        reviewRecipient,
    } = selectState();
    const dispatch = useDispatch();

    const isPaymentRequestActive = useCallback((payment) => {
        return payment?.status === 'created' || payment?.status === 'retry';
    }, []);

    useEffect(() => {
        if (!payment || !reviewAccount.details) {
            return;
        }
        if (isPaymentRequestActive(payment)) {
            if (parseFloat(reviewAccount.details.balance) < parseFloat(payment.amount)) {
                setError('Insufficient funds. Please add funds to approve this payment request.');
                return;
            } else if (!!reviewAccount.error) {
                setError(
                    'Something went wrong. Please retry after sometime. If issue persists, reach out to cs@merge.money.'
                );
                return;
            }
            if (reviewRecipient.error === 'Recipient not found') {
                setError(
                    'Recipient not found. Please review the recipient details and create a new payment request.'
                );
                return;
            } else if (!!reviewRecipient.error) {
                setError(
                    'Something went wrong. Please retry after sometime. If issue persists, reach out to cs@merge.money.'
                );
                return;
            }
        }
        if (!reviewRecipient.details) {
            return;
        }
        setError('');
        const isQuote = isQuoteEnabled(reviewAccount.details);
        if (isQuote && isPaymentRequestActive(payment)) {
            dispatch(
                actions.getReviewQuote({
                    source_account: reviewAccount.details,
                    source_amount: payment.amount,
                    recipient: reviewRecipient.details,
                })
            );
        }
    }, [
        dispatch,
        reviewAccount.details,
        reviewAccount,
        isPaymentRequestActive,
        reviewRecipient,
        payment,
    ]);

    useEffect(() => {
        if (!payment) {
            return;
        }
        dispatch(actions.getReviewAccount({ account_id: payment.account_id }));
        dispatch(actions.getReviewRecipient({ recipient_id: payment.recipient_id }));
    }, [dispatch, payment]);

    return (
        <>
            {error && <div style={{ color: '#f88' }}>{error}</div>}
            <PaymentDetail
                details={{fromAccount: reviewAccount?.details, recipient: reviewRecipient?.details, sourceAmount: payment?.amount, reference: payment?.reference}}
            />
        </>
    )
};

export default PendingPaymentReview;