import React from 'react';
// import cx from 'classnames';
import Icon from './../Icon';
// import styles from './button.module.scss';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Button = styled('div')(
    ({
        disabled,
        variant,
        // hasIcon,
        // iconName,
        // fullWidth,
        margin,
        size,
    }) => ({
        borderRadius: '15px',
        outline: 'none',
        paddingTop: '7px',
        paddingBottom: '7px',
        color: '#fff',
        textAlign: 'center',
        fontFamily: 'Inter',
        cursor: 'pointer',
        fontSize: '18px',
        lineHeight: '26px',
        border: '1px solid #00A09B',
        ...(margin && {
            marginTop: margin.top,
            marginLeft: margin.left,
            marginRight: margin.right,
            marginBottom: margin.bottom,
        }),
        background: '#00A09B',
        '&:hover': {
            background: 'linear-gradient(270deg, #00A09B 0%, #016C87 100%)',
        },
        '&:active': {
            filter: 'drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25))',
        },
        ...(size === 'lg' && {
            paddingTop: '14px',
            paddingBottom: '14px',
        }),
        ...(variant === 'outlined' && {
            border: '1px solid #00A09B',
            boxSizing: 'border-box',
            background: 'transparent',
            color: '#00A09B',
            '&:hover': {
                background: 'rgba(0, 160, 155, 0.19)',
            },
            '&:active': {
                filter: 'drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25))',
            },
            ...(disabled && {
                backgroundColor: 'rgba(216, 216, 216, 0.05)',
                border: '1px solid #C4C4C4',
                borderRadius: '15px',
                color: '#C4C4C4',
            }),
        }),
        ...(variant === 'delete' && {
            outline: '1px solid #FF3131',
            boxSizing: 'border-box',
            background: 'transparent',
            color: '#FF3131',
            border: '1px solid #FF3131',
            '&:hover': {
                background: 'rgba(255,136,136,0.15)',
            },
            '&:active': {
                filter: 'drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25))',
            },
        }),
        ...(variant === 'gradient' && {
            boxSizing: 'border-box',
            background: 'linear-gradient(270deg, #00A09B 0%, #016C87 100%)',
            color: '#FFFFFF',
        }),
        ...(disabled && {
            border: '1px solid #C4C4C4',
            background: '#C4C4C4',
            cursor: 'not-allowed',
            pointerEvents: 'none',
        }),
    })
);

const CustomButton = (props) => {
    const {
        text,
        className,
        onClick,
        disabled = false,
        variant = 'primary',
        hasIcon = false,
        iconName,
        type,
        // fullWidth = false,
        margin,
        size,
    } = props;
    const icon = hasIcon ? <Icon name={iconName} style={{ verticalAlign: 'bottom' }} /> : null;
    return (
        <Button
            className={className}
            onClick={onClick}
            disabled={disabled}
            variant={variant}
            size={size}
            type={type}
            margin={margin}
            startIcon={icon}>
            <span>
                {text} {hasIcon ? icon : ''}
            </span>
        </Button>
    );
};

export default CustomButton;

CustomButton.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    hasIcon: PropTypes.bool,
    iconName: PropTypes.string,
    fullWidth: PropTypes.bool,
    type: PropTypes.string,
};
