import React from 'react';

const CountryIcon = ({ countryCode, rounded = false, ...rest }) => {
    if (countryCode === '') {
        return <></>;
    }
    const url = `/currency/${rounded ? 'round' : 'rect'}/${countryCode.toUpperCase()}.svg`;
    return <img alt={countryCode} title={countryCode} src={url} {...rest} />;
};

export default CountryIcon;
