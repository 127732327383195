import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Icon from '../Icon';
import CustomTooltip from '../CustomTooltip';
import styles from 'assets/styles/common.module.scss';
import { getPrevMonthDateFormat } from './utils';
import SelectField from './SelectField';

export default function SelectMonth({
    name,
    label,
    value,
    required,
    monthOptions,
    yearOptions,
    info,
    minDate,
    onDateChange,
    error,
    disable,
}) {
    const initCalendar = {
        year: getPrevMonthDateFormat('YYYY'),
        month: getPrevMonthDateFormat('MMMM'),
    };
    const [calendar, setCalendar] = useState(initCalendar);

    const updateToAvailableMonth = () => {
        setCalendar((calendarState) => {
            const { month, year } = calendarState;
            if (monthOptions.length) {
                const isMonthAvailable = monthOptions.some(({ value }) => value === month);

                if (isMonthAvailable) {
                    return calendarState;
                } else {
                    const newState = {
                        year,
                        month: monthOptions[0]?.value,
                    };

                    return newState;
                }
            }

            return calendarState;
        });
    };

    const onChange = (fieldName, val) => {
        setCalendar((state) => ({ ...state, [fieldName]: val }));
        onDateChange(name, calendar);
    };

    useEffect(() => {
        if (value.month !== calendar.month || value.year !== calendar.year) {
            onDateChange(name, calendar);
        }
    }, [value, calendar, onDateChange, name]);

    useEffect(() => {
        if (minDate) {
            setCalendar(initCalendar);
        }
    }, [minDate, setCalendar]);

    useEffect(() => {
        updateToAvailableMonth();
    }, [value?.year, minDate]);

    return (
        <div className={cx(styles.selectPicker)}>
            {label && (
                <div className={cx(styles.label)}>
                    {label}
                    {required && <span className={cx(styles.asterisk)}> *</span>}
                    {info && (
                        <CustomTooltip info={info}>
                            <Icon name={'info'} />
                        </CustomTooltip>
                    )}
                </div>
            )}
            <div
                className={cx([
                    error ? styles.errorBorder : styles.default,
                    styles.calendarSelect,
                ])}>
                <SelectField
                    name="year"
                    value={calendar.year}
                    placeHolderText={calendar.year}
                    onChange={onChange}
                    options={yearOptions}
                    className={styles.year}
                    hasLabel={false}
                    selectClassName={styles.selectBox}
                    disable={disable}
                    allowDeselect={false}
                />
                <SelectField
                    name="month"
                    value={calendar.month}
                    placeHolderText={calendar.month}
                    onChange={onChange}
                    options={monthOptions}
                    className={styles.month}
                    hasLabel={false}
                    selectClassName={styles.selectBox}
                    disable={disable}
                    allowDeselect={false}
                />
            </div>
        </div>
    );
}
