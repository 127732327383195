import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'store/slice/pendingpayments';
import { useSelector } from 'react-redux';

const selectDomain = (state) => state.payments || initialState;

const select = () => createSelector([selectDomain], (state) => state);

/* eslint-disable */
export default () => useSelector(select());
