import React from 'react';
import { getCountryCode, getCurrencyDescription } from 'utils/helpers';

const CurrencyIcon = ({ currency, rounded = false, ...rest }) => {
    const countryCode = getCountryCode(currency);
    const currencyDescription = getCurrencyDescription(currency);
    if (countryCode === '') {
        return <></>;
    }
    const url = `/currency/${rounded ? 'round' : 'rect'}/${countryCode.toUpperCase()}.svg`;
    return (
        <img
            alt={`${currencyDescription} - ${countryCode}`}
            title={`${currencyDescription} - ${currency.toUpperCase()}`}
            src={url}
            {...rest}
        />
    );
};

export default CurrencyIcon;
