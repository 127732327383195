const Endpoints = {
    recipients: {
        recipient: {
            url: '/recipient',
            auth: true,
        },
        getRecipient: {
            url: (queryParams) =>
                `/recipient?member_type=business${queryParams ? '&' + queryParams : ''}`,
            auth: true,
        },
    },
    developer: {
        createApiKey: {
            url: '/api-token-management/token',
            auth: true,
        },
        getApiKey: {
            url: '/api-token-management/token',
            auth: true,
        },
        deactivateApiKey: {
            url: (id) => `/api-token-management/token/${id}`,
            auth: true,
        },
    },
    accounts: {
        account: {
            url: '/account',
            auth: true,
        },
        accountDetails: {
            url: (account_id) => `account/${account_id}`,
            auth: true,
        },
        closeAccount: {
            url: (account_id) => `account/${account_id}/close`,
            auth: true,
        },
        filterAccounts: {
            url: (queryParams) => `/account?${queryParams}`,
            auth: true,
        },
        accounts: {
            url: ({ currency, page = 1, size = 10, status, search }) =>
                `/account?page=${page}${currency ? '&currency=' + currency : ''}&size=${size}${status ? '&status=' + status : ''
                }${search ? '&search=' + search : ''}&member_type=business`,
            auth: true,
        },
        transactions: {
            url: (account_id, page, size, queryParams) =>
                `account/${account_id}/transaction?page=${page}&size=${size}${queryParams ? '&' + queryParams : ''
                }`,
            auth: true,
        },
        summary: {
            url: 'account/account-summary',
            auth: true,
        },
        addFunds: {
            url: (accountId) => `account/${accountId}/add-balance`,
            auth: true,
        },
        generateStatement: {
            url: (accountId) => `account/${accountId}/generate-mas`,
            auth: true,
        },
    },
    featureFlags: {
        feature: {
            url: '/feature',
            auth: true,
        },
    },
    transactions: {
        transaction: {
            url: (page = 1, size = 10, queryParams, currency) =>
                `/transaction?page=${page}${currency ? '&currency=' + currency : ''}&size=${size}${queryParams ? '&' + queryParams : ''
                }`,
            auth: true,
        },
        simulateWebhook: {
            url: (transactionId) => `/transaction/${transactionId}/simulate-webhook`,
            auth: true,
        },
        fetchTransaction: {
            url: (accountId, transactionId) => `/account/${accountId}/transaction/${transactionId}`,
            auth: true,
        },
    },
    auth: {
        updatePassword: {
            url: '/auth/update-password',
            auth: true,
        },
    },
    payment_request: {
        get: {
            url: (page = 1, size = 10, queryParams, currency = 'GBP') =>
                `/payment-request?page=${page}${currency ? '&currency=' + currency : ''
                }&size=${size}${queryParams ? '&' + queryParams : ''}`,
        },
        post: {
            url: '/payment-request',
        },
    },
    report: {
        accountTransactions: {
            url: (account_id, payment_type_filter, status_filter) =>
                `/account/${account_id}/transaction?${payment_type_filter? payment_type_filter: ''}${status_filter ? `&${status_filter}`: ''}`,
            auth: true,
        },
        transactions: {
            url: (currency, payment_type_filter, status_filter) =>
                `transaction?${payment_type_filter? payment_type_filter: ''}${currency ? '&currency=' + currency : ''
                }${status_filter ? `&${status_filter}`: ''}`,
            auth: true,
        },
        masReport: {
            url: (queryParams) => `report/mas${queryParams ? `?${queryParams}` : ''}`,
            auth: true,
        },
    },
    users: {
        getUsersRequest: {
            url: '/user-management/user',
            auth: true,
        },
        getRolesRequest: {
            url: '/auth/role',
            auth: true,
        },
        createUserRequest: {
            url: '/user-management/user',
            auth: true,
        },
        deleteUserRequest: {
            url: (user_id) => `/user-management/user/${user_id}`,
            auth: true,
        },
        modifyUserRequest: {
            url: (user_id) => `/user-management/user/${user_id}`,
            auth: true,
        },
    },
};

export default Endpoints;
