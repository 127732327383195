import React from 'react';

import styles from 'assets/styles/common.module.scss';
import NumberFormat from 'react-number-format';
import cx from 'classnames';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';

const StyledNumberInput = styled(NumberFormat)(({ error }) => ({
    height: '64px',
    ...(error && {
        border: '1px solid #00A09B',
    }),
    boxSizing: 'border-box',
    font: 'inherit',
    letterSpacing: 'inherit',
    background: 'none',
    display: 'block',
    minWidth: 0,
    width: '100%',
    animationDuration: '10ms',
    marginBottom: '16px',
}));

const InputFieldV2 = (props) => {
    const {
        label,
        value,
        isNumber,
        isThousandOperator,
        placeholder,
        name,
        helperText,
        error = false,
        onValueChange,
        required,
    } = props;

    const materialTheme = createTheme({
        components: {
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        boxSizing: 'border-box',
                        height: '64px',    
                    },
                },
            },
        },
    });

    return (
        <div className={cx(styles.inputFieldV2)}>
            <ThemeProvider theme={materialTheme}>
                <div className={cx(styles.inputContainer)}>
                    <div className={cx(styles.inputLabel)}>
                        {label}{required && <span className={cx(styles.asterisk)}> *</span>}
                    </div>
                    <StyledNumberInput
                        placeholder={placeholder}
                        name={name}
                        isNumericString={!isNumber}
                        className={cx(styles.input)}
                        thousandSeparator={isThousandOperator}
                        value={value}
                        onValueChange={(val) => onValueChange(val.value)}
                        {...props}
                        error={error ? helperText : false} // Input expects error to be of string type. Overriding the type from above props
                    />

                    {/* <TextField
                        name={name}
                        type={type}
                        fullWidth
                        className={cx(
                            styles.input,
                            error && styles.error,
                            name === 'password' && styles.password,
                            iconPosition === 'start' && styles['icon-start']
                        )}
                        disabled={disable}
                        placeholder={placeholder}
                        value={value || ''}
                        error={!!error}
                        onBlur={onBlur}
                        helperText={helperText ? helperText : error ? error : ''}
                        InputProps={
                            hasIcon
                                ? iconPosition === 'start'
                                    ? startAdornment
                                    : endAdornment
                                : {
                                    value: value || '',
                                }
                        }
                        inputProps={additionalInputProps}
                        onChange={onChange}
                    /> */}
                </div>
            </ThemeProvider>
        </div>
    );
};

export default InputFieldV2;
