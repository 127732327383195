import React, { useCallback } from 'react';
import styles from 'assets/styles/transaction.module.scss';
import NumberFormat from 'react-number-format';
import { CustomList, CustomTooltip, Icon } from 'components/common';
import { formatAmount, getCurrencySymbol, toCapitalize } from 'utils/helpers';
import cx from 'classnames';
import TransactionDetails from './TransactionDetails';

const getSubtitle = (transaction) => {
    const transactionDetails = [];
    if (transaction.type === 'funds_in') {

        if (transaction.source_account_number) {
            transactionDetails.push(`Acc number: ${transaction.source_account_number}`);
        }
        if (transaction.source_sort_code) {
            transactionDetails.push(`Sort code: ${transaction.source_sort_code}`);
        }
        if (transaction.source_iban) {
            transactionDetails.push(`IBAN: ${transaction.source_iban}`);
        }
        if (transaction.source_bic) {
            transactionDetails.push(`BIC: ${transaction.source_bic}`);
        }
    } else {
        if (transaction.destination_account_number) {
            transactionDetails.push(`Acc number: ${transaction.destination_account_number}`);
        }
        if (transaction.destination_sort_code) {
            transactionDetails.push(`Sort code: ${transaction.destination_sort_code}`);
        }
        if (transaction.destination_iban) {
            transactionDetails.push(`IBAN: ${transaction.destination_iban}`);
        }
        if (transaction.destination_bic) {
            transactionDetails.push(`BIC: ${transaction.destination_bic}`);
        }        
    }
    return transactionDetails.join(', ');
};
 
const getTransactionStatusIcon = (transaction) => {
    switch (transaction.status) {
        case 'processing':
            return 'transaction-status-processing';
        case 'successful':
            return 'transaction-status-success';
        case 'failed':
            return 'transaction-status-failed';
        default:
            break;
    }
};

const getTransactionSign = (transaction) => {
    const currencySymbol = getCurrencySymbol(transaction.currency);
    if (transaction.payment_direction === 'funds_in') {
        return `+ ${currencySymbol}`;
    } else {
        return `- ${currencySymbol}`;
    }
};

const getTransactionStatusToolTip = (transaction) => {
    return (
        toCapitalize(transaction.status) +
        (transaction.status_note ? `: ${transaction.status_note}` : '')
    );
};

const getTitle = (transaction) => {
    switch (transaction.type) {
        case 'funds_in':
            return transaction.source_name;
        case 'funds_out':
            return transaction.destination_name;
        case 'fee':
            return transaction.reference;
        default:
            return '';
    }
};

const TransactionItem = ({ transaction, onSelectHandler, isSelected }) => {
    const handleTransactionClick = useCallback(
        (txn) => {
            onSelectHandler(txn);
        },
        [onSelectHandler]
    );

    return (
        <React.Fragment key={transaction.transaction_id}>
            <CustomList
                name={getTitle(transaction)}
                id={transaction.transaction_id}
                title={getTitle(transaction)}
                subtitle={getSubtitle(transaction)}
                avatar={'true'}
                customClass={cx(styles.item)}
                avatarClass={cx(styles.avatar)}
                actions={[
                    <span
                        key={'type'}
                        className={styles.type}
                        style={{
                            color:
                                transaction.payment_direction === 'funds_out'
                                    ? '#111326'
                                    : '#57AD9E',
                        }}>
                        <NumberFormat
                            prefix={`${getTransactionSign(transaction)}`}
                            value={formatAmount(transaction.amount)}
                            thousandSeparator={true}
                            displayType={'text'}
                            decimalScale={2}
                        />
                    </span>,
                    <span
                        key={'reference'}
                        className={cx(
                            styles.subStatusRef,
                            ((transaction.type === 'funds_in' && transaction.source_name) ||
                                (transaction.type === 'funds_out' &&
                                    transaction.destination_name)) &&
                                styles.subStatusRefNoData
                        )}>
                        Ref: {transaction.reference?.substring(0, 15)}
                        {transaction.reference?.length >= 18
                            ? '...'
                            : transaction.reference?.substring(15, 18)}
                    </span>,
                    <span key={'status'} className={cx(styles.statusIcon)}>
                        <CustomTooltip info={getTransactionStatusToolTip(transaction)}>
                            <Icon name={getTransactionStatusIcon(transaction)} />
                        </CustomTooltip>
                    </span>,
                    <span key={'id'} className={cx(styles.action)}>
                        <Icon
                            name={isSelected ? 'caret-up' : 'caret-down'}
                            color="#C4C4C4"
                            onClick={() => handleTransactionClick(transaction)}
                        />
                    </span>,
                ]}
                collapsibleContent={
                    <TransactionDetails
                        transaction={transaction}
                        isOpen={isSelected}
                    />
                }
            />
        </React.Fragment>
    );
};

export default TransactionItem;
