export const fundsType = [
    {
        label: 'Person',
        value: 'person',
        type: 'type',
    },
    {
        label: 'Business',
        value: 'business',
        type: 'type',
    },
];
