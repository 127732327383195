import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/dashboard';
import { actions as accountActions } from 'store/slice/account';
import selectAccountsState from 'store/selectors/account';
import selectGlobalState from 'store/selectors/global';
import selectUserState from 'store/selectors/user';
import selectState from 'store/selectors/dashboard';
import { CustomTooltip, Icon, Loading } from 'components/common';
import { Grid } from '@mui/material';

import styles from 'assets/styles/dashboard.module.scss';
import cx from 'classnames';

import styled from 'styled-components/macro';
import { formatAmount, getCurrencyDescription, getCurrencySymbol } from 'utils/helpers';
import TransactionSummary from './TransactionSummary';
import CurrencyIcon from 'components/common/CurrencyIcon';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';

export const StyledAccountInfo = styled('div')`
    width: 90%;
    height: auto;
    padding: 7px 16px;

    // @media (min-width: 840px) {
    //     width: 275px;
    // }
    background: ${(props) =>
        props.type === 'warning' ? 'rgba(255, 217, 100, 0.05)' : 'rgba(0, 160, 155, 0.05)'};

    border: 1px solid ${(props) => (props.type === 'warning' ? '#EBB102' : '#00A09B')};
    border-radius: 15px;
    h5,
    p {
        font: 14px 'Inter';
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: ${(props) => (props.type === 'warning' ? '#EBB102' : '#00A09B')};
        margin-bottom: 8px;
    }
    p {
        font-weight: 400;
        margin-bottom: 8px;
    }
    .icon-holder {
        margin-bottom: 16px;
    }
`;
export const AccountDetailsInfo = ({ account }) => {
    if (!account) {
        return <></>;
    }
    return (
        <>
            {account?.account_number && <p>Account number: {account.account_number}</p>}
            {account?.sort_code && <p>Sort Code: {account.sort_code}</p>}
            {account?.iban && <p>IBAN: {account.iban}</p>}
            {account?.bic && <p>BIC: {account.bic}</p>}
        </>
    );
};

/**
 * Dashboard component.
 */
const Dashboard = () => {
    const dispatch = useDispatch();

    const { transactionSummary: { loading, transactions } } = selectState();
    const { summary } = selectAccountsState();
    const { enabledCurrencies } = selectGlobalState();
    const { user } = selectUserState();

    const [isActive, setIsActive] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // If any of the currency is enabled, the company is consider to be activated
        const isActivated = enabledCurrencies.length > 0;
        setIsActive(isActivated);
    }, [enabledCurrencies]);

    useEffect(() => {
        dispatch(accountActions.getAccountsSummary());
        dispatch(actions.loadTransactions({}));
    }, [dispatch]);

    return (
        <div>
            <div className={styles.container}>
                <Grid container>
                    <Grid md={6} item>
                        <h1 className={cx(styles.title)}>{`Welcome ${user?.first_name || ''}!`}</h1>
                    </Grid>
                </Grid>
            </div>
            {!isActive ? (
                <div className={cx(styles.dashboard)}>
                    <p className={cx(styles.enableAccount)}>
                        To enable a new currency, please reach out to Merge customer support
                        at&nbsp;
                        <a href="mailto:cs@merge.money">cs@merge.money</a>.
                    </p>
                </div>
            ) : (
                <div className={cx(styles.dashboard)}>
                    <div className={cx(styles.content)}>
                        <div className={cx(styles.transactions)}>
                            <Grid container className={cx(styles.currencyScroller)}>
                                {summary?.currencies?.map((currencySummary) => (
                                    <CustomTooltip
                                        key={currencySummary.currency}
                                        info={getCurrencyDescription(currencySummary.currency)}>
                                        <Grid
                                            key={currencySummary.currency}
                                            item
                                            style={{ padding: '5px 10px' }}>
                                            <Grid container className={cx(styles.currencyCard)}>
                                                <Grid item>
                                                    <CurrencyIcon
                                                        rounded
                                                        currency={currencySummary.currency}
                                                        height="50px"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    {currencySummary.currency} balance
                                                </Grid>
                                                <Grid
                                                    item
                                                    style={{
                                                        fontWeight: 'bolder',
                                                    }}>
                                                    <NumberFormat
                                                        value={formatAmount(currencySummary.total_available_balance)}
                                                        thousandSeparator={true}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        prefix={getCurrencySymbol(currencySummary.currency)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CustomTooltip>
                                ))}
                            </Grid>
                            <Grid container style={{ margin: '30px 0px 30px' }}>
                                <div className={cx(styles.transactionSummary)}>
                                    <div style={{ fontSize: '24px' }}>Transaction summary</div>
                                    <div
                                        className={cx(styles.viewDetails)}
                                        onClick={() => {
                                            navigate(`/dashboard/transactions`);
                                        }}>
                                        View details  <Icon name={'chevron'} />
                                    </div>
                                </div>
                            </Grid>
                            {loading ? <Loading className={cx(styles.loading)} />
                                : (transactions?.length > 0 ? (
                                    <TransactionSummary transactions={transactions} />
                                ) : (
                                    <div className={cx(styles.noData)}>No data found</div>
                                ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
