import React, { useRef } from 'react';

import { formatAmount, getCurrencySymbol, toCapitalize } from 'utils/helpers';
import { Grid } from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/dashboard.module.scss';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { CustomTooltip, Icon } from 'components/common';

/**
 * TransactionSummary component.
 */
const TransactionSummary = ({ transactions }) => {
    const ref = useRef(null);

    const getTransactionSign = (transaction) => {
        const currencySymbol = getCurrencySymbol(transaction.currency);
        if (transaction.payment_direction === 'funds_in') {
            return `+ ${currencySymbol}`;
        } else {
            return `- ${currencySymbol}`;
        }
    };

    const getTransactionStatusIcon = (transaction) => {
        switch (transaction.status) {
            case 'processing':
                return 'transaction-status-processing';
            case 'successful':
                return 'transaction-status-success';
            case 'failed':
                return 'transaction-status-failed';
            default:
                break;
        }
    };

    const getTransactionStatusToolTip = (transaction) => {
        return (
            toCapitalize(transaction.status) +
            (transaction.status_note ? `: ${transaction.status_note}` : '')
        );
    };

    return (
        <Grid container sx={{ flexGrow: 1 }} className={cx(styles.txnList)}>
            <Grid item xs={12} className={cx(styles.header)}>
                <div className={cx(styles.payment_date)}>Created on</div>
                <div className={cx(styles.reference)}>Reference</div>
                <div className={cx(styles.amount)}>Amount</div>
                <div className={cx(styles.status)}>Status</div>
            </Grid>
            {/* style={{
                // 75px for fixed header, 10px for bottom padding
                minHeight: `calc(100vh - ${ref?.current?.offsetTop}px - 75px - 10px)`,
            }}> */}
            <Grid item xs={12} ref={ref} className={cx(styles.txnListData)} style={{height: `calc(100vh - ${ref?.current?.offsetTop}px - 140px - 10px)`, overflow: 'auto'}}>
                {transactions?.length > 0 &&
                    transactions?.map((transaction) => (
                        <Grid
                            item
                            xs={12}
                            key={transaction.transaction_id}
                            className={cx(styles.row)}>
                            <div className={cx(styles.payment_date)}>
                                <div>
                                    {moment(
                                        transaction.payment_date ?? transaction.created_at
                                    ).format('DD MMM YYYY HH:mm:ss')}
                                </div>
                            </div>
                            <div className={cx(styles.reference)}>
                                <div>{transaction.reference}</div>
                            </div>
                            <div className={cx(styles.amount)}>
                                <span
                                    key={'type'}
                                    className={styles.type}>
                                    <NumberFormat
                                        value={formatAmount(transaction.amount)}
                                        thousandSeparator={true}
                                        displayType={'text'}
                                        decimalScale={2}
                                        prefix={getTransactionSign(transaction)}
                                    />
                                </span>
                            </div>
                            <div className={cx(styles.status)}>
                                <span style={{display: 'flex', justifyContent: 'center'}}>
                                    <CustomTooltip info={getTransactionStatusToolTip(transaction)}>
                                        <Icon name={getTransactionStatusIcon(transaction)} />
                                    </CustomTooltip>
                                </span>
                            </div>
                        </Grid>
                    ))}
            </Grid>
        </Grid>
    );
};

export default TransactionSummary;
