export const transactionTypes = [
    {
        label: 'Payout',
        value: 'payout',
    },
    {
        label: 'Payin',
        value: 'payin',
    },
    {
        label: 'Fee',
        value: 'fee',
    },
];

export const transactionStatuses = [
    {
        label: 'Processing',
        value: 'processing',
    },
    {
        label: 'Successful',
        value: 'successful',
    },
    {
        label: 'Failed',
        value: 'failed',
    },
];

export const SIMULATE_WEBHOOK = {
    transactionStatus: {
        name: 'transactionStatus',
        title: 'Change transaction status',
        icon: 'change-transaction-status',
        allowedPaymentType: ['payout'],
        allowedStatuses: ['processing'],
    },
    transactionFee: {
        name: 'transactionFee',
        title: 'Charge fee',
        icon: 'charge-fee',
        allowedPaymentType: ['payin', 'payout'],
        allowedStatuses: ['processing', 'successful', 'failed'],
    },
};

export const transactionStatusRadioOptions = [
    {
        label: 'Successful',
        value: 'successful',
        type: 'statusType',
    },
    {
        label: 'Failed',
        value: 'failed',
        type: 'statusType',
    },
];

export const initSimulateTransactionState = {
    simulate: 'successful',
};
