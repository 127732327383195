import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Icon, Loading } from 'components/common';
import { Grid } from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/common.module.scss';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import selectState from 'store/selectors/common';
import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/common';
import {
    formatAmount,
    getCurrencySymbol,
    isAlphanumeric,
    isQuoteEnabled,
    validateReference,
} from 'utils/helpers';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SelectAccountV2 from '../SelectInput/SelectAccountV2';
import SelectRecipientV2 from '../SelectInput/SelectRecipientV2';
import CurrencyIcon from '../CurrencyIcon';
import NumberFormat from 'react-number-format';
import { InputFieldV2 } from '../InputField';
import { NumberInputV2 } from '../NumberInput';
import PaymentValue from './PaymentValue';

const StyledInput = styled(TextField)`
    .MuiInput-root {
        margin-top: 6px;
        font-size: 18px;
    }
    .MuiInputLabel-root {
        display: none;
    }
    .MuiInput-underline {
        width: 100%;
    }
    .MuiInput-underline:hover:before {
    }
    input {
        font-size: 20px;
        font-weight: 500;
        color: #2f2b3d;
    }
`;

const PaymentInput = (props) => {
    const { handleSubmit, values, defaultFrom } = props;
    const dispatch = useDispatch();
    const ref = useRef(null);

    const [destinationAmount, setDestinationAmount] = useState(null);
    const [isQuoteTransaction, setIsQuoteTransaction] = useState(values?.isQuoteTransaction);
    const [isDetailsValid, setIsDetailsValid] = useState(false);
    const {
        quote: { quoteInfo, loading, error },
    } = selectState();

    const isFromAccountAndRecipientSame = (fromAccount, recipient) => {
        if (!recipient) {
            return true;
        }

        if (fromAccount?.currency !== recipient?.currency) {
            return true;
        }

        if (
            recipient?.account_number &&
            fromAccount?.account_number !== recipient?.account_number
        ) {
            return true;
        }

        if (recipient?.sort_code && fromAccount?.sort_code !== recipient?.sort_code) {
            return true;
        }

        if (recipient?.iban && fromAccount?.iban !== recipient?.iban) {
            return true;
        }

        if (values.recipient?.bic && values.fromAccount?.bic !== values.recipient?.bic) {
            return true;
        }
        return false;
    };

    const isAmountEmpty = (amount) => {
        return !amount || parseFloat(amount) === 0;
    };

    const validationSchema = yup.lazy((values) => {
        const validationSchema = {
            fromAccount: yup.object(),
        };
        const sourceAmount = yup
            .string('Enter the amount to send')
            .test(
                'hasSufficientBalance',
                'Insufficient funds. Please recheck the amount to send.',
                function () {
                    if (!values.fromAccount || !values.sourceAmount) {
                        return true;
                    }
                    const amount = parseFloat(values.fromAccount?.balance - values.sourceAmount);
                    return amount >= 0;
                }
            )
            .nullable();
        const recipient = yup
            .object()
            .test(
                'isRecipientExchangeEnabled',
                'Exchange is not enabled in source account',
                function () {
                    if (!values.recipient || isQuoteTransaction) {
                        return true;
                    }
                    return values.recipient.currency === values.fromAccount?.currency;
                }
            )
            .test(
                'isFromAccountAndRecipientSame',
                'The payer and recipient account cannot be the same. Select another recipient.',
                function () {
                    return isFromAccountAndRecipientSame(values.fromAccount, values.recipient);
                }
            )
            .nullable();
        let reference = yup
            .string('Enter the transaction reference')
            .test(
                'referenceList',
                'Reference can only have a-z, A-Z, 0-9, spaces, ampersand (&), hyphen (-), full stop (.), solidus (/) characters',
                function () {
                    return validateReference(values.reference);
                }
            )
            .test(
                'sameCharacters',
                'All the alphanumeric characters cannot be the same',
                function () {
                    return !/^(.)\1+$/.test(values.reference?.replace(/ /g, ''));
                }
            )
            .test(
                'minimumAlphaNumericCharacters',
                'Reference must consist of at least 6 alphanumeric characters that are not all the same.',
                function () {
                    let count = 0;
                    if (!values.reference) {
                        return true; // Nullable values allowed
                    }
                    for (let i = 0; i < values.reference.length; i++) {
                        if (isAlphanumeric(values.reference[i])) {
                            count++;
                        }
                    }
                    return count >= 6;
                }
            );
        if (isQuoteTransaction) {
            reference = reference.max(
                16,
                'Limit the characters that can be entered on FE for reference field to 16'
            );
        } else {
            if (values.fromAccount?.currency === 'GBP') {
                reference = reference.max(
                    18,
                    'Limit the characters that can be entered on FE for reference field to 18'
                );
            }
            if (values.fromAccount?.currency === 'EUR') {
                reference = reference.max(
                    140,
                    'Limit the characters that can be entered on FE for reference field to 140'
                );
            }
        }
        return yup.object({
            ...validationSchema,
            sourceAmount,
            recipient,
            reference: reference.nullable(),
        });
    });

    const formik = useFormik({
        initialValues: {
            fromAccount: values?.fromAccount,
            recipient: values?.recipient,
            sourceAmount: values?.sourceAmount,
            reference: values?.reference,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit({
                ...values,
                isQuoteTransaction,
            });
        },
    });

    useEffect(() => {
        dispatch(actions.invalidateQuote());
    }, [dispatch]);

    useEffect(() => {
        if (
            formik.values.recipient !== null &&
            formik.isValid &&
            formik.values.fromAccount &&
            !isAmountEmpty(formik.values.sourceAmount) &&
            !isAmountEmpty(destinationAmount)
        ) {
            setIsDetailsValid(true);
        } else {
            setIsDetailsValid(false);
        }
    }, [dispatch, formik, destinationAmount]);

    const fetchQuote = () => {
        if (!isQuoteTransaction) {
            setDestinationAmount(formik.values.sourceAmount);
            return;
        }
        if (formik.values.recipient && formik.values.sourceAmount) {
            dispatch(
                actions.createQuote({
                    source_account: formik.values.fromAccount,
                    source_amount: formik.values.sourceAmount,
                    recipient: formik.values.recipient,
                    reference: formik.values.reference,
                })
            );
        }
    };

    const updateDetails = useCallback(
        (name, value) => {
            formik.setValues((data) => ({ ...data, [name]: value }));
        },
        [formik]
    );

    const handleChange = useCallback(
        (event) => {
            const { name, value } = event?.target;
            updateDetails(name, value);
        },
        [updateDetails]
    );

    const handleAccountChange = useCallback(
        (name, value) => {
            formik.setValues((data) => ({ ...data, [name]: value }));
        },
        [formik]
    );

    const handleRecipientsChange = useCallback(
        (name, value) => {
            formik.setValues((data) => ({ ...data, [name]: value }));
        },
        [formik]
    );

    useEffect(() => {
        if (!isQuoteTransaction) {
            setDestinationAmount(formik.values.sourceAmount);
        } else {
            dispatch(actions.invalidateQuote());
        }
        // Disabling the lint as we only this to happen while changing the amount
        // eslint-disable-next-line
    }, [dispatch, formik.values.sourceAmount]);

    useEffect(() => {
        if (quoteInfo) {
            setDestinationAmount(quoteInfo.destination_amount);
        } else if (isQuoteTransaction) {
            setDestinationAmount('');
        }
    }, [quoteInfo, isQuoteTransaction, error]);

    useEffect(() => {
        if (formik.values.fromAccount) {
            const enabled = isQuoteEnabled(formik.values.fromAccount);
            setIsQuoteTransaction(enabled);
        } else {
            dispatch(actions.invalidateQuote());
            setIsQuoteTransaction(null);
            setDestinationAmount(null);
            updateDetails('recipient', null);
        }
        // Disabling the lint as we adding updateDetails will trigger infinite update loop
        // eslint-disable-next-line
    }, [dispatch, formik.values.fromAccount]);

    useEffect(() => {
        // If recipient is unset, clear other info
        if (!formik.values.recipient) {
            dispatch(actions.invalidateQuote());
            setDestinationAmount(null);
            updateDetails('sourceAmount', '');
        }
        // eslint-disable-next-line
    }, [dispatch, formik.values.recipient]);

    const clearValues = () => {
        formik.setValues(() => ({
            fromAccount: null,
            recipient: null,
            sourceAmount: null,
            reference: null,
        }));
    };

    useEffect(() => {
        if (defaultFrom) {
            updateDetails('fromAccount', defaultFrom);
        }
        // Disabling the lint as we need this be triggered only if there is change in default from
        // eslint-disable-next-line
    }, [defaultFrom]);

    const isSourceAmountAllowed = (values) => {
        const { formattedValue, value, floatValue } = values;
        if (value.length > 1 && /^0*$/.test(value)) {
            return false;
        }
        return (
            formattedValue === '' || value === '.' || (floatValue >= 0 && floatValue <= 2147483647)
        );
    };

    const handleSourceAmountChange = (amount) => {
        if (amount.value === '') {
            updateDetails('sourceAmount', '');
        } else if (amount.value === '0') {
            updateDetails('sourceAmount', '0');
        } else if (amount.value !== '' && amount.value !== '0') {
            updateDetails('sourceAmount', formatAmount(amount.value));
        }
    };

    const renderSourceAmountPrefix = () => {
        return (
            <div style={{ fontSize: '20px', fontWeight: '500' }}>
                {getCurrencySymbol(formik.values.fromAccount?.currency)}
            </div>
        );
    };

    const renderQuoteInfo = () => {
        if (quoteInfo) {
            return (
                <>
                    <div className={cx(styles.quoteLabelContainer)}>
                        <div className={cx(styles.quoteLabel)}>Estimated exchange rate and fee</div>
                    </div>
                    <div className={cx(styles.fetchQuote)}>
                        <div className={cx(styles.exchangeRate)}>
                            <CurrencyIcon
                                className={cx(styles.currency)}
                                rounded
                                currency={formik.values.fromAccount?.currency}
                            />
                            1 {formik.values.fromAccount?.currency} =
                            <CurrencyIcon
                                className={cx(styles.currency)}
                                rounded
                                currency={formik.values.recipient?.currency}
                            />
                            {quoteInfo.exchange_rate} {formik.values.recipient?.currency}
                            <Icon
                                name="refresh"
                                className={cx(styles.refresh)}
                                onClick={() => {
                                    fetchQuote();
                                }}
                            />
                        </div>
                        <div className={cx(styles.divider)}></div>
                        <div className={cx(styles.feeAmount)}>
                            Fee :&nbsp;
                            <NumberFormat
                                value={formatAmount(quoteInfo.fee_amount)}
                                thousandSeparator={true}
                                displayType={'text'}
                                decimalScale={2}
                                prefix={getCurrencySymbol(formik.values.fromAccount?.currency)}
                            />
                        </div>
                    </div>
                </>
            );
        } else if (loading) {
            return (
                <div className={cx(styles.fetchQuote)}>
                    <Loading className={cx(styles.loading)} />
                </div>
            );
        } else {
            return (
                <div className={cx(styles.fetchQuote)}>
                    <div className={cx(styles.quoteText)}>Fetch exchange rate to proceed</div>
                    <div>
                        <Button
                            className={cx(styles.fetchBtn)}
                            disabled={loading || !formik.values?.sourceAmount}
                            text={'Fetch exchange rate'}
                            onClick={fetchQuote}
                        />
                    </div>
                </div>
            );
        }
    };

    return (
        <div
            ref={ref}
            className={cx(styles.paymentInputContainer)}
            style={
                {
                    // 75px for fixed header, 10px for bottom padding
                    // minHeight: `calc(100vh - ${ref?.current?.offsetTop}px - 75px - 10px)`,
                }
            }>
            <div>
                <div className={cx(styles.paymentAccounts)}>
                    <div className={cx(styles.paymentInputSelect)}>
                        <SelectAccountV2
                            name={'fromAccount'}
                            label={'Send funds from'}
                            value={formik.values.fromAccount?.id}
                            disableInactiveAccount={true}
                            disableEmptyAccount={true}
                            onChange={handleAccountChange}
                            showBalance={true}
                        />
                    </div>
                    <div className={cx(styles.paymentInputSelect)}>
                        <SelectRecipientV2
                            name={'recipient'}
                            disable={!formik.values.fromAccount}
                            label={'Send funds to'}
                            currency={
                                isQuoteTransaction === false
                                    ? formik.values.fromAccount?.currency
                                    : null
                            }
                            value={formik.values.recipient?formik.values.recipient.id: formik.values.recipient}
                            onChange={handleRecipientsChange}
                        />
                        {formik.errors.recipient && (
                            <Grid container mb={2}>
                                <Grid item md={12}>
                                    <p className={cx(styles.error)} style={{ marginTop: '15px' }}>
                                        {formik.errors.recipient}
                                    </p>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </div>
                {formik.values.recipient && !formik.errors.recipient && (
                    <>
                        {formik.errors.sourceAmount && (
                            <Grid container mb={2}>
                                <Grid item md={12}>
                                    <p className={cx(styles.error)}>{formik.errors.sourceAmount}</p>
                                </Grid>
                            </Grid>
                        )}
                        <div className={styles.amountInput}>
                            <div className={styles.source}>
                                <NumberInputV2
                                    label={'You send'}
                                    required={true}
                                    placeholder={'Enter the amount to send'}
                                    decimalScale={2}
                                    value={formik.values.sourceAmount}
                                    customInput={StyledInput}
                                    // variant="standard"
                                    isAllowed={isSourceAmountAllowed}
                                    onBlur={() => {
                                        if (formik.values.sourceAmount === '') {
                                            updateDetails('sourceAmount', '0');
                                        } else if (formik.values.sourceAmount !== '0') {
                                            updateDetails(
                                                'sourceAmount',
                                                formatAmount(formik.values.sourceAmount)
                                            );
                                        }
                                    }}
                                    onFocus={() => {
                                        if (formik.values.sourceAmount === '0') {
                                            updateDetails('sourceAmount', '');
                                        }
                                    }}
                                    onValueChange={handleSourceAmountChange}
                                    thousandSeparator=","
                                    InputProps={{ startAdornment: renderSourceAmountPrefix() }}
                                />
                            </div>
                            {isQuoteTransaction && !isAmountEmpty(formik.values.sourceAmount) && (
                                <div className={styles.quoteInfo}>
                                    {renderQuoteInfo()}
                                    {error && <div className={cx(styles.error)}>{error}</div>}
                                </div>
                            )}
                            <div className={styles.destination}>
                                {destinationAmount && (
                                    <PaymentValue
                                        label={'Send to recipient'}
                                        valueType={'number'}
                                        value={
                                            <NumberFormat
                                                value={formatAmount(destinationAmount)}
                                                thousandSeparator={true}
                                                displayType={'text'}
                                                decimalScale={2}
                                                prefix={getCurrencySymbol(
                                                    formik.values.recipient?.currency
                                                )}
                                            />
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div className={styles.reference}>
                            <InputFieldV2
                                placeholder="Payout"
                                label={'Reference'}
                                name="reference"
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                                variant="standard"
                                value={formik.values.reference}
                            />
                            {formik.touched.reference && formik.errors.reference && (
                                <p className={cx(styles['error'])}>{formik.errors.reference}</p>
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className={cx(styles.paymentDivider)}></div>
            <div className={cx(styles.paymentActions)}>
                <div className={cx(styles.submitButton)}>
                    <Button
                        text={'Clear all'}
                        variant="outlined"
                        onClick={clearValues}
                        disabled={!formik.values.fromAccount}
                    />
                </div>
                <div className={cx(styles.submitButton)}>
                    <Button
                        text={'Continue'}
                        onClick={formik.handleSubmit}
                        disabled={!isDetailsValid}
                    />
                </div>
            </div>
        </div>
    );
};

export default PaymentInput;
