import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import Img from 'assets/images/merge-logo.png';
import NeptuneData from './NeptuneData';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: '20px 20px 100px 20px',
    },
    logo: {
        margin: '20px',
        width: '120px',
        height: '25px',
    },
    pageNumbers: {
        fontSize: '9px',
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
});

const headerStyles = StyleSheet.create({
    row: {
        fontSize: '10px',
        fontFamily: 'Helvetica-Bold',
        flexDirection: 'row',
        textAlign: 'center',
        alignItems: 'center',
        width: '100%',
        height: '50px',
    },
    cell: {
        padding: '5px',
        border: '1px solid #ccc',
        borderRight: '0px solid #ccc',
        // height: 'auto',
        minHeight: '10px',
        height: '50px',
        verticalAlign: 'middle',
        // justifyContent: 'center',
        // alignItems: 'center',
    },
    id: {
        width: '6%',
    },
    status: {
        width: '2%',
    },
    type: {
        width: '1.5%',
    },
    typeDescription: {
        width: '2.5%',
    },
    account_number: {
        width: '3%',
    },
    sort_code: {
        width: '2%',
    },
    iban: {
        width: '5%',
    },
    bic: {
        width: '4%',
    },
    account_type: {
        width: '3%',
    },
    description: {
        width: '6%',
    },
    source: {
        width: '3%',
    },
    destination: {
        width: '3%',
    },
    amount: {
        width: '2%',
    },
    exchangeRate: {
        width: '2.5%',
    },
    currency: {
        width: '2%',
    },
    date: {
        width: '5.5%',
    },
    statusReason: {
        width: '3%',
    },
    statusNote: {
        width: '3%',
        borderRight: '1px solid #ccc',
    },
});

// Create Document Component
const NeptuneReport = ({ data }) => (
    <Document>
        <Page size="A0" orientation="landscape" style={styles.page} wrap>
            <View fixed>
                <Image style={styles.logo} src={Img} cache={false} />
            </View>
            <View fixed>
                <View style={headerStyles.row} key={'header'}>
                    <Text style={[headerStyles.id, headerStyles.cell]}>
                        Transaction ID
                    </Text>
                    <Text style={[headerStyles.id, headerStyles.cell]}>Account ID</Text>
                    <Text style={[headerStyles.account_type, headerStyles.cell]}>Account type</Text>
                    <Text style={[headerStyles.status, headerStyles.cell]}>Status</Text>
                    <Text style={[headerStyles.date, headerStyles.cell]}>Payment date</Text>
                    <Text style={[headerStyles.type, headerStyles.cell]}>Type</Text>
                    <Text style={[headerStyles.typeDescription, headerStyles.cell]}>Type {'\n'}  description</Text>
                    <Text style={[headerStyles.description, headerStyles.cell]}>Reference</Text>
                    <Text style={[headerStyles.source, headerStyles.cell]}>Source name</Text>
                    <Text style={[headerStyles.currency, headerStyles.cell]}>Source {'\n'}  currency</Text>
                    <Text style={[headerStyles.amount, headerStyles.cell]}>Source amount</Text>
                    <Text style={[headerStyles.account_number, headerStyles.cell]}>
                        Source Acc#
                    </Text>
                    <Text style={[headerStyles.sort_code, headerStyles.cell]}>
                    Source Sort#
                    </Text>
                    <Text style={[headerStyles.iban, headerStyles.cell]}>
                        Source IBAN
                    </Text>
                    <Text style={[headerStyles.bic, headerStyles.cell]}>
                        Source BIC
                    </Text>
                    <Text style={[headerStyles.destination, headerStyles.cell]}>
                        Destination name
                    </Text>
                    <Text style={[headerStyles.currency, headerStyles.cell]}>Destination currency</Text>
                    <Text style={[headerStyles.amount, headerStyles.cell]}>Destination amount</Text>
                    <Text style={[headerStyles.account_number, headerStyles.cell]}>
                    Destination Acc#
                    </Text>
                    <Text style={[headerStyles.sort_code, headerStyles.cell]}>
                    Destination Sort#
                    </Text>
                    <Text style={[headerStyles.iban, headerStyles.cell]}>
                    Destination IBAN
                    </Text>
                    <Text style={[headerStyles.bic, headerStyles.cell]}>
                    Destination BIC
                    </Text>
                    <Text style={[headerStyles.amount, headerStyles.cell]}>Amount</Text>
                    <Text style={[headerStyles.currency, headerStyles.cell]}>Currency</Text>
                    <Text style={[headerStyles.id, headerStyles.cell]}>Quote ID</Text>
                    <Text style={[headerStyles.exchangeRate, headerStyles.cell]}>Exchange rate</Text>
                    <Text style={[headerStyles.date, headerStyles.cell]}>Creation time</Text>
                    <Text style={[headerStyles.statusReason, headerStyles.cell]}>
                        Status reason
                    </Text>
                    <Text style={[headerStyles.statusNote, headerStyles.cell]}>Status note</Text>
                </View>
            </View>
            <View style={styles.tableContainer}>
                <NeptuneData items={data} />
            </View>
            <Text
                style={styles.pageNumbers}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed></Text>
        </Page>
    </Document>
);

export default NeptuneReport;
