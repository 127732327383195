import { createSlice } from '@reduxjs/toolkit';

/*
 *  @desc The accounts slice manages the state of the accounts
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    loading: false,
    error: {
        status: false,
        message: '',
    },
    selectedAccount: null,
    summary: null,
    accounts: {
        items: [],
        total: null,
        page: null,
        size: null,
    },
    currencyAccounts: {
        items: [],
        total: null,
        page: null,
        size: null,
    },
    transactions: [],
    addFunds: {
        loading: false,
        message: '',
        error: false,
    },
    createCustomerAccount: {
        loading: false,
        message: '',
        isLoaded: false,
        error: {
            message: '',
            status: null,
        },
    },
    updateAccount: {
        loading: false,
        message: '',
        isLoaded: false,
        error: {
            message: '',
            status: null,
        },
    },
    closeCustomerAccount: {
        loading: false,
        message: '',
        isLoaded: false,
        error: {
            message: '',
            status: null,
        },
    },
};

export const accountsSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        getAccounts(state) {
            state.loading = true;
            state.error = {
                status: false,
            };
        },
        getAccountsSuccess(state, action) {
            state.loading = false;
            state.accounts = action.payload.data;
            state.error = {
                status: false,
            };
        },
        getAccountsError(state, action) {
            state.loading = false;
            state.error = {
                message: action.payload,
                status: true,
            };
        },
        getCurrencyAccounts(state) {
            state.loading = true;
            state.currencyAccounts = {
                items: [],
                total: null,
                page: null,
                size: null,
            };
            state.error = {
                status: false,
            };
        },
        getCurrencyAccountsSuccess(state, action) {
            state.loading = false;
            state.currencyAccounts = action.payload.data;
            state.error = {
                status: false,
            };
        },
        getCurrencyAccountsError(state, action) {
            state.loading = false;
            state.error = {
                message: action.payload,
                status: true,
            };
        },

        getSubAccountTransactions: (state, action) => {
            state.transactions = [];
            // action.payload ? action.payload : state.transactions;
        },
        getSubAccountTransactionsSuccess: (state, action) => {
            state.transactions = action.payload;
            // ? action.payload : state.account
        },
        getSubAccountTransactionsError: (state) => {
            state.transactions = [];
        },
        getSelectedAccount: (state, action) => {
            state.loading = true;
            state.selectedAccount = null;
            state.error = {
                status: false,
            };
        },
        getSelectedAccountSuccess: (state, action) => {
            state.loading = false;
            state.selectedAccount = action.payload.account;
            state.error = {
                status: false,
            };
        },
        getSelectedAccountError(state, action) {
            state.loading = false;
            state.error = {
                message: action.payload,
                status: true,
            };
        },
        removeTransactions: (state) => {
            state.transactions = [];
        },
        getAccountsSummary(state) {
            state.loading = true;
            state.error = {
                status: false,
            };
        },
        getAccountsSummarySuccess(state, action) {
            state.loading = false;
            state.summary = action.payload.data;
            state.error = {
                status: false,
            };
        },
        getAccountsSummaryError(state, action) {
            state.loading = false;
            state.error = {
                message: action.payload,
                status: true,
            };
        },
        addAccountsFunds(state) {
            state.addFunds.loading = true;
        },
        addAccountsFundsSuccess(state, action) {
            state.addFunds.loading = false;
            state.addFunds.message = action.payload;
            state.addFunds.error = false;
        },
        addAccountsFundsError(state) {
            state.addFunds.loading = false;
            state.addFunds.message =
                'An error was encountered while crediting funds. Please retry after some time. If the issue persists, then contact support at cs@merge.money';
            state.addFunds.error = true;
        },
        resetAccountsFundsState(state) {
            state.addFunds.loading = false;
            state.addFunds.message = '';
            state.addFunds.error = false;
        },
        createCustomerAccount(state) {
            state.createCustomerAccount = { ...initialState.createCustomerAccount, loading: true };
        },
        createCustomerAccountSuccess(state, { payload }) {
            state.createCustomerAccount.loading = false;
            state.createCustomerAccount.message = payload;
            state.createCustomerAccount.isLoaded = true;
            state.createCustomerAccount.error = false;
        },
        createCustomerAccountError(state, { payload }) {
            state.createCustomerAccount.loading = false;
            state.createCustomerAccount.message = payload.message;
            state.createCustomerAccount.isLoaded = true;
            state.createCustomerAccount.error = payload;
        },
        resetCreateCustomerAccount(state) {
            state.createCustomerAccount = { ...initialState.createCustomerAccount };
        },
        updateAccount(state) {
            state.updateAccount = { ...initialState.updateAccount, loading: true };
        },
        updateAccountSuccess(state, { payload }) {
            state.updateAccount.loading = false;
            state.updateAccount.message = payload;
            state.updateAccount.isLoaded = true;
            state.updateAccount.error = false;
        },
        updateAccountError(state, { payload }) {
            state.updateAccount.loading = false;
            state.updateAccount.message = payload.message;
            state.updateAccount.isLoaded = true;
            state.updateAccount.error = payload;
        },
        resetUpdateAccount(state) {
            state.updateAccount = { ...initialState.updateAccount };
        },
        closeCustomerAccount(state) {
            state.closeCustomerAccount = { ...initialState.closeCustomerAccount, loading: true };
        },
        closeCustomerAccountSuccess(state, { payload }) {
            state.closeCustomerAccount.loading = false;
            state.closeCustomerAccount.message = payload;
            state.closeCustomerAccount.isLoaded = true;
            state.closeCustomerAccount.error = false;
        },
        closeCustomerAccountError(state, { payload }) {
            state.closeCustomerAccount.loading = false;
            state.closeCustomerAccount.message = payload.message;
            state.closeCustomerAccount.isLoaded = true;
            state.closeCustomerAccount.error = payload;
        },
        resetCloseCustomerAccount(state) {
            state.closeCustomerAccount = { ...initialState.closeCustomerAccount };
        },
    },
});

export const { actions, reducer, name } = accountsSlice;
