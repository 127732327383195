import { createSlice } from '@reduxjs/toolkit';

/*
 *  @desc The transactions slice manages the state of the transactions
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    loading: false,
    error: {
        status: false,
        message: '',
    },
    transactions: {
        items: [],
        total: null,
        page: null,
        size: null,
    },
    account: null,
    simulate: {
        loading: false,
        message: '',
        error: false,
    },
};

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        getTransactions(state) {
            state.loading = true;
            state.error = {
                status: false,
            };
        },
        getTransactionsSuccess(state, action) {
            state.loading = false;
            state.transactions = action.payload;
            state.error = {
                status: false,
            };
        },
        getTransactionsError(state, action) {
            state.loading = false;
            state.error = {
                message: action.payload,
                status: true,
            };
        },
        getAccount: (state, action) => {
            state.account = action.payload ? action.payload : state.account;
            // state.transactions = [];
            state.error = {
                status: false,
                message: '',
            };
        },
        getAccountSuccess: (state, action) => {
            state.account = action.payload ? action.payload : state.account;
        },
        getAccountError: (state) => {
            state.account = null;
        },
        simulateWebhook: (state) => {
            state.simulate.loading = true;
        },
        simulateWebhookRequestSuccess: (state, { payload }) => {
            state.simulate.loading = false;
            state.simulate.message = payload;
        },
        simulateWebhookRequestFailed: (state, { payload }) => {
            state.simulate.loading = false;
            state.simulate.error = true;
            state.simulate.message = payload;
        },
        resetSimulateWebhook: (state) => {
            state.simulate.loading = false;
            state.simulate.error = false;
            state.simulate.message = '';
        },
    },
});

export const { actions, reducer, name } = transactionsSlice;
