import Skeleton from '@mui/material/Skeleton';
import cx from 'classnames';
import { Icon } from 'components/common';
import { AccountDetailsInfo, StyledAccountInfo } from 'components/dashboard/Dashboard';
import React, { useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import selectAccountsState from 'store/selectors/account';
import { actions } from 'store/slice/account';
import { formatAmount, getCurrencySymbol, isQuoteEnabled } from 'utils/helpers';
import styles from 'assets/styles/account.module.scss';
import CurrencyIcon from 'components/common/CurrencyIcon';
import selectCompanyState from 'store/selectors/company';

const AccountInfo = ({ accountId, onRefresh }) => {
    const dispatch = useDispatch();
    const { selectedAccount, loading } = selectAccountsState();
    const { company } = selectCompanyState();

    const [balanceMessage, setBalanceMessage] = useState('');
    const [clipboardContent, setClipboardContent] = useState({
        value: '',
        copied: false,
    });

    useEffect(() => {
        const timer = () =>
            setTimeout(() => setClipboardContent((val) => ({ ...val, copied: false })), 4000);
        const timerId = timer();
        return () => {
            clearTimeout(timerId);
        };
    }, [clipboardContent.copied]);

    const handleRefresh = useCallback(() => {
        if (!loading) {
            dispatch(actions.getSelectedAccount({ accountId }));
            onRefresh();
        }
    }, [dispatch, loading, accountId, onRefresh]);

    const renderBankAddress = (selectedAccount) => {
        if (!selectedAccount) {
            return <></>;
        }
        const address = getBankAddress(selectedAccount);
        const formatAddress = [address.name, address.line1, address.line2, address.city, address.postcode, address.country]
            .filter(x => !!x).join(', ')
        return (
            <div className={cx(styles.bankAccountInfo)}>
                <div>Issuing institution: {formatAddress}</div>
                <div className={cx(styles.tos)}>These funds are held and safeguarded with {address.name} under the terms found <a href={address.tos} target="_blank" rel="noopener noreferrer"> here</a>.</div>
            </div>
        )
    }

    const getBankAddress = (selectedAccount) => {

        const isNeptuneAccount = isQuoteEnabled(selectedAccount);
        if (isNeptuneAccount) {
            if (selectedAccount?.bic === 'SPPVGB2LXXX') {
                return {"name": "Equals Money PLC", "city": "London", "line1": "Vintners Place", "line2": "68 Upper Thames St", "country": "United Kingdom", "postcode": "EC4V 3BJ", "tos": "/pdf/Merge Money Ltd_ T&Cs Equals Money PLC Partner Terms (20240905).pdf"}
            } else if (selectedAccount?.bic === 'RBOSGB2LXXX'){
                return {"name": "The Royal Bank of Scotland", "city": "London", "line1": "62/63 Threadneedle Street", "country": "United Kingdom", "postcode": "EC4V 3BJ", "tos": "/pdf/Merge Money Ltd_ T&Cs Equals Money PLC Partner Terms (20240905).pdf"}
            } else {
                return {"name": "Equals Money PLC", "city": "London", "line1": "Vintners Place", "line2": "68 Upper Thames St", "country": "United Kingdom", "postcode": "EC4V 3BJ", "tos": "/pdf/Merge Money Ltd_ T&Cs Equals Money PLC Partner Terms (20240905).pdf"}
            }
        } else { // Merlin
            if (company?.registration_address[0]?.country === 'GB') {
                return {
                    "name": "Modulr FS Limited",
                    "line1": "Scale Space",
                    "line2": "58 Wood Lane",
                    "city": "London",
                    "postcode": "W12 7RZ",
                    "country": "United Kingdom",
                    "tos": "/pdf/MFSL_Introduced Client_Account Terms and Conditions_safeguarding_23-09-24_UK.pdf"
                }
            } else {
                return {
                    "name": "Modulr Finance B.V.",
                    "line1": "Strawinskylaan 4117",
                    "city": "Amsterdam",
                    "postcode": "1077 ZX",
                    "country": "The Netherlands",
                    "tos": "/pdf/MFBV_Introduced Client_Terms_safeguarding_23-10-23_NL.pdf"
                }
            }
        }
    }

    useEffect(() => {
        dispatch(actions.getSelectedAccount({ accountId }));
    }, [dispatch, accountId]);

    useEffect(() => {
        if (selectedAccount === null) {
            return;
        }
        var toCopy = selectedAccount.account_name + '\n';
        if (selectedAccount.account_number) {
            toCopy += `Account number: ${selectedAccount.account_number}\n`;
        }
        if (selectedAccount.sort_code) {
            toCopy += `Sort code: ${selectedAccount.sort_code}\n`;
        }
        if (selectedAccount.iban) {
            toCopy += `IBAN: ${selectedAccount.iban}\n`;
        }
        if (selectedAccount.bic) {
            toCopy += `BIC: ${selectedAccount.bic}\n`;
        }
        toCopy = toCopy.trim();
        setClipboardContent((val) => ({ ...val, value: toCopy }));

        setBalanceMessage(
            `The ${selectedAccount?.currency} balance as of now for account ${
                selectedAccount?.account_number || selectedAccount?.iban
            }`
        );
    }, [selectedAccount]);

    return (
        <div className={cx(styles.accountInfo)}>
            <div className={cx(styles.balance)}>
                <div className={styles.refresh}>
                    <h3>Total balance</h3>
                    <Icon name={'refresh'} onClick={handleRefresh} />
                </div>
                {selectedAccount ? (
                    <h2>
                        {getCurrencySymbol(selectedAccount?.currency)}
                        <NumberFormat
                            value={formatAmount(selectedAccount?.balance)}
                            thousandSeparator={true}
                            displayType={'text'}
                            decimalScale={2}
                        />
                    </h2>
                ) : loading ? (
                    <h2>
                        <Skeleton animation={'pulse'} width={'50%'} height={'100%'} />
                    </h2>
                ) : (
                    '0.00'
                )}
                <h5>
                    <CurrencyIcon rounded currency={selectedAccount?.currency} height="20px" />{' '}
                    {balanceMessage}{' '}
                </h5>
            </div>
            <div className={cx(styles.copy)}>
                <StyledAccountInfo style={{ minHeight: '60px', float: 'right' }}>
                    <div>
                        <CopyToClipboard text={clipboardContent.value}>
                            <Icon
                                name={'colored-copy'}
                                style={{ float: 'right' }}
                                onClick={() =>
                                    setClipboardContent((val) => ({
                                        ...val,
                                        copied: true,
                                    }))
                                }
                                className={cx(styles.copyIcon)}
                            />
                        </CopyToClipboard>

                        <AccountDetailsInfo account={selectedAccount} />
                        {renderBankAddress(selectedAccount)}
                        {clipboardContent.copied && (
                            <span className={cx(styles.copied)}>Copied!</span>
                        )}
                    </div>
                </StyledAccountInfo>
            </div>
        </div>
    );
};

export default AccountInfo;
