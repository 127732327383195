import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { Divider, Grid, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/slice/reports';
import SelectAccountV2 from 'components/common/SelectInput/SelectAccountV2';
import SelectMonth from 'components/common/SelectInput/SelectMonth';
import styles from 'assets/styles/reports.module.scss';
import DialogV2 from 'components/common/DialogV2';
import { selectMASReports } from 'store/selectors/reports';
import { failureQueueTitle, successQueueTitle } from './constant';
import {
    getCalendarYearMonths,
    getCalendarYears,
    getPrevMonthDate,
    getPrevMonthDateFormat,
} from 'components/common/SelectInput/utils';
import moment from 'moment';
import { ButtonV2 } from 'components/common/Button';

const GenerateMAS = ({ setTab }) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { message, success, error, loading } = useSelector(selectMASReports);
    const validationSchema = yup.object({
        fromAccount: yup.object().required('account is required'),
        calendarMonth: yup.object({
            year: yup.string('Select the year').required('Year is required'),
            month: yup.string('Select the month').required('Month is required'),
        }),
    });
    const initialValues = {
        fromAccount: null,
        calendarMonth: {
            year: getPrevMonthDateFormat('YYYY'),
            month: getPrevMonthDateFormat('MMMM'),
        },
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
    });
    const { id: fromAccountId, created_at: accountCreatedAt } = formik.values.fromAccount ?? {
        id: null,
        created_at: null,
    };
    const hasAccount = Boolean(fromAccountId);
    const yearOptions = useMemo(
        () =>
            getCalendarYears({
                startDate: accountCreatedAt ? moment(accountCreatedAt) : getPrevMonthDate(),
                endDate: moment().startOf('month'),
            }),
        [accountCreatedAt]
    );
    const monthOptions = useMemo(
        () =>
            getCalendarYearMonths({
                startDate: accountCreatedAt ? moment(accountCreatedAt) : getPrevMonthDate(),
                endDate: moment().startOf('month'),
                year: formik.values.calendarMonth.year,
            }),
        [accountCreatedAt, formik.values.calendarMonth.year]
    );

    useEffect(() => {
        if (searchParams.get('from')) {
            const accountId = searchParams.get('from');
            formik.setValues((data) => ({
                ...data,
                fromAccount: {
                    id: accountId,
                },
            }));

            searchParams.delete('from');
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    const onGenerateClick = () => {
        const { fromAccount, calendarMonth } = formik.values;
        const { month, year } = calendarMonth;
        const abbreviatedMonth = moment().month(month).format('MMM').toUpperCase();
        const generateData = {
            accountId: fromAccount.id,
            month: abbreviatedMonth,
            year,
        };
        dispatch(actions.generateMonthlyStatement(generateData));
    };

    const handleChange = (name, value) => {
        formik.setValues((data) => ({ ...data, [name]: value }));
    };

    const clearValues = () => {
        formik.setValues(() => initialValues);
    };

    useEffect(() => {
        dispatch(actions.resetMASQueue());
    }, [dispatch]);

    return (
        <>
            <Grid container className={cx(styles.filter, styles.queueTab)}>
                <form onSubmit={formik.handleSubmit} className={cx(styles.paymentInputContainer)}>
                    <div>
                        <div className={cx(styles.paymentAccounts)}>
                            <div className={cx(styles.paymentInputSelect)}>
                                <SelectAccountV2
                                    name={'fromAccount'}
                                    label="Select account"
                                    value={fromAccountId}
                                    required={true}
                                    onChange={handleChange}
                                    showBalance={true}
                                />
                            </div>
                            <div className={cx(styles.paymentInputSelect)}>
                                <SelectMonth
                                    name="calendarMonth"
                                    label="Select month"
                                    value={formik.values.calendarMonth}
                                    required={true}
                                    monthOptions={monthOptions}
                                    yearOptions={yearOptions}
                                    onDateChange={handleChange}
                                    minDate={accountCreatedAt}
                                    disable={!hasAccount}
                                />
                            </div>
                        </div>
                    </div>
                    <Container maxWidth="md" className={styles.dividerContainer}>
                        <Divider className={styles.divider} />
                    </Container>
                    <div className={cx(styles.pageActions)}>
                        <div className={cx(styles.actionBtn)}>
                            <ButtonV2
                                text={'Clear'}
                                variant="secondary"
                                // variant='outlined'
                                onClick={clearValues}
                                disabled={!hasAccount}
                            />
                        </div>
                        <div className={cx(styles.actionBtn)}>
                            <ButtonV2
                                onClick={() => onGenerateClick()}
                                text={loading ? 'Queuing...' : 'Queue MAS'}
                                disabled={loading || !hasAccount}
                            />
                        </div>
                    </div>
                </form>
            </Grid>
            <DialogV2
                title={success ? successQueueTitle : failureQueueTitle}
                open={success || error}
                isSingleButton={error}
                setOpen={() => dispatch(actions.resetMASQueue())}
                submitButton={(success && 'Go to Downloads') || (error && 'Close')}
                variant={(success && 'success') || (error && 'error')}
                submitAction={() => {
                    if (success) {
                        setTab(1);
                    }
                    dispatch(actions.resetMASQueue());
                }}
                cancelAction={() => dispatch(actions.resetMASQueue())}>
                {message}
            </DialogV2>
        </>
    );
};

export default GenerateMAS;
