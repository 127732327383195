import React from 'react';
import moment from 'moment';
import { styled } from '@mui/system';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import styles from 'assets/styles/reports.module.scss';
import { CustomTooltip, Icon } from 'components/common';
import CurrencyIcon from 'components/common/CurrencyIcon';
import DownloadButton from 'components/common/DownloadButton';
import DialogV2 from 'components/common/DialogV2';
import { selectMASReports } from 'store/selectors/reports';
import { actions } from 'store/slice/reports';
import {
    successDownloadTitle,
    failureDownloadTitle,
    successDownloadContent,
    failureDownloadContent,
} from './constant';

const TableHeaderRow = styled(TableRow)(() => ({
    backgroundColor: 'rgba(0,160,155,0.19)',
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
        backgroundColor: 'rgba(0,160,155,0.05)',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const renderStatusIcon = (status, download_url, filename, expires_at) => {
    const expiresAt = moment(expires_at);
    if (moment().isAfter(expiresAt, 'second')) {
        status = 'expired';
    }

    switch (status) {
        case 'available':
            return (
                <DownloadButton url={download_url} filename={filename} hoverInfo={'Download MAS'} />
            );
        case 'processing':
            return (
                <CustomTooltip info={'MAS processing'}>
                    <Icon name={'download-processing'} disabled={true} />
                </CustomTooltip>
            );
        case 'expired':
            return (
                <CustomTooltip info={'MAS expired'}>
                    <Icon name={'download-expired'} disabled={true} />
                </CustomTooltip>
            );
        default:
            return null;
    }
};

export default function MASReportsList({ reportsList }) {
    const dispatch = useDispatch();
    const { isDownloading } = useSelector(selectMASReports);
    const { success, error } = isDownloading;

    return (
        <>
            <Grid item md={12} xs={12} className={cx(styles.list)}>
                <TableContainer className={cx(styles.table)}>
                    <Table>
                        <TableHead>
                            <TableHeaderRow>
                                <TableCell>Job ID</TableCell>
                                <TableCell width="290px">Account name</TableCell>
                                <TableCell>Month</TableCell>
                                <TableCell>Year</TableCell>
                                <TableCell>Requested on</TableCell>
                                <TableCell>Requested by</TableCell>
                                <TableCell>Status</TableCell>
                            </TableHeaderRow>
                        </TableHead>
                        <TableBody>
                            {reportsList?.length > 0 &&
                                reportsList.map(
                                    ({
                                        alias_id,
                                        account_holder_name,
                                        iban,
                                        bic,
                                        account_number,
                                        sort_code,
                                        currency,
                                        month,
                                        year,
                                        requested_at,
                                        expires_at,
                                        requested_by,
                                        status,
                                        download_url,
                                        filename,
                                    }) => (
                                        <StyledTableRow key={alias_id}>
                                            <TableCell>{alias_id}</TableCell>
                                            <TableCell>
                                                <div className={cx(styles.accountName)}>
                                                    <CurrencyIcon currency={currency} />
                                                    <p>{account_holder_name}</p>
                                                </div>
                                                {account_number && (
                                                    <div>
                                                        <b>Acc#: </b> {account_number}
                                                    </div>
                                                )}
                                                {sort_code && (
                                                    <div>
                                                        <b>Sort code: </b> {sort_code}
                                                    </div>
                                                )}
                                                {iban && (
                                                    <div>
                                                        <b>IBAN: </b> {iban}
                                                    </div>
                                                )}
                                                {bic && (
                                                    <div>
                                                        <b>BIC: </b> {bic}
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell>{month}</TableCell>
                                            <TableCell>{year}</TableCell>
                                            <TableCell>
                                                {moment(requested_at).format('DD MMM YYYY')}
                                            </TableCell>
                                            <TableCell>{requested_by}</TableCell>
                                            <TableCell className={styles.iconInfo}>
                                                {renderStatusIcon(
                                                    status,
                                                    download_url,
                                                    filename,
                                                    expires_at
                                                )}
                                            </TableCell>
                                        </StyledTableRow>
                                    )
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <DialogV2
                title={(success && successDownloadTitle) || (error && failureDownloadTitle)}
                open={success || error}
                isSingleButton={true}
                submitButton={(success && 'Okay') || (error && 'Close')}
                variant={(success && 'success') || (error && 'error')}
                setOpen={() => dispatch(actions.resetDownload())}
                submitAction={() => dispatch(actions.resetDownload())}>
                {success && successDownloadContent}
                {error && failureDownloadContent}
            </DialogV2>
        </>
    );
}
