import { combineReducers } from 'redux';
import auth from './auth';
import { reducer as companyReducer } from './company';
import { reducer as recipientsReducer } from './recipients';
import { reducer as fundsReducer } from './funds';
import { reducer as appReducer } from 'AppSlice';
import { reducer as dashboardReducer } from './dashboard';
import { reducer as transactionReducer } from './transactions';
import { reducer as commonReducer } from './common';
import { reducer as globalReducer } from './global';
import { reducer as accountsReducer } from './account';
import { reducer as usersReducer } from './userManagement';
import { reducer as queuepaymentsReducer } from './queuepayments';
import { reducer as paymentsReducer } from './pendingpayments';
import { reducer as profileReducer } from './profile';
import { reducer as reportReducer } from './reports';
import { reducer as developerReducer } from './developer';
import { reducer as userReducer } from './user';
import { reducer as contentReducer } from 'components/conditions/slice';

const RESET_STORE = '@AUTH/RESET_STORE';

const RESTART_APP = '@APP/RESTART_APP';

export const clearState = () => ({
    type: RESET_STORE,
});

export const restartApp = () => ({
    type: RESTART_APP,
});

const storeReducer = combineReducers({
    app: appReducer,
    auth: auth,
    common: commonReducer,
    global: globalReducer,
    dashboard: dashboardReducer,
    user: userReducer,
    company: companyReducer,
    recipients: recipientsReducer,
    funds: fundsReducer,
    transactions: transactionReducer,
    accounts: accountsReducer,
    users: usersReducer,
    queuepayments: queuepaymentsReducer,
    payments: paymentsReducer,
    profile: profileReducer,
    developer: developerReducer,
    reports: reportReducer,
    content: contentReducer,
});

const rootReducer = (state, action) => {
    if (action.type === RESET_STORE) {
        localStorage.clear();
        return storeReducer(undefined, action);
    }
    if (action.type === RESTART_APP) {
        const updateState = {
            ...state.auth,
        };
        return storeReducer(updateState, action);
    }
    return storeReducer(state, action);
};

export default rootReducer;
