export const masReportsStatus = [
    {
        label: 'Available',
        value: 'available',
    },
    {
        label: 'Processing',
        value: 'processing',
    },
];

export const successDownloadTitle = 'MAS download is successful';
export const failureDownloadTitle = 'MAS download failed';

export const successDownloadContent = 'MAS is downloaded successfully.';
export const failureDownloadContent =
    'MAS was not downloaded successfully. Please retry in a few minutes or contact cs@merge.money if the issue persists.';

export const successQueueTitle = 'MAS queued successfully';
export const failureQueueTitle = 'MAS was not queued';
