export const paymentPurposeTypes = [
    {
        "value": "MNEANYMAD340",
        "default_purpose_code": "340",
        "default_purpose": "Insurance",
        "additional_purpose": "Ancillary insurance costs",
        "label": "340 - Insurance ; Ancillary insurance costs",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYBRL12005",
        "default_purpose_code": "12005",
        "default_purpose": "Import of goods",
        "additional_purpose": "Importation payment",
        "label": "12005 - Import of goods ; Importation payment",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYBRL22136",
        "default_purpose_code": "22136",
        "default_purpose": "Services",
        "additional_purpose": "Sea transportation",
        "label": "22136 - Services ; Sea transportation",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYBRL47025",
        "default_purpose_code": "47025",
        "default_purpose": "Services",
        "additional_purpose": "IT services",
        "label": "47025 - Services ; IT services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYBRL47128",
        "default_purpose_code": "47128",
        "default_purpose": "Services",
        "additional_purpose": "Legal services",
        "label": "47128 - Services ; Legal services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYBRL47135",
        "default_purpose_code": "47135",
        "default_purpose": "Services",
        "additional_purpose": "Audit, accounting and tax consulting",
        "label": "47135 - Services ; Audit, accounting and tax consulting",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYBRL47142",
        "default_purpose_code": "47142",
        "default_purpose": "Services",
        "additional_purpose": "Business and public relations consulting services",
        "label": "47142 - Services ; Business and public relations consulting services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYBRL47197",
        "default_purpose_code": "47197",
        "default_purpose": "Services",
        "additional_purpose": "Other technical, professional and administrative services",
        "label": "47197 - Services ; Other technical, professional and administrative services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYBRL47609",
        "default_purpose_code": "47609",
        "default_purpose": "Purchase of a property or goods",
        "additional_purpose": "Commissions or expenses on trade transactions",
        "label": "47609 - Purchase of a property or goods ; Commissions or expenses on trade transactions",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYBRL47719",
        "default_purpose_code": "47719",
        "default_purpose": "Services",
        "additional_purpose": "Education services",
        "label": "47719 - Services ; Education services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYBRL47733",
        "default_purpose_code": "47733",
        "default_purpose": "Services",
        "additional_purpose": "Health services",
        "label": "47733 - Services ; Health services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYBRL47740",
        "default_purpose_code": "47740",
        "default_purpose": "Services",
        "additional_purpose": "Tourism services",
        "label": "47740 - Services ; Tourism services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYBRL47908",
        "default_purpose_code": "47908",
        "default_purpose": "Other purposes",
        "additional_purpose": "Salary and other compensation",
        "label": "47908 - Other purposes ; Salary and other compensation",
        "type": "default_purpose"
    },
    {
        "value": "MNEJOANY0203",
        "default_purpose_code": "0203",
        "default_purpose": "Salaries and wages",
        "additional_purpose": "Private sector staff salaries",
        "label": "0203 - Salaries and wages ; Private sector staff salaries",
        "type": "default_purpose"
    },
    {
        "value": "MNEJOANY0209",
        "default_purpose_code": "0209",
        "default_purpose": "Salaries and wages",
        "additional_purpose": "Establishment social security subscription",
        "label": "0209 - Salaries and wages ; Establishment social security subscription",
        "type": "default_purpose"
    },
    {
        "value": "MNEJOANY0404",
        "default_purpose_code": "0404",
        "default_purpose": "Transportation and tourism",
        "additional_purpose": "Travel and tourism",
        "label": "0404 - Transportation and tourism ; Travel and tourism",
        "type": "default_purpose"
    },
    {
        "value": "MNEJOANY0504",
        "default_purpose_code": "0504",
        "default_purpose": "Training and delegation",
        "additional_purpose": "Private sector education",
        "label": "0504 - Training and delegation ; Private sector education",
        "type": "default_purpose"
    },
    {
        "value": "MNEJOANY0801",
        "default_purpose_code": "0801",
        "default_purpose": "Services",
        "additional_purpose": "Telecommunication services",
        "label": "0801 - Services ; Telecommunication services",
        "type": "default_purpose"
    },
    {
        "value": "MNEJOANY0802",
        "default_purpose_code": "0802",
        "default_purpose": "Services",
        "additional_purpose": "Financial services",
        "label": "0802 - Services ; Financial services",
        "type": "default_purpose"
    },
    {
        "value": "MNEJOANY0803",
        "default_purpose_code": "0803",
        "default_purpose": "Services",
        "additional_purpose": "Information Technology services",
        "label": "0803 - Services ; Information Technology services",
        "type": "default_purpose"
    },
    {
        "value": "MNEJOANY0804",
        "default_purpose_code": "0804",
        "default_purpose": "Services",
        "additional_purpose": "Consulting services",
        "label": "0804 - Services ; Consulting services",
        "type": "default_purpose"
    },
    {
        "value": "MNEJOANY0807",
        "default_purpose_code": "0807",
        "default_purpose": "Services",
        "additional_purpose": "Marketing and media services",
        "label": "0807 - Services ; Marketing and media services",
        "type": "default_purpose"
    },
    {
        "value": "MNEJOANY0811",
        "default_purpose_code": "0811",
        "default_purpose": "Services",
        "additional_purpose": "Rental expenses",
        "label": "0811 - Services ; Rental expenses",
        "type": "default_purpose"
    },
    {
        "value": "MNEJOANY0813",
        "default_purpose_code": "0813",
        "default_purpose": "Services",
        "additional_purpose": "Taxes",
        "label": "0813 - Services ; Taxes",
        "type": "default_purpose"
    },
    {
        "value": "MNEJOANY0814",
        "default_purpose_code": "0814",
        "default_purpose": "Services",
        "additional_purpose": "Fees",
        "label": "0814 - Services ; Fees",
        "type": "default_purpose"
    },
    {
        "value": "MNEJOANY0815",
        "default_purpose_code": "0815",
        "default_purpose": "Services",
        "additional_purpose": "Commissions",
        "label": "0815 - Services ; Commissions",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD1042",
        "default_purpose_code": "1042",
        "default_purpose": "Revenues",
        "additional_purpose": "Income from direct investments",
        "label": "1042 - Revenues ; Income from direct investments",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD1100",
        "default_purpose_code": "1100",
        "default_purpose": "Revenues",
        "additional_purpose": "Dividends and distributed profits",
        "label": "1100 - Revenues ; Dividends and distributed profits",
        "type": "default_purpose"
    },
    {
        "value": "MNEKEKES1202",
        "default_purpose_code": "1202",
        "default_purpose": "VAT imports",
        "additional_purpose": "",
        "label": "1202 - VAT imports",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD1210",
        "default_purpose_code": "1210",
        "default_purpose": "Current transfers",
        "additional_purpose": "Pensions, retirement benefits and family allowances",
        "label": "1210 - Current transfers ; Pensions, retirement benefits and family allowances",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD1220",
        "default_purpose_code": "1220",
        "default_purpose": "Current transfers",
        "additional_purpose": "Contributions to pension funds or provident schemes",
        "label": "1220 - Current transfers ; Contributions to pension funds or provident schemes",
        "type": "default_purpose"
    },
    {
        "value": "MNEKEKES1519",
        "default_purpose_code": "1519",
        "default_purpose": "Registration fees",
        "additional_purpose": "",
        "label": "1519 - Registration fees",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD180",
        "default_purpose_code": "180",
        "default_purpose": "Goods",
        "additional_purpose": "International trade",
        "label": "180 - Goods ; International trade",
        "type": "default_purpose"
    },
    {
        "value": "MNEKEKES2901",
        "default_purpose_code": "2901",
        "default_purpose": "Income tax - PAYE",
        "additional_purpose": "",
        "label": "2901 - Income tax - PAYE",
        "type": "default_purpose"
    },
    {
        "value": "MNEKEKES3001",
        "default_purpose_code": "3001",
        "default_purpose": "Income tax - company",
        "additional_purpose": "",
        "label": "3001 - Income tax - company",
        "type": "default_purpose"
    },
    {
        "value": "MNEKEKES3514",
        "default_purpose_code": "3514",
        "default_purpose": "Value Added Tax (VAT)",
        "additional_purpose": "",
        "label": "3514 - Value Added Tax (VAT)",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD440",
        "default_purpose_code": "440",
        "default_purpose": "Travel",
        "additional_purpose": "Tuition fees",
        "label": "440 - Travel ; Tuition fees",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD441",
        "default_purpose_code": "441",
        "default_purpose": "Travel",
        "additional_purpose": "Students’ living expenses",
        "label": "441 - Travel ; Students’ living expenses",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD442",
        "default_purpose_code": "442",
        "default_purpose": "Travel",
        "additional_purpose": "Rents",
        "label": "442 - Travel ; Rents",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD510",
        "default_purpose_code": "510",
        "default_purpose": "Business services",
        "additional_purpose": "Administrative procurement",
        "label": "510 - Business services ; Administrative procurement",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD560",
        "default_purpose_code": "560",
        "default_purpose": "Business services",
        "additional_purpose": "IT services",
        "label": "560 - Business services ; IT services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD570",
        "default_purpose_code": "570",
        "default_purpose": "Business services",
        "additional_purpose": "Information services",
        "label": "570 - Business services ; Information services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD585",
        "default_purpose_code": "585",
        "default_purpose": "Business services",
        "additional_purpose": "Research and development",
        "label": "585 - Business services ; Research and development",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD590",
        "default_purpose_code": "590",
        "default_purpose": "Business services",
        "additional_purpose": "Consulting, advertising and market research services",
        "label": "590 - Business services ; Consulting, advertising and market research services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD595",
        "default_purpose_code": "595",
        "default_purpose": "Business services",
        "additional_purpose": "Various business, specialist and technical services",
        "label": "595 - Business services ; Various business, specialist and technical services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD600",
        "default_purpose_code": "600",
        "default_purpose": "Communication services",
        "additional_purpose": "Postal services",
        "label": "600 - Communication services ; Postal services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD610",
        "default_purpose_code": "610",
        "default_purpose": "Communication services",
        "additional_purpose": "Mail and courier services",
        "label": "610 - Communication services ; Mail and courier services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYMAD620",
        "default_purpose_code": "620",
        "default_purpose": "Communication services",
        "additional_purpose": "Telecommunication services",
        "label": "620 - Communication services ; Telecommunication services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYCNYCCTFDR",
        "default_purpose_code": "CCTFDR",
        "default_purpose": "Payment to capital account",
        "additional_purpose": "Capital injection, capital reduction, capital payment, investment, shareholder loan, non-trade fund transfer, other capital payments as approved by relevant regulatory authorities",
        "label": "CCTFDR - Payment to capital account ; Capital injection, capital reduction, capital payment, investment, shareholder loan, non-trade fund transfer, other capital payments as approved by relevant regulatory authorities",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYCNYCCTFDR/PART",
        "default_purpose_code": "CCTFDR/PART",
        "default_purpose": "Payment to capital account",
        "additional_purpose": "Partial return of payment to capital account",
        "label": "CCTFDR/PART - Payment to capital account ; Partial return of payment to capital account",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYCNYCCTFDR/RETN",
        "default_purpose_code": "CCTFDR/RETN",
        "default_purpose": "Payment to capital account",
        "additional_purpose": "Cancellation of payment to capital account",
        "label": "CCTFDR/RETN - Payment to capital account ; Cancellation of payment to capital account",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYCNYCGODDR",
        "default_purpose_code": "CGODDR",
        "default_purpose": "Trade settlement for goods",
        "additional_purpose": "Sale or purchase of goods, import or export of goods, procurement of goods, letter of credit or documentary collection related to goods trade, prepayment of goods, etc.",
        "label": "CGODDR - Trade settlement for goods ; Sale or purchase of goods, import or export of goods, procurement of goods, letter of credit or documentary collection related to goods trade, prepayment of goods, etc.",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYCNYCGODDR/PART",
        "default_purpose_code": "CGODDR/PART",
        "default_purpose": "Trade settlement for goods",
        "additional_purpose": "Partial return of trade settlement for goods",
        "label": "CGODDR/PART - Trade settlement for goods ; Partial return of trade settlement for goods",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYCNYCGODDR/RETN",
        "default_purpose_code": "CGODDR/RETN",
        "default_purpose": "Trade settlement for goods",
        "additional_purpose": "Cancellation of trade settlement for goods",
        "label": "CGODDR/RETN - Trade settlement for goods ; Cancellation of trade settlement for goods",
        "type": "default_purpose"
    },
    {
        "value": "MNEAEANYCOM",
        "default_purpose_code": "COM",
        "default_purpose": "Commission",
        "additional_purpose": "",
        "label": "COM - Commission",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYCNYCSTRDR",
        "default_purpose_code": "CSTRDR",
        "default_purpose": "Trade settlement for services",
        "additional_purpose": "Public utility, rent, audit fee, hotel and accommodation fee, legal fee, advertising and promotion fee, copyright and license fee, wages, service contract fee, design fee, research and development fee, registration fee, medical expenses, etc.",
        "label": "CSTRDR - Trade settlement for services ; Public utility, rent, audit fee, hotel and accommodation fee, legal fee, advertising and promotion fee, copyright and license fee, wages, service contract fee, design fee, research and development fee, registration fee, medical expenses, etc.",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYCNYCSTRDR/PART",
        "default_purpose_code": "CSTRDR/PART",
        "default_purpose": "Trade settlement for services",
        "additional_purpose": "Partial return of trade settlement for services",
        "label": "CSTRDR/PART - Trade settlement for services ; Partial return of trade settlement for services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYCNYCSTRDR/RETN",
        "default_purpose_code": "CSTRDR/RETN",
        "default_purpose": "Trade settlement for services",
        "additional_purpose": "Cancellation of trade settlement for services",
        "label": "CSTRDR/RETN - Trade settlement for services ; Cancellation of trade settlement for services",
        "type": "default_purpose"
    },
    {
        "value": "MNEAEANYDIV",
        "default_purpose_code": "DIV",
        "default_purpose": "Dividend payouts",
        "additional_purpose": "",
        "label": "DIV - Dividend payouts",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYINRP1004",
        "default_purpose_code": "P1004",
        "default_purpose": "Other business services",
        "additional_purpose": "Legal services",
        "label": "P1004 - Other business services ; Legal services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYINRP1005",
        "default_purpose_code": "P1005",
        "default_purpose": "Other business services",
        "additional_purpose": "Accounting, auditing, bookkeeping and tax consulting services",
        "label": "P1005 - Other business services ; Accounting, auditing, bookkeeping and tax consulting services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYINRP1007",
        "default_purpose_code": "P1007",
        "default_purpose": "Other business services",
        "additional_purpose": "Advertising, trade fair, market research and public opinion polling services",
        "label": "P1007 - Other business services ; Advertising, trade fair, market research and public opinion polling services",
        "type": "default_purpose"
    },
    {
        "value": "MNEANYINRP1019",
        "default_purpose_code": "P1019",
        "default_purpose": "Other business services",
        "additional_purpose": "Other services not included elsewhere",
        "label": "P1019 - Other business services ; Other services not included elsewhere",
        "type": "default_purpose"
    },
    {
        "value": "MNEKEKESPAYE",
        "default_purpose_code": "PAYE",
        "default_purpose": "Pay As You Earn (PAYE)",
        "additional_purpose": "",
        "label": "PAYE - Pay As You Earn (PAYE)",
        "type": "default_purpose"
    },
    {
        "value": "MNEAEANYPEN",
        "default_purpose_code": "PEN",
        "default_purpose": "Pension",
        "additional_purpose": "",
        "label": "PEN - Pension",
        "type": "default_purpose"
    },
    {
        "value": "MNEAEANYSAL",
        "default_purpose_code": "SAL",
        "default_purpose": "Salary",
        "additional_purpose": "",
        "label": "SAL - Salary",
        "type": "default_purpose"
    },
    {
        "value": "MNEBHANYSAL",
        "default_purpose_code": "SAL",
        "default_purpose": "Personal",
        "additional_purpose": "Salary (compensation of employees)",
        "label": "SAL - Personal ; Salary (compensation of employees)",
        "type": "default_purpose"
    },
    {
        "value": "MNEKEKESSALA",
        "default_purpose_code": "SALA",
        "default_purpose": "Salary payment",
        "additional_purpose": "",
        "label": "SALA - Salary payment",
        "type": "default_purpose"
    },
    {
        "value": "MNEBHANYSTR",
        "default_purpose_code": "STR",
        "default_purpose": "Transport and travel",
        "additional_purpose": "Travel",
        "label": "STR - Transport and travel ; Travel",
        "type": "default_purpose"
    },
    {
        "value": "MNEAEANYTAX",
        "default_purpose_code": "TAX",
        "default_purpose": "Tax payment",
        "additional_purpose": "",
        "label": "TAX - Tax payment",
        "type": "default_purpose"
    },
    {
        "value": "MNEKEKESTAXS",
        "default_purpose_code": "TAXS",
        "default_purpose": "Tax payment",
        "additional_purpose": "",
        "label": "TAXS - Tax payment",
        "type": "default_purpose"
    },
    {
        "value": "MNEKEKESTBIL",
        "default_purpose_code": "TBIL",
        "default_purpose": "Telecommunications bill",
        "additional_purpose": "",
        "label": "TBIL - Telecommunications bill",
        "type": "default_purpose"
    },
    {
        "value": "MNEKEKESUBIL",
        "default_purpose_code": "UBIL",
        "default_purpose": "Utilities",
        "additional_purpose": "",
        "label": "UBIL - Utilities",
        "type": "default_purpose"
    },
    {
        "value": "MNEAEANYUTL",
        "default_purpose_code": "UTL",
        "default_purpose": "Utility bill payments",
        "additional_purpose": "",
        "label": "UTL - Utility bill payments",
        "type": "default_purpose"
    },
    {
        "value": "MNEKEKESVATX",
        "default_purpose_code": "VATX",
        "default_purpose": "Value Added Tax (VAT) payment",
        "additional_purpose": "",
        "label": "VATX - Value Added Tax (VAT) payment",
        "type": "default_purpose"
    }
];
