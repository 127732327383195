import React from 'react';
import PropTypes from 'prop-types';
import { getUserTypes } from 'store/selectors/auth';
import { useSelector } from 'react-redux';
import { belongsTo } from 'utils/auth';

const WithPermission = ({ children, containsTypes }) => {
    const userTypes = useSelector(getUserTypes);
    if (belongsTo(userTypes, containsTypes)) {
        return <>{children}</>;
    }
    return (
        <p style={{ marginTop: '30px', padding: '20px 30px' }}>
            You do not have permission to access this feature. Please reach out to your
            administrator to request access to this feature.
        </p>
    );
};

export default WithPermission;

WithPermission.propTypes = {
    children: PropTypes.node.isRequired,
    containsTypes: PropTypes.array.isRequired,
};
