import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import Img from 'assets/images/merge-logo.png';
import MerlinData from './MerlinData';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: '20px 20px 100px 20px',
    },
    logo: {
        margin: '20px',
        width: '120px',
        height: '25px',
    },
    pageNumbers: {
        fontSize: '9px',
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    }    
});

const headerStyles = StyleSheet.create({
    row: {
        fontSize: '10px',
        fontFamily: 'Helvetica-Bold',
        flexDirection: 'row',
        textAlign: 'center',
        alignItems: 'center',
        width: '100%',
    },
    cell: {
        padding: '5px',
        border: '1px solid #ccc',
        borderRight: '0px solid #ccc',
        height: 'auto',
        minHeight: '10px',
    },
    transaction_id: {
        width: '6%',
    },
    status: {
        width: '3%',
    },
    type: {
        width: '2%',
    },
    account_number: {
        width: '6%',
    },
    sort_code: {
        width: '5%',
    },
    account_type: {
        width: '3%',
    },
    description: {
        width: '14%',
    },
    source: {
        width: '5%',
    },
    destination: {
        width: '5%',
    },
    amount: {
        width: '5%',
    },
    currency: {
        width: '3%',
    },
    paymentDate: {
        width: '6%',
    },
    createdAt: {
        width: '6%',
    },
    statusReason: {
        width: '3%',
    },
    statusNote: {
        width: '7%',
        borderRight: '1px solid #ccc',
    },
});

// Create Document Component
const MerlinReport = ({ data }) => (
    <Document>
        <Page size="A0" orientation="landscape" style={styles.page} wrap>
            <View fixed>
                <Image style={styles.logo} src={Img} cache={false} />
            </View>
            <View fixed>
                <View style={headerStyles.row} key={'header'}>
                    <Text style={[headerStyles.transaction_id, headerStyles.cell]}>
                        Transaction ID
                    </Text>
                    <Text style={[headerStyles.transaction_id, headerStyles.cell]}>Account ID</Text>
                    <Text style={[headerStyles.account_type, headerStyles.cell]}>Account type</Text>
                    <Text style={[headerStyles.status, headerStyles.cell]}>Status</Text>
                    <Text style={[headerStyles.paymentDate, headerStyles.cell]}>Payment date</Text>
                    <Text style={[headerStyles.type, headerStyles.cell]}>Type</Text>
                    <Text style={[headerStyles.description, headerStyles.cell]}>Reference</Text>
                    <Text style={[headerStyles.source, headerStyles.cell]}>Source name</Text>
                    <Text style={[headerStyles.account_number, headerStyles.cell]}>
                        {data[0]?.currency === 'GBP' ? 'Source Acc no' : 'Source IBAN'}
                    </Text>
                    <Text style={[headerStyles.sort_code, headerStyles.cell]}>
                        {data[0]?.currency === 'GBP' ? 'Source Sort code' : 'Source BIC'}
                    </Text>
                    <Text style={[headerStyles.destination, headerStyles.cell]}>
                        Destination name
                    </Text>
                    <Text style={[headerStyles.account_number, headerStyles.cell]}>
                        {data[0]?.currency === 'GBP' ? 'Destination Acc no' : 'Destination IBAN'}
                    </Text>
                    <Text style={[headerStyles.sort_code, headerStyles.cell]}>
                        {data[0]?.currency === 'GBP' ? 'Destination Sort code' : 'Destination BIC'}
                    </Text>
                    <Text style={[headerStyles.amount, headerStyles.cell]}>Amount</Text>
                    <Text style={[headerStyles.currency, headerStyles.cell]}>Currency</Text>
                    <Text style={[headerStyles.createdAt, headerStyles.cell]}>Creation time</Text>
                    <Text style={[headerStyles.statusReason, headerStyles.cell]}>
                        Status Reason
                    </Text>
                    <Text style={[headerStyles.statusNote, headerStyles.cell]}>Status Note</Text>
                </View>
            </View>
            <View style={styles.tableContainer}>
                <MerlinData items={data} />
            </View>
            <Text
                style={styles.pageNumbers}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed></Text>
        </Page>
    </Document>
);

export default MerlinReport;
