import { createSelector } from '@reduxjs/toolkit';
import { initialState } from 'store/slice/global';
import { useSelector } from 'react-redux';

const selectDomain = (state) => state.global || initialState;

const select = () => createSelector([selectDomain], (state) => state);

/* eslint-disable */
export default () => useSelector(select());
