import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { Loading, Pagination } from 'components/common';
import { selectMASReports } from 'store/selectors/reports';
import { actions } from 'store/slice/reports';
import styles from 'assets/styles/reports.module.scss';
import MASReportsList from './MASReportsList';
import { Grid } from '@mui/material';
import MASReportsFilter from './MASReportsFilter';

export default function MASReports() {
    const dispatch = useDispatch();
    const [params, setParams] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const selectMASReportsList = useSelector(selectMASReports);
    const { reportsList = [], loading, error } = selectMASReportsList;

    const handleFilter = useCallback(
        (searchParams, page = 1, size = pageSize) => {
            setParams(searchParams);
            dispatch(actions.fetchMASReports({ page, size, ...searchParams }));
        },
        [dispatch, pageSize]
    );

    const handlePageChange = useCallback(
        (page, size) => {
            handleFilter(params, page, size);
        },
        [handleFilter, params]
    );

    const handlePerPageChange = useCallback(
        (size) => {
            setPageSize(size);
            handleFilter(params, 1, size);
        },
        [handleFilter, params]
    );

    useEffect(() => {
        dispatch(actions.resetMASQueue());
    }, [dispatch]);

    return (
        <div className={styles.reportsTab}>
            <p className={styles.masTitle}>All monthly account statements ({reportsList.total})</p>
            <Grid container className={cx(styles.reportsContainer, styles.reportsListContainer)}>
                <MASReportsFilter onFilter={handleFilter} />
                {loading && <Loading className={cx(styles.loading)} />}
                {!loading && error && (
                    <h3 className={cx(styles.noData)}>Error: Unable to load data</h3>
                )}
                {!loading && !error && reportsList.total === 0 && (
                    <h3 className={cx(styles.noData)}>No data found</h3>
                )}
                {!loading && reportsList.items?.length > 0 && (
                    <MASReportsList reportsList={reportsList.items} />
                )}
                {!loading && reportsList.items?.length > 0 && (
                    <Pagination
                        data={{
                            items: reportsList.items,
                            page: reportsList.page,
                            size: reportsList.size,
                            total: reportsList.total,
                        }}
                        pageChange={handlePageChange}
                        perPageChange={handlePerPageChange}
                    />
                )}
            </Grid>
        </div>
    );
}
