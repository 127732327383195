import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from './Icon';
import Button from './Button';

import styles from 'assets/styles/common.module.scss';
import cx from 'classnames';

const CustomDialog = (props) => {
    const {
        open,
        setOpen,
        title,
        children,
        submitAction,
        cancelAction,
        isDisable,
        className,
        cancelButton = 'Cancel',
        submitButton = 'Delete',
        isSingleButton = true,
        fullWidth, //TODO: investigate single button case
        backdropProps,
        paperProps = {},
        isActions = true,
        disableClose = false,
    } = props;

    const handleClose = useCallback(
        (event, reason) => {
            // Disabling close on backdrop click
            if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                return;
            }
            if (!disableClose) {
                setOpen(false, reason);
            }
        },
        [setOpen, disableClose]
    );

    return (
        <Dialog
            className={cx(styles[className])}
            open={open}
            onClose={handleClose}
            fullWidth={fullWidth}
            PaperProps={{
                style: {
                    borderRadius: '30px',
                    border: '1px solid #c4c4c4',
                    ...paperProps,
                },
            }}
            BackdropProps={{
                ...backdropProps,
            }}>
            <DialogTitle
                style={{
                    backgroundColor: '#EEF7F5',
                    height: '32px',
                    textAlign: 'center',
                    color: 'rgb(17, 19, 38)',
                    fontFamily: 'Inter, sans-serif',
                    fontStyle: 'normal',
                    letterSpacing: 'normal',
                    lineHeight: '32px',
                    fontWeight: '400',
                    fontSize: '21px',
                }}>
                {title}{' '}
                {!disableClose && (
                    <Icon
                        name={'close-modal'}
                        className={cx(styles.closeIcon)}
                        onClick={() => {
                            handleClose(null, 'modal-close');
                        }}
                    />
                )}
            </DialogTitle>
            <DialogContent className={cx(styles.dialogContent)} style={{ marginTop: '20px' }}>
                {children}
            </DialogContent>
            {isActions && (
                <DialogActions className={cx(styles.actionGroup)}>
                    {cancelButton && !isSingleButton && (
                        <Button
                            className={cx(styles.cancelBtn)}
                            text={cancelButton}
                            variant="outlined"
                            onClick={cancelAction}
                            disabled={isDisable}
                        />
                    )}
                    {submitButton && (
                        <Button
                            className={cx(styles.submitBtn)}
                            text={submitButton}
                            onClick={submitAction}
                            disabled={isDisable}
                        />
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default CustomDialog;

CustomDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    title: PropTypes.string,
    children: PropTypes.node,
    submitAction: PropTypes.func,
    cancelAction: PropTypes.func,
    isDisable: PropTypes.bool,
    disableClose: PropTypes.bool,
    className: PropTypes.string,
    cancelButton: PropTypes.string,
    submitButton: PropTypes.string,
    isSingleButton: PropTypes.bool,
    fullWidth: PropTypes.bool,
    backdropProps: PropTypes.object,
};
