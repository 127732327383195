export const currencyTypes = [
    {
        label: 'AED',
        value: 'AED',
        currency: 'AED',
    },
    {
        label: 'AUD',
        value: 'AUD',
        currency: 'AUD',
    },
    {
        label: 'BBD',
        value: 'BBD',
        currency: 'BBD',
    },
    {
        label: 'BDT',
        value: 'BDT',
        currency: 'BDT',
    },
    {
        label: 'BGN',
        value: 'BGN',
        currency: 'BGN',
    },
    {
        label: 'BHD',
        value: 'BHD',
        currency: 'BHD',
    },
    {
        label: 'BWP',
        value: 'BWP',
        currency: 'BWP',
    },
    {
        label: 'CAD',
        value: 'CAD',
        currency: 'CAD',
    },
    {
        label: 'CHF',
        value: 'CHF',
        currency: 'CHF',
    },
    {
        label: 'CNY',
        value: 'CNY',
        currency: 'CNY',
    },
    {
        label: 'CZK',
        value: 'CZK',
        currency: 'CZK',
    },
    {
        label: 'DKK',
        value: 'DKK',
        currency: 'DKK',
    },
    {
        label: 'ETB',
        value: 'ETB',
        currency: 'ETB',
    },
    {
        label: 'EUR',
        value: 'EUR',
        currency: 'EUR',
    },
    {
        label: 'GBP',
        value: 'GBP',
        currency: 'GBP',
    },
    {
        label: 'GHS',
        value: 'GHS',
        currency: 'GHS',
    },
    {
        label: 'GMD',
        value: 'GMD',
        currency: 'GMD',
    },
    {
        label: 'HKD',
        value: 'HKD',
        currency: 'HKD',
    },
    {
        label: 'HUF',
        value: 'HUF',
        currency: 'HUF',
    },
    {
        label: 'ILS',
        value: 'ILS',
        currency: 'ILS',
    },
    {
        label: 'INR',
        value: 'INR',
        currency: 'INR',
    },
    {
        label: 'JOD',
        value: 'JOD',
        currency: 'JOD',
    },
    {
        label: 'JPY',
        value: 'JPY',
        currency: 'JPY',
    },
    {
        label: 'KES',
        value: 'KES',
        currency: 'KES',
    },
    {
        label: 'KRW',
        value: 'KRW',
        currency: 'KRW',
    },
    {
        label: 'KWD',
        value: 'KWD',
        currency: 'KWD',
    },
    {
        label: 'LKR',
        value: 'LKR',
        currency: 'LKR',
    },
    {
        label: 'MAD',
        value: 'MAD',
        currency: 'MAD',
    },
    {
        label: 'MGA',
        value: 'MGA',
        currency: 'MGA',
    },
    {
        label: 'MUR',
        value: 'MUR',
        currency: 'MUR',
    },
    {
        label: 'MXN',
        value: 'MXN',
        currency: 'MXN',
    },
    {
        label: 'MZN',
        value: 'MZN',
        currency: 'MZN',
    },
    {
        label: 'NGN',
        value: 'NGN',
        currency: 'NGN',
    },
    {
        label: 'NOK',
        value: 'NOK',
        currency: 'NOK',
    },
    {
        label: 'NPR',
        value: 'NPR',
        currency: 'NPR',
    },
    {
        label: 'NZD',
        value: 'NZD',
        currency: 'NZD',
    },
    {
        label: 'OMR',
        value: 'OMR',
        currency: 'OMR',
    },
    {
        label: 'PGK',
        value: 'PGK',
        currency: 'PGK',
    },
    {
        label: 'PHP',
        value: 'PHP',
        currency: 'PHP',
    },
    {
        label: 'PKR',
        value: 'PKR',
        currency: 'PKR',
    },
    {
        label: 'PLN',
        value: 'PLN',
        currency: 'PLN',
    },
    {
        label: 'QAR',
        value: 'QAR',
        currency: 'QAR',
    },
    {
        label: 'RON',
        value: 'RON',
        currency: 'RON',
    },
    {
        label: 'RWF',
        value: 'RWF',
        currency: 'RWF',
    },
    {
        label: 'SAR',
        value: 'SAR',
        currency: 'SAR',
    },
    {
        label: 'SEK',
        value: 'SEK',
        currency: 'SEK',
    },
    {
        label: 'SGD',
        value: 'SGD',
        currency: 'SGD',
    },
    {
        label: 'THB',
        value: 'THB',
        currency: 'THB',
    },
    {
        label: 'TND',
        value: 'TND',
        currency: 'TND',
    },
    {
        label: 'TRY',
        value: 'TRY',
        currency: 'TRY',
    },
    {
        label: 'TTD',
        value: 'TTD',
        currency: 'TTD',
    },
    {
        label: 'TZS',
        value: 'TZS',
        currency: 'TZS',
    },
    {
        label: 'UGX',
        value: 'UGX',
        currency: 'UGX',
    },
    {
        label: 'USD',
        value: 'USD',
        currency: 'USD',
    },
    {
        label: 'XAF',
        value: 'XAF',
        currency: 'XAF',
    },
    {
        label: 'XCD',
        value: 'XCD',
        currency: 'XCD',
    },
    {
        label: 'XOF',
        value: 'XOF',
        currency: 'XOF',
    },
    {
        label: 'ZAR',
        value: 'ZAR',
        currency: 'ZAR',
    },
    {
        label: 'NAD',
        value: 'NAD',
        currency: 'NAD',
    },
    {
        label: 'ZMW',
        value: 'ZMW',
        currency: 'ZMW',
    },
];

export const selectAllOption = {
    value: 'select-all',
    currencyName: 'Select All',
    currency: '',
};
