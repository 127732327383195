import { createSlice } from '@reduxjs/toolkit';

import { PROVIDERS } from 'utils/constants';

/*
 *  @desc The payments slice manages the state of the company details
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    company: {},
    providers: [],
    isMerlinOnly: null,
    loading: false,
    error: null,
    message: '',
};

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        getCompanyData(state) {
            state.loading = true;
            state.error = null;
            state.message = '';
        },
        getCompanySuccess(state, action) {
            const company = action.payload.item;
            state.loading = false;
            state.error = null;
            state.message = '';
            state.company = company;
            let providers = [];
            if (company.provider_customer_id) {
                providers.push(PROVIDERS.MERLIN);
            }
            if (company.neptune_provider_id) {
                providers.push(PROVIDERS.NEPTUNE);
            }
            if (providers.length === 1 && providers[0] === PROVIDERS.MERLIN) {
                state.isMerlinOnly = true;
            } else {
                state.isMerlinOnly = false;
            }
            state.providers = providers;
        },
        getCompanyError(state, action) {
            state.loading = false;
            state.message = '';
            state.error = action.error;
            state.company = {};
        },
    },
});

export const { actions, reducer, name } = companySlice;
