import { createSlice } from '@reduxjs/toolkit';

/*
 *  @desc The funds slice manages the state of Send Funds
 *  slice
 *  reducer
 *  actions
 */

export const initialState = {
    transaction_id: null,
    loading: false,
    loadingTransaction: false,
    error: null,
    fromAccount: null,
    recipient: null,
    account: null,
    currentFundStep: 0,
    disableStep: true,
    transaction_details: {},
    transactionStatus: null,
    transactionError: null,
    paymentRequestStatus: null,
    paymentRequestError: null,
    otp: false,
    otpCount: 0,
    token: '',
};

export const fundsSlice = createSlice({
    name: 'funds',
    initialState,
    reducers: {
        setCurrentFundStep: (state, action) => {
            if (action.payload === 0) {
                state.transactionStatus = null;
                state.transactionError = null;
            }
            state.currentFundStep = action.payload;
        },
        resetTransactionStatus: (state, action) => {
            state.transactionStatus = null;
            state.transactionError = null;
        },
        generateTransactionDetails: (state, action) => {
            state.transaction_details = { ...action.payload };
        },
        getQuoteTransactionOtp: (state, action) => {
            state.otp = false;
            state.token = '';
            state.loading = true;
        },
        getQuoteTransactionOtpSuccess: (state, action) => {
            state.otp = true;
            state.token = action.payload?.token;
            state.loading = false;
        },
        getQuoteTransactionOtpError: (state, action) => {
            state.otp = false;
            state.token = ';';
            state.error = { ...action.payload };
            state.loading = false;
        },
        resetQuoteTransactionOtp: (state, action) => {
            state.otp = false;
            state.error = {
                message: '',
                status: null,
            };
        },
        getOtp: (state, action) => {
            state.otp = false;
            state.token = '';
            state.loading = true;
        },
        getOtpSuccess: (state, action) => {
            state.otp = true;
            state.token = action.payload?.token;
            state.loading = false;
        },
        getOtpError: (state, action) => {
            state.otp = false;
            state.token = ';';
            state.error = { ...action.payload };
            state.loading = false;
        },
        resetOtp: (state, action) => {
            state.otp = false;
            state.error = {
                message: '',
                status: null,
            };
        },
        fetchFromAccount: (state, action) => {
            state.fromAccount = null;
        },
        fetchFromAccountSuccess: (state, action) => {
            state.fromAccount = action.payload;
        },
        fetchFromAccountFailure: (state, action) => {
            state.error = action.error;
        },

        createQuoteTransaction: (state, action) => {
            state.loadingTransaction = true;
            state.transactionStatus = 'pending';
            state.transactionError = null;
        },
        createQuoteTransactionSuccess: (state, action) => {
            state.loadingTransaction = false;
            state.transaction_id = action.payload.transaction_id;
            state.transactionStatus = 'success';
            state.transactionError = null;
            state.otp = false;
            state.currentFundStep = 2;
        },
        createQuoteTransactionError: (state, action) => {
            state.loadingTransaction = false;
            state.error = { ...action.payload };
            switch (action.payload.status) {
                case 422:
                    state.transactionStatus = 'error';
                    state.transactionError = 'Error';
                    break;
                case 403:
                    if (state.error.message.indexOf('Invalid OTP') !== -1 && state.otpCount !== 3) {
                        state.otpCount = state.otpCount + 1;
                    }
                    state.transactionError = null;
                    state.transactionStatus = null;
                    break;
                case 500:
                    state.transactionError =
                        action.payload.message ?? 'Oops! Something went wrong...';
                    state.transactionStatus = 'error';
                    state.currentFundStep = 2;
                    break;
                default:
                    state.transactionError =
                        action.payload.message ?? 'Oops! Something went wrong...';
                    state.transactionStatus = 'error';
                    state.currentFundStep = 2;
                    break;
            }
            state.otp = false;
        },

        createTransaction: (state, action) => {
            state.loadingTransaction = true;
            state.transactionStatus = 'pending';
            state.transactionError = null;
        },
        createTransactionSuccess: (state, action) => {
            state.loadingTransaction = false;
            state.transaction_id = action.payload.transaction_id;
            state.transactionStatus = 'success';
            state.transactionError = null;
            state.otp = false;
            state.currentFundStep = 2;
        },
        createTransactionError: (state, action) => {
            state.loadingTransaction = false;
            state.error = { ...action.payload };
            switch (action.payload.status) {
                case 422:
                    state.transactionStatus = 'error';
                    state.transactionError = 'Error';
                    break;
                case 403:
                    if (state.error.message.indexOf('Invalid OTP') !== -1 && state.otpCount !== 3) {
                        state.otpCount = state.otpCount + 1;
                    }
                    state.transactionError = null;
                    state.transactionStatus = null;
                    break;
                case 500:
                    state.transactionError =
                        action.payload.message ?? 'Oops! Something went wrong...';
                    state.transactionStatus = 'error';
                    state.currentFundStep = 2;
                    break;
                default:
                    state.transactionError =
                        action.payload.message ?? 'Oops! Something went wrong...';
                    state.transactionStatus = 'error';
                    state.currentFundStep = 2;
                    break;
            }
            state.otp = false;
        },
        resetFunds: () => initialState,
    },
});

export const { actions, reducer, name } = fundsSlice;
