import React, { useCallback, useState } from 'react';
import { Grid, MenuItem, Select } from '@mui/material';
import cx from 'classnames';
import styles from '../../assets/styles/common.module.scss';
import TablePagination from '@mui/material/TablePagination';
import Icon from './Icon';

const Pagination = (props) => {
    const {
        data: { items, page, size, total },
        perPageChange,
        pageChange,
    } = props;
    const [rowsPerPage, setRowsPerPage] = useState(size);

    const handleChangeRowsPerPage = useCallback(
        (event) => {
            setRowsPerPage(event.target.value);
            perPageChange(event.target.value);
        },
        [perPageChange]
    );

    const handlePageChanges = useCallback(
        (value) => {
            const currentPage = value === 'next' ? page + 1 : page - 1;
            pageChange(currentPage, rowsPerPage);
        },
        [pageChange, rowsPerPage, page]
    );

    return (
        <Grid container className={cx(styles.pagination)}>
            <Grid item xs={4}>
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={items?.length || 0}
                    rowsPerPage={rowsPerPage || 10}
                    page={0}
                    onPageChange={() => null}
                    nextIconButtonProps={{ style: { display: 'none' } }}
                    backIconButtonProps={{ style: { display: 'none' } }}
                    labelDisplayedRows={() => (
                        <span className={cx(styles.actions)}>
                            {Math.ceil(total / rowsPerPage) >= page ? (
                                <>
                                    <Icon
                                        name={'arrow-left'}
                                        onClick={() => handlePageChanges('prev')}
                                        disabled={page === 1}
                                    />
                                    <span className={cx(styles.actionsPage)}>{page} </span>
                                    <span className={cx(styles.actionsSlash)}>/</span>
                                    <span className={cx(styles.actionsPerPage)}>
                                        {Math.ceil(total / rowsPerPage)}
                                    </span>
                                    <Icon
                                        name="arrow-right"
                                        onClick={() => handlePageChanges('next')}
                                        disabled={page === Math.ceil(total / rowsPerPage)}
                                    />
                                </>
                            ) : null}
                        </span>
                    )}
                    sx={{
                        float: 'left',
                    }}
                />
            </Grid>
            {items?.length > 0 && (
                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className={cx(styles.select)}>
                        <p>Rows per page</p>
                        <Select
                            className={cx(styles.selectField)}
                            value={`${rowsPerPage || size}`}
                            onChange={handleChangeRowsPerPage}>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

export default Pagination;
