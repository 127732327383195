export const accountStatuses = [
    {
        label: 'Active',
        value: 'active',
    },
    {
        label: 'Processing',
        value: 'in-progress',
    },
    {
        label: 'Blocked',
        value: 'blocked',
    },
];

export const MODAL_TYPE = {
    AddFunds: 'add-funds',
    CreateSubAccount: 'create-sub-account',
    UpdateAccount: 'update-account',
    CloseAccount: 'close-account',
};

export const MAIN_ACCOUNT = 'main_account';
