import React from 'react';
import { SelectField } from 'components/common';
import { currencyTypes } from './constants';

const SelectCurrency = (props) => {
    const { currencies, handleChange } = props;
    const currencyOptions = currencies
        ? currencyTypes.filter((c) => !!currencies.find((o) => o === c.currency))
        : currencyTypes;
    return (
        <SelectField 
            options={currencyOptions} 
            onChange={handleChange} 
            currencyIcon {...props} />
    );
};

export default SelectCurrency;
