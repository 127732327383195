import { createSelector } from '@reduxjs/toolkit';
import { initialState as accountState } from 'store/slice/account';
import { useSelector } from 'react-redux';

const selectAccount = (state) => state.accounts || accountState;
export const selectAddFunds = (state) => state.accounts.addFunds || accountState.addFunds;

export const selectCreateAccount = (state) =>
    state.accounts.createCustomerAccount || accountState.createCustomerAccount;

export const selectUpdateAccount = (state) =>
    state.accounts.updateAccount || accountState.updateAccount;

export const selectCloseAccount = (state) =>
    state.accounts.closeCustomerAccount || accountState.closeCustomerAccount;

export const selectSummary = (state) => state.accounts.summary || accountState.summary;

const select = () => createSelector(selectAccount, (state) => state);

/* eslint-disable */
export default () => useSelector(select());
