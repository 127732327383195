import { Grid } from '@mui/material';
import cx from 'classnames';
import { Loading, Pagination } from 'components/common';
import styles from 'assets/styles/transaction.module.scss';
import TransactionFilter from 'components/dashboard/transactions/TransactionFilter';
import TransactionList from 'components/dashboard/transactions/TransactionList';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import selectAccountsState from 'store/selectors/account';
import { actions } from 'store/slice/account';
import AccountInfo from './AccountInfo';
import BreadcrumbsNavigator from 'components/common/Breadcrumbs';

const AccountTransactions = () => {
    const { transactions, loading } = selectAccountsState();
    const [searchParams] = useSearchParams();
    const [params, setParams] = useState('');
    const dispatch = useDispatch();
    const { accountId } = useParams();

    const handleFilter = useCallback(
        (queryParams = '', page = 1, size = 10) => {
            setParams(queryParams);
            dispatch(
                actions.getSubAccountTransactions({
                    account_id: accountId,
                    page,
                    size,
                    queryParams,
                })
            );
        },
        [dispatch, accountId]
    );

    const handlePerPageChange = useCallback(
        (value) => {
            handleFilter(params, 1, value);
        },
        [params, handleFilter]
    );

    const handlePageChange = useCallback(
        (page, perPage) => {
            handleFilter(params, page, perPage);
        },
        [params, handleFilter]
    );

    return (
        <Grid container className={cx(styles.container)}>
            <h1 className={cx(styles.title)}>Accounts transactions</h1>
            <BreadcrumbsNavigator
                prevPage={{ title: 'Accounts', href: '/dashboard/accounts' }}
                currentPage={{ title: 'Transactions' }}
            />
            <AccountInfo
                className={cx(styles.accountInfo)}
                accountId={accountId}
                onRefresh={() => handleFilter(searchParams.toString())}
            />
            <TransactionFilter
                disableCurrencyFilter={true}
                disableAccountFilter={true}
                onFilter={handleFilter}
                loading={loading}
            />
            <Grid container>
                {loading && <Loading className={cx(styles.loading)} />}
                {!loading && transactions?.total === 0 && (
                    <h3 className={cx(styles.noData)}>No data found</h3>
                )}
                {!loading && transactions?.items?.length > 0 && (
                    <TransactionList transactions={transactions} />
                )}
                {!loading && transactions?.items?.length > 0 && (
                    <Pagination
                        data={{
                            items: transactions?.items,
                            page: transactions?.page,
                            size: transactions?.size,
                            total: transactions?.total,
                        }}
                        pageChange={handlePageChange}
                        perPageChange={handlePerPageChange}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default AccountTransactions;
