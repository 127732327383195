import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
    Autocomplete,
    TextField,
    Checkbox,
    ListItemIcon,
    ListItemText,
    MenuItem,
} from '@mui/material';
import cx from 'classnames';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { currencyList } from 'utils/helpers';
import { ThemeProvider } from '@emotion/react';
import CustomTooltip from '../CustomTooltip';
import Icon from '../Icon';
import styles from 'assets/styles/common.module.scss';
import { selectAllOption } from './constants';
import { getCurrencyOptions, getCurrencyCodes, materialTheme } from './utils';
import CurrencyIcon from '../CurrencyIcon';

const MultiSelectAutocomplete = ({
    name,
    label,
    handleChange,
    value,
    currencies = [],
    required = false,
    info = '',
    error = false,
    disable = false,
    helperText = '',
}) => {
    const [isFocus, setFocus] = useState(false);
    let inputRef = useRef(null);
    const currencyListOptions = useMemo(() => {
        if (currencies.length) {
            return getCurrencyOptions(currencies, currencyList);
        }

        return currencyList;
    }, [currencies]);
    const [selectedCurrencies, setSelectedCurrencies] = useState(() =>
        getCurrencyOptions(value, currencyListOptions)
    );
    const isAllSelected = selectedCurrencies.length === currencyListOptions.length;
    const isPartialySelected =
        selectedCurrencies.length <= currencyListOptions.length && selectedCurrencies.length > 0;
    const isSingleSelected = selectedCurrencies.length === 1;

    const handleScrollIntoView = () => {
        if (inputRef.current) {
            inputRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'end',
            });
        }
    };

    const onAutcompleteFocus = () => {
        if (!isFocus) {
            setTimeout(() => {
                setFocus(true);
            }, 220);
        }
    };

    const onSelectChange = (event, value, action, selectedOption = null) => {
        if (value.length > selectedCurrencies.length) {
            handleScrollIntoView();
        }
        if (!selectedOption) return setSelectedCurrencies(value);
        const { option: { currencyName = '' } = { option: { currencyName: '' } } } = selectedOption;
        if (currencyName === 'Select All') {
            if (isPartialySelected) {
                setSelectedCurrencies([]);
            } else {
                setSelectedCurrencies(currencyListOptions);
            }
        } else {
            setSelectedCurrencies(value);
        }
        const currencyCodes = getCurrencyCodes(value);
        handleChange(name, currencyCodes);
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: ({ currencyName, currency, countryCode }) =>
            currency && `${currencyName} ${countryCode}`,
    });

    useEffect(() => {
        if (!value.length) {
            setSelectedCurrencies([]);
        }
    }, [value]);

    return (
        <div style={{ height: error ? '110px' : '80px' }}>
            <div className={cx(disable ? styles.disable : styles.label)}>
                {label}
                {required && <span className={cx(styles.asterisk)}> *</span>}
                {info && (
                    <CustomTooltip info={info}>
                        <Icon name={'info'} />
                    </CustomTooltip>
                )}
            </div>
            <ThemeProvider theme={materialTheme(selectedCurrencies)}>
                <div
                    className={cx([
                        error ? styles.errorBorder : '',
                        styles['autocompleteFieldOverride'],
                    ])}>
                    <Autocomplete
                        name={name}
                        open={isFocus}
                        blurOnSelect={false}
                        className={cx(styles.autocompleteScroll)}
                        componentsProps={{
                            popupIndicator: {
                                disableRipple: true,
                            },
                        }}
                        multiple
                        limitTags={3}
                        onFocus={onAutcompleteFocus}
                        onBlur={() => setFocus(false)}
                        value={selectedCurrencies}
                        id="multiple-limit-tags"
                        onChange={onSelectChange}
                        options={[selectAllOption, ...currencyListOptions]}
                        getOptionLabel={({ currency }) => currency}
                        filterOptions={filterOptions}
                        disableListWrap
                        disableCloseOnSelect
                        disableClearable
                        disablePortal
                        renderOption={(props, option, selectState) => {
                            const { key } = props;
                            const { index, selected } = selectState;
                            const { currency, currencyName } = option;
                            const isSelectAllBtn =
                                index === 0 && currencyName === selectAllOption.currencyName;
                            const selectAllBtnText = isPartialySelected
                                ? `Deselect ${isSingleSelected ? '' : 'All'}`
                                : 'Select All';

                            return (
                                <MenuItem
                                    key={key}
                                    style={{
                                        marginLeft: 0,
                                    }}
                                    value={currencyName || ''}
                                    defaultValue={currencyName || ''}
                                    className={cx([styles.multiSelectMenuItem])}
                                    {...props}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={isSelectAllBtn ? isAllSelected : selected}
                                            indeterminate={
                                                isSelectAllBtn ? isPartialySelected : false
                                            }
                                        />
                                    </ListItemIcon>
                                    {currency && (
                                        <ListItemIcon>
                                            <CurrencyIcon
                                                style={{
                                                    paddingRight: '10px',
                                                }}
                                                rounded
                                                currency={currency}
                                                height="20px"
                                            />
                                        </ListItemIcon>
                                    )}
                                    <ListItemText
                                        primary={isSelectAllBtn ? selectAllBtnText : currencyName}
                                    />
                                </MenuItem>
                            );
                        }}
                        renderInput={(params) => {
                            const { ref } = params.inputProps;
                            inputRef.current = ref.current;

                            return (
                                <TextField
                                    {...params}
                                    sx={{
                                        backgroundColor: '#fff',
                                        opacity: 1,
                                        '& .MuiInputBase-root': {
                                            backgroundColor: '#fff',
                                            opacity: 1,
                                        },
                                    }}
                                    placeholder={
                                        !(selectedCurrencies.length > 0)
                                            ? isFocus
                                                ? 'Type Euro or EUR or €'
                                                : 'Select currency(s)'
                                            : ''
                                    }
                                />
                            );
                        }}
                    />
                    {helperText && <p className={cx(styles.asterisk)}>{helperText}</p>}
                </div>
            </ThemeProvider>
        </div>
    );
};

export default MultiSelectAutocomplete;
