const pages = {
    policyPages: [
        {
            title: 'Privacy Policy',
            href: '#privacy-policy',
        },
        {
            title: 'Personal information overview',
            href: '#personal-information-overview',
        },
        {
            title: 'Sensitive data',
            href: '#sensitive-data',
        },
        {
            title: 'Cookies and other identifiers',
            href: '#cookies-and-other-identifiers',
        },
        {
            title: 'Does Merge share my personal information?',
            href: '#does-merge-share-my-personal-information',
        },
        {
            title: 'International transfers of personal data',
            href: '#international-transfers-of-personal-data',
        },
        {
            title: 'How Secure is my information?',
            href: '#how-secure-is-my-information',
        },
        {
            title: 'What about advertising?',
            href: '#what-about-advertising',
        },
        {
            title: 'What information can I access?',
            href: '#what-information-can-i-access',
        },
        {
            title: 'What rights do I have?',
            href: '#what-rights-do-i-have',
        },
        {
            title: 'How long does Merge keep my personal information?',
            href: '#how-long-does-merge-keep-my-personal-information',
        },
        {
            title: 'Contact information',
            href: '#contact-information',
        },
        {
            title: 'Conditions of use, notices and revisions',
            href: '#conditions-of-use-notices-and-revisions',
        },
    ],
    termsPages: [
        {
            title: 'Terms and Conditions',
            href: '#terms-and-conditions',
        },
        {
            title: '1. Merge: Distributor and Contact Information',
            href: '#merge-distributor-and-contact-information',
        },
        {
            title: '2. Introduction',
            href: '#introduction',
        },
        {
            title: '3. Definitions',
            href: '#definitions',
        },
        {
            title: '4. Your e-wallet and the services',
            href: '#your-e-wallet-and-the-services',
        },
        {
            title: '5. Maintaining your wallet',
            href: '#maintaining-your-wallet',
        },
        {
            title: '6. Protecting your e-wallet',
            href: '#protecting-your-e-wallet',
        },
        {
            title: '7. Closing your wallet',
            href: '#closing-your-wallet',
        },
        {
            title: '8. Depositing or withdrawing funds',
            href: '#depositing-or-withdrawing-funds',
        },
        {
            title: '9. Making payments',
            href: '#making-payments',
        },
        {
            title: '10. Partners',
            href: '#partners',
        },
        {
            title: '11. End-user accounts',
            href: '#end-user-accounts',
        },
        {
            title: '12. Compliance with laws and prohibited activity',
            href: '#compliance-with-laws-and-prohibited-activity',
        },
        {
            title: '13. Fees and set-off',
            href: '#fees-and-set-off',
        },
        {
            title: '14. Term, suspension and termination',
            href: '#term-suspension-and-termination',
        },
        {
            title: '15. (Limitation of) Liability',
            href: '#limitation-of-liability',
        },
        {
            title: '16. Confidentiality and data protection',
            href: '#confidentiality-and-data-protection',
        },
        {
            title: '17. Intellectual property',
            href: '#intellectual-property',
        },
        {
            title: '18. Changes to these terms',
            href: '#changes-to-these-terms',
        },
        {
            title: '19. Communication and complaints',
            href: '#communication-and-complaints',
        },
        {
            title: '20. General',
            href: '#general',
        },
    ],
    termsPagesModulrUk: [
        {
            title: 'Background',
            href: '#background',
        },
        {
            title: '1. Interpretation',
            href: '#interpretation',
        },
        {
            title: '2. Modulr Products',
            href: '#modulr-products',
        },
        {
            title: '3. Authorised Users',
            href: '#authorised-users',
        },
        {
            title: '4. Accessing Modulr Products through a Partner Platform',
            href: '#accessing-modulr-products-through-a-partner-platform',
        },
        {
            title: '5. Customer Service',
            href: '#customer-service',
        },
        {
            title: '6. Term and Termination',
            href: '#term-and-termination',
        },
        {
            title: '7. Intellectual Property',
            href: '#intellectual-property',
        },
        {
            title: '8. Force Majeur',
            href: '#force-majeur',
        },
        {
            title: '9. Assignment Transfer and Subcontracting',
            href: '#assignment-transfer-and-subcontracting',
        },
        {
            title: '10. Liability',
            href: '#liability',
        },
        {
            title: '11. Reports',
            href: '#reports',
        },
        {
            title: '12. Data privacy',
            href: '#data-privacy',
        },
        {
            title: '13. Changes to the agreement',
            href: '#changes-to-the-agreement',
        },
        {
            title: '14. General',
            href: '#general',
        },
    ],
    termsOfUsePages: [
        {
            title: 'Terms of use',
            href: '#terms-of-use',
        },
        {
            title: 'Other applicable terms',
            href: '#other-applicable-terms',
        },
        {
            title: 'Changes to these terms',
            href: '#changes-to-these-terms',
        },
        {
            title: 'Changes to merge website',
            href: '#changes-to-merge-website',
        },
        {
            title: 'Accessing merge website',
            href: '#accessing-merge-website',
        },
        {
            title: 'Account and password',
            href: '#account-and-password',
        },
        {
            title: 'Intellectual property',
            href: '#intellectual-property',
        },
        {
            title: 'No reliance on information',
            href: '#no-reliance-on-information',
        },
        {
            title: 'Prohibited use',
            href: '#prohibited-use',
        },
        {
            title: 'Breach',
            href: '#breach',
        },
        {
            title: 'Limitation of liability',
            href: '#limitation-of-liability',
        },
        {
            title: 'Linking to merge website',
            href: '#linking-to-merge-website',
        },
        {
            title: 'Third party links',
            href: '#third-party-links',
        },
        {
            title: 'Our relationship',
            href: '#our-relationship',
        },
        {
            title: 'Complaints',
            href: '#complaints',
        },
        {
            title: 'Governing law',
            href: '#governing-law',
        },
    ],
};
export default pages;
