import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slice/global';
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';

const {
    accounts: { summary },
} = Endpoints;

export function* loadEnabledCurrencies() {
    try {
        const { data } = yield call(request().get, summary.url);
        if (data) {
            const {currencies} = data;
            yield put(actions.loadEnabledCurrenciesSuccess(currencies.map(item => item.currency)));
        }
    } catch (err) {
        yield put(actions.loadEnabledCurrenciesError(err.message));
    }
}

export function* globalSaga() {
    yield takeLatest(actions.loadEnabledCurrencies.type, loadEnabledCurrencies);
}
