import React from 'react';
import cx from 'classnames';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Stack } from '@mui/material';
import { Dialog, RadioGroup, Loading } from 'components/common';
import { actions } from 'store/slice/transactions';
import { selectSimulateWebhook } from 'store/selectors/transactions';
import styles from 'assets/styles/account.module.scss';
import {
    initSimulateTransactionState,
    SIMULATE_WEBHOOK,
    transactionStatusRadioOptions,
} from './constants';
import DialogV2 from 'components/common/DialogV2';

const simulateValidationSchema = yup.object({
    simulate: yup
        .string('Type of Transaction Status')
        .required('Please select a type of transaction status'),
});

export default function SimulateTransactionStatusModal({
    transactionId,
    simulateModalState,
    toggleModalDisplay,
}) {
    const displayModal = simulateModalState === SIMULATE_WEBHOOK.transactionStatus.name;
    const dispatch = useDispatch();
    const { loading, error, message } = useSelector(selectSimulateWebhook);
    const isLoaded = !loading && !!message;

    const onSimulateTransactionStatus = () => {
        if (formik.isValid) {
            const { simulate } = formik.values;
            dispatch(actions.simulateWebhook({ simulate, transactionId }));
        }
    };

    const formik = useFormik({
        initialValues: initSimulateTransactionState,
        validationSchema: simulateValidationSchema,
        onSubmit: (values) => {
            const { simulate } = values;
            onSimulateTransactionStatus(simulate);
        },
    });

    const handleChange = (event) => {
        const { value } = event.target;
        formik.setValues({
            simulate: value,
        });
    };

    const renderBody = () => {
        return (
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="center"
                style={{ margin: '5px auto' }}>
                <RadioGroup
                    options={transactionStatusRadioOptions}
                    label="Mark the transaction as"
                    required={true}
                    defaultValue={formik.values.simulate}
                    onChange={handleChange}
                    isRow
                    name={'statusType'}
                    error={formik.touched.simulate && Boolean(formik.errors.simulate)}
                    helperText={formik.touched.simulate && formik.errors.simulate}
                />
            </Stack>
        );
    };

    return (
        <>
            {isLoaded ?
                <DialogV2
                    title={SIMULATE_WEBHOOK.transactionStatus.title}
                    open={displayModal}
                    isSingleButton={true}
                    setOpen={() => toggleModalDisplay('')}
                    submitButton={'Close'}
                    variant={error ? 'error' : 'info'}
                    submitAction={() => toggleModalDisplay('')}
                >
                    <div className={cx(styles.messageText)}>
                        <p>{message}</p>
                    </div>
                </DialogV2> :
                <Dialog
                    title={SIMULATE_WEBHOOK.transactionStatus.title}
                    fullWidth
                    open={displayModal}
                    setOpen={() => toggleModalDisplay('')}
                    backdropProps={{
                        style: {
                            backgroundColor: 'rgba(255,255,255, 0.8)',
                        },
                    }}
                    submitButton={'Submit'}
                    cancelButton={'Cancel'}
                    isSingleButton={false}
                    cancelAction={() => toggleModalDisplay('')}
                    submitAction={() => onSimulateTransactionStatus()}
                    isActions={true}
                    isDisable={loading || !formik.isValid}>
                    <form className={cx(styles.form, styles.formRadio)} onSubmit={formik.handleSubmit}>
                        {loading && <Loading className={cx(styles.formLoading)} />}
                        {renderBody()}
                    </form>
                </Dialog>
            }
        </>
    );
}
