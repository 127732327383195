import cx from 'classnames';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTooltip, Icon } from 'components/common';
import SimulateTransactionStatusModal from './SimulateTransactionStatusModal';
import SimulateTransactionFeeModal from './SimulateTransactionFeeModal';
import { getUserTypes } from 'store/selectors/auth';
import styles from 'assets/styles/transaction.module.scss';
import { AllowedUsers, belongsTo } from 'utils/auth';
import { actions } from 'store/slice/transactions';
import { SIMULATE_WEBHOOK } from './constants';

export default function SimulateTransaction({ simulateMeta }) {
    const [displaySimulateModal, setDisplaySimulateModal] = useState('');
    const dispatch = useDispatch();
    const userTypes = useSelector(getUserTypes);
    const allowedUserTypes = belongsTo(userTypes, AllowedUsers.SimulateWebhooks);
    const { paymentType, status, transactionId } = simulateMeta;

    const onModalClose = (val) => {
        setDisplaySimulateModal(val);
        dispatch(actions.resetSimulateWebhook());
    };

    useEffect(() => {
        if (displaySimulateModal === SIMULATE_WEBHOOK.transactionFee.name) {
            dispatch(actions.simulateWebhook({ simulate: 'fee', transactionId }));
        }
    }, [displaySimulateModal, dispatch, transactionId]);

    return (
        <Box sx={{ display: 'flex' }}>
            {Object.keys(SIMULATE_WEBHOOK)
                .filter((simulateItem) => {
                    const { allowedPaymentType, allowedStatuses } = SIMULATE_WEBHOOK[simulateItem];
                    return (
                        allowedPaymentType.includes(paymentType) && allowedStatuses.includes(status)
                    );
                })
                .map((simulateItem) => {
                    return (
                        <div
                            key={simulateItem}
                            className={cx(styles.sandboxIcon)}
                            onClick={() => setDisplaySimulateModal(simulateItem)}
                            disabled={!allowedUserTypes}>
                            <CustomTooltip info={SIMULATE_WEBHOOK[simulateItem].title}>
                                <Icon name={SIMULATE_WEBHOOK[simulateItem].icon} width="20px" />
                            </CustomTooltip>
                        </div>
                    );
                })}
            <SimulateTransactionStatusModal
                transactionId={transactionId}
                simulateModalState={displaySimulateModal}
                toggleModalDisplay={(val) => onModalClose(val)}
            />
            <SimulateTransactionFeeModal
                transactionId={transactionId}
                simulateModalState={displaySimulateModal}
                toggleModalDisplay={(val) => onModalClose(val)}
            />
        </Box>
    );
}
