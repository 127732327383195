import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        fontSize: '10px',
        flexDirection: 'row',
        alignItems: 'stretch',
        width: '100%',
    },
    cell: {
        padding: '5px',
        borderLeft: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
        justifyContent: 'center',
        minHeight: '10px',
        wordBreak: 'break-all',
        height: 'auto',
    },
    id: {
        width: '6%',
    },
    status: {
        width: '2%',
    },
    type: {
        width: '1.5%',
    },
    typeDescription: {
        width: '2.5%',
    },
    account_number: {
        width: '3%',
    },
    sort_code: {
        width: '2%',
    },
    iban: {
        width: '5%',
    },
    bic: {
        width: '4%',
    },
    account_type: {
        width: '3%',
    },
    description: {
        width: '6%',
    },
    source: {
        width: '3%',
    },
    destination: {
        width: '3%',
    },
    amount: {
        width: '2%',
        textAlign: 'right',
    },
    exchangeRate: {
        width: '2.5%',
    },
    currency: {
        width: '2%',
    },
    date: {
        width: '5.5%',
    },
    statusReason: {
        width: '3%',
    },
    statusNote: {
        width: '3%',
        borderRight: '1px solid #ccc',
    },
});

const NeptuneData = ({ items }) => {
    const rows = items.map((item) => (
        <View style={styles.row} key={item.transaction_id}>
            <Text style={[styles.id, styles.cell]}>{item.transaction_id}</Text>
            <Text style={[styles.id, styles.cell]}>{item.account_id}</Text>
            <Text style={[styles.account_type, styles.cell]}>{item.account_type}</Text>
            <Text style={[styles.status, styles.cell]}>{item.status}</Text>
            <Text style={[styles.date, styles.cell]}>{item.payment_date}</Text>
            <Text style={[styles.type, styles.cell]}>{item.payment_type}</Text>
            <Text style={[styles.typeDescription, styles.cell]}>{item.type_description}</Text>
            <Text style={[styles.description, styles.cell]}>
                {item.reference?.match(/.{1,80}/g)?.map((s, idx) => {
                    return <Text key={idx}>{s}</Text>;
                })}
            </Text>
            <Text style={[styles.source, styles.cell]}>{item.source_name}</Text>
            <Text style={[styles.currency, styles.cell]}>{item.source_currency}</Text>
            <Text style={[styles.amount, styles.cell]}>{item.source_amount}</Text>
            <Text style={[styles.account_number, styles.cell]}>
                {item.source_account_number}
            </Text>
            <Text style={[styles.sort_code, styles.cell]}>
                {item.source_sort_code}
            </Text>
            <Text style={[styles.iban, styles.cell]}>
                {item.source_iban}
            </Text>
            <Text style={[styles.bic, styles.cell]}>
                {item.source_bic}
            </Text>
            <Text style={[styles.destination, styles.cell]}>{item.destination_name}</Text>
            <Text style={[styles.currency, styles.cell]}>{item.destination_currency}</Text>
            <Text style={[styles.amount, styles.cell]}>{item.destination_amount}</Text>
            <Text style={[styles.account_number, styles.cell]}>
                {item.destination_account_number}
            </Text>
            <Text style={[styles.sort_code, styles.cell]}>
                {item.destination_sort_code}
            </Text>
            <Text style={[styles.iban, styles.cell]}>
                {item.destination_iban}
            </Text>
            <Text style={[styles.bic, styles.cell]}>
                {item.destination_bic}
            </Text>
            <Text style={[styles.amount, styles.cell]}>{item.amount}</Text>
            <Text style={[styles.currency, styles.cell]}>{item.currency}</Text>
            <Text style={[styles.id, styles.cell]}>{item.quote_id}</Text>
            <Text style={[styles.exchangeRate, styles.cell]}>{item.exchange_rate}</Text>
            <Text style={[styles.date, styles.cell]}>{item.created_at}</Text>
            <Text style={[styles.statusReason, styles.cell]}>{item.status_reason}</Text>
            <Text style={[styles.statusNote, styles.cell]}>{item.status_note}</Text>
        </View>
    ));
    return <Fragment>{rows}</Fragment>;
};

export default NeptuneData;
